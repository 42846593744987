<template>
  <div class="auth-block">
    <div class="auth-block-body">
      <div class="auth-block__title">Reset password</div>

      <template v-if="responseMessage">
        <info-block :type="responseMessage.type">
          {{ responseMessage.text }}
        </info-block>
      </template>

      <template v-else>
        <info-block type="primary">
          Enter email address that you use for Sign in.
        </info-block>

        <b-form class="auth-form" @submit.prevent="submitResetPasswordForm">
          <b-form-group
            id="emailGroup"
            label-for="email"
            invalid-feedback="This is a required field and must be email."
          >
            <template slot="label">
              Email <span class="text-danger">*</span>
            </template>
            <b-form-input
              id="email"
              type="email"
              v-model="$v.form.email.$model"
              :state="validateState('email')"
            ></b-form-input>
          </b-form-group>

          <div class="form-buttons">
            <button
              class="button"
              type="submit"
            >
              Reset Password
            </button>
            <router-link
              :to="{ name: 'SignIn', params: { type: this.$route.params.type } }"
              class="action-label action-label--dark"
            >
              Cancel
            </router-link>
          </div>
        </b-form>
      </template>
    </div>

    <div v-if="!responseMessage" class="auth-block-footer">
      <p>Remember your Password?</p>

      <router-link
        :to="{ name: 'SignIn', params: { type: this.$route.params.type } }"
        class="link-regular link-regular--bold"
      >
        Sign In
      </router-link>
    </div>
  </div>
</template>

<script>
import { BForm, BFormGroup, BFormInput } from 'bootstrap-vue';
import { validationMixin } from 'vuelidate';
import { email, required } from 'vuelidate/lib/validators';
import InfoBlock from '@/components/utils/InfoBlock.vue';

export default {
  name: 'ResetPassword',
  components: {
    BForm,
    BFormGroup,
    BFormInput,
    InfoBlock,
  },
  mixins: [validationMixin],
  data() {
    return {
      form: {
        email: null,
      },
      responseMessage: null,
    };
  },
  validations: {
    form: {
      email: {
        required,
        email,
      },
    },
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    submitResetPasswordForm() {
      this.$v.form.$reset();
      this.$v.form.$touch();

      if (!this.$v.form.$anyError) {
        this.$store.dispatch('auth/sendResetPasswordLink', {
          email: this.form.email,
        })
          .then(() => {
            this.responseMessage = {
              type: 'success',
              text: 'Reset password link was sent to your email',
            };
          })
          .catch((error) => {
            this.responseMessage = {
              type: 'error',
              text: error.response.data.message,
            };
          });
      }
    },
  },
};
</script>
