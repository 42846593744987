<template>
  <!-- eslint-disable -->
  <svg :width="width" :height="height" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g class="fill" transform="translate(-280.000000, -4000.000000)" :fill="color">
        <g transform="translate(0.000000, 3920.000000)">
          <g transform="translate(80.000000, 80.000000)">
            <g transform="translate(200.000000, 0.000000)">
              <path d="M3.63810142,4.8473511 C6.80325326,1.14412757 12.0271837,-0.0361504639 16.4769999,1.94657173 C16.9814723,2.17135153 17.2082081,2.76252732 16.9834282,3.2669998 C16.7586484,3.77147228 16.1674727,3.998208 15.6630002,3.77342819 C12.0222415,2.15120094 7.74811659,3.11688297 5.15844691,6.14679313 C2.56877722,9.1767033 2.28046508,13.5490667 4.44985971,16.892788 C6.61925433,20.2365092 10.7295699,21.7550583 14.5518173,20.6249391 C18.3740648,19.4948199 20.9977212,15.9852455 21,12 L21,12 L21,11.08 C21,10.5277152 21.4477153,10.08 22,10.08 C22.5522848,10.08 23,10.5277152 23,11.08 L23,11.08 L23,12.0005718 C22.9972145,16.8721262 19.7905232,21.1616061 15.1188875,22.5428629 C10.4472518,23.9241197 5.42353271,22.0681151 2.7720504,17.9813448 C0.120568078,13.8945744 0.472949583,8.55057463 3.63810142,4.8473511 Z M21.2925399,3.29324665 C21.682869,2.90252724 22.3160339,2.90221082 22.7067533,3.2925399 C23.0974727,3.68286897 23.0977891,4.31603387 22.7074601,4.70675328 L22.7074601,4.70675328 L12.7074601,14.7167533 C12.316993,15.1076108 11.6835555,15.1077691 11.2928932,14.7171068 L11.2928932,14.7171068 L8.2928932,11.7171068 C7.90236891,11.3265825 7.90236892,10.6934175 8.29289321,10.3028932 C8.6834175,9.91236892 9.31658248,9.91236892 9.70710677,10.3028932 L9.70710677,10.3028932 L11.9996465,12.595433 Z"></path>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
  <!-- eslint-enable -->
</template>

<script>
export default {
  name: 'CheckCircleSvg',
  props: {
    width: {
      type: String,
      default: '16px',
    },
    height: {
      type: String,
      default: '16px',
    },
    color: {
      type: String,
      default: '#000000',
    },
  },
};
</script>
