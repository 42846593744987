<template>
  <div class="auth-block">
    <div class="auth-block-body">
      <div class="auth-block__title">Candidate confirmation</div>

      <template v-if="responseMessage">
        <info-block
          v-if="responseMessage.type === 'success'"
          :type="responseMessage.type"
        >
          Congratulations! You are now part of the most exclusive tools in the
          staffing industry, TheVault!
          <br />
          <br />
          Your staffing agency will contact you as requests come in.
        </info-block>

        <info-block
          v-if="responseMessage.type === 'error'"
          :type="responseMessage.type"
        >
          {{ responseMessage.text }}
        </info-block>
      </template>
    </div>
  </div>
</template>

<script>
import InfoBlock from '@/components/utils/InfoBlock.vue';

export default {
  name: 'ConfirmCandidate',
  components: {
    InfoBlock,
  },
  data() {
    return {
      responseMessage: {
        type: null,
        text: null,
      },
    };
  },
  methods: {
    confirmCandidate() {
      this.$store.dispatch('candidate/confirmCandidate', this.$route.params.token)
        .then(() => {
          this.responseMessage.type = 'success';
        })
        .catch((error) => {
          this.responseMessage.type = 'error';
          this.responseMessage.text = error.response.data.message;
        });
    },
  },
  created() {
    this.confirmCandidate();
  },
};
</script>
