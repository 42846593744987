<template>
  <div class="custom-container">
    <div class="searches-header">
      <div class="searches-header__title">Continue with previous search or start a new one</div>
      <button
        class="button"
        @click="addSearch"
      >
        Start a new search
      </button>
    </div>
    <div class="searches-list">
      <div class="search" v-for="(search, index) in searches" :key="index">
        <div class="search-block">
          <router-link
            :to="{
              name: 'SearchAllCandidates',
              params: { id: search.id },
            }"
            class="search__title"
          >
            {{ search.name }}
          </router-link>

          <div class="search__job">Job function: {{ search.job_function.name }}</div>
        </div>

        <div class="search-block">
          <div class="search-actions">
            <span>Last activity: {{ search.last_activity }}</span>

            <button
              class="search-action"
              @click="editSearch(search)"
            >
              <edit-svg />
            </button>

            <button
              class="search-action"
              @click="openDeleteSearchConfirmation(search)"
            >
              <trash-svg />
            </button>
          </div>

          <div class="search-candidates">
            <router-link
              :to="{
                name: 'SearchSavedCandidates',
                params: { id: search.id },
              }"
              class="search-candidates__item"
            >
              {{ search.number_of_saved_candidates }} Saved Candidate(s)
            </router-link>
            <router-link
              :to="{
                name: 'SearchRequestedCandidates',
                params: { id: search.id },
              }"
              class="search-candidates__item"
            >
              {{ search.number_of_requested_candidates }} Requested Candidate(s)
            </router-link>
          </div>
        </div>
      </div>
    </div>

    <b-modal
      size="md"
      :title="searchModalTitle"
      v-model="searchModalStatus"
      no-close-on-backdrop
      centered
      @hidden="clearSearchForm"
    >
      <template slot="modal-header-close">
        <cross-svg />
      </template>

      <b-form @submit.prevent="submitSearchForm">
        <b-form-group
          id="nameGroup"
          label-for="name"
          :state="validateState('name')"
          invalid-feedback="This is a required field and must be at least 2 characters."
        >
          <template slot="label">
            Search Name <span class="text-danger">*</span>
          </template>
          <b-form-input
            id="name"
            type="text"
            v-model="$v.form.name.$model"
            :state="validateState('name')"
          ></b-form-input>
        </b-form-group>

        <b-form-group
          id="jobFunctionGroup"
          label-for="jobFunction"
          invalid-feedback="Please, select at least one."
          :state="validateState('job_function_id')"
        >
          <template slot="label">
            Job function <span class="text-danger">*</span>
          </template>

          <multiselect
            id="industry"
            class="custom-multiselect"
            placeholder="— Select —"
            v-model="form.job_function_id"
            :options="jobFunctionOptions.map(option => option.id)"
            :custom-label="opt => jobFunctionOptions.find(x => x.id === opt).name"
            @close="$v.form.job_function_id.$touch()"
          ></multiselect>
        </b-form-group>
      </b-form>

      <template slot="modal-footer">
        <button
          class="action-label action-label--dark"
          @click="changeSearchModalStatus(false)"
        >
          Cancel
        </button>

        <button
          class="button"
          @click="submitSearchForm"
        >
          {{ searchModalSubmitText }}
        </button>
      </template>
    </b-modal>

    <b-modal
      v-if="searchToDelete"
      size="md"
      title="Delete search"
      v-model="confirmationModalStatus"
      centered
    >
      <template slot="modal-header-close">
        <cross-svg />
      </template>

      <p>Are sure you want to delete search <b>"{{ searchToDelete.name }}"</b>?</p>

      <template slot="modal-footer">
        <button
          class="action-label action-label--dark"
          @click="closeDeleteSearchConfirmation"
        >
          Cancel
        </button>

        <button
          class="button"
          @click="deleteSearch(searchToDelete.id)"
        >
          Delete
        </button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import {
  BModal, BForm, BFormGroup, BFormInput,
} from 'bootstrap-vue';
import { validationMixin } from 'vuelidate';
import { required, minLength } from 'vuelidate/lib/validators';
import Multiselect from 'vue-multiselect';
import EditSvg from '@/components/svg/EditSvg.vue';
import TrashSvg from '@/components/svg/TrashSvg.vue';
import CrossSvg from '@/components/svg/CrossSvg.vue';

export default {
  name: 'Searches',
  components: {
    BModal,
    BForm,
    BFormGroup,
    BFormInput,
    Multiselect,
    EditSvg,
    TrashSvg,
    CrossSvg,
  },
  mixins: [validationMixin],
  data() {
    return {
      searches: [],
      form: {
        name: null,
        job_function_id: null,
      },
      searchModalStatus: false,
      searchModalType: false,
      searchModalTitle: null,
      searchModalSubmitText: null,
      jobFunctionOptions: [],
      confirmationModalStatus: false,
      searchToEditId: null,
      searchToDelete: null,
    };
  },
  validations: {
    form: {
      name: {
        required,
        minLength: minLength(2),
      },
      job_function_id: {
        required,
      },
    },
  },
  methods: {
    getSearches() {
      this.$store.dispatch('search/getSearches')
        .then((response) => {
          this.searches = response.data.searches;
        })
        .catch((error) => {
          window.flash(error.response.data.message, 'error');
        });
    },
    getJobFunctionOptions() {
      return new Promise((resolve, reject) => {
        this.$store.dispatch('search/getJobFunctionOptions')
          .then((response) => {
            this.jobFunctionOptions = response.data.job_functions;
            resolve();
          })
          .catch((error) => {
            window.flash(error.response.data.message, 'error');
            reject();
          });
      });
    },
    changeSearchModalStatus(status) {
      this.searchModalStatus = status;
    },
    addSearch() {
      this.searchModalType = 'add';
      this.searchModalTitle = 'New Search';
      this.searchModalSubmitText = 'Start new Search';

      if (!this.jobFunctionOptions || !this.jobFunctionOptions.length) {
        this.getJobFunctionOptions()
          .then(() => {
            this.changeSearchModalStatus(true);
          });
      } else {
        this.changeSearchModalStatus(true);
      }
    },
    editSearch(search) {
      this.searchModalType = 'edit';
      this.searchModalTitle = 'Edit Search';
      this.searchModalSubmitText = 'Update Search';

      this.form = {
        name: search.name,
        job_function_id: search.job_function.id,
      };

      this.searchToEditId = search.id;

      if (!this.jobFunctionOptions || !this.jobFunctionOptions.length) {
        this.getJobFunctionOptions()
          .then(() => {
            this.changeSearchModalStatus(true);
          });
      } else {
        this.changeSearchModalStatus(true);
      }
    },
    clearSearchForm() {
      this.$v.form.$reset();

      this.searchModalType = null;
      this.searchModalTitle = null;
      this.searchModalSubmitText = null;

      this.form = {
        name: null,
        job_function_id: null,
      };

      this.searchToEditId = null;
    },
    submitSearchForm() {
      this.$v.form.$reset();
      this.$v.form.$touch();

      if (!this.$v.form.$anyError) {
        if (this.searchModalType === 'add') {
          this.createSearch();
        } else if (this.searchModalType === 'edit') {
          this.updateSearch();
        }
      }
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    createSearch() {
      this.$store.dispatch('search/createSearch', this.form)
        .then((response) => {
          window.flash('Search successfully created!', 'success');
          this.changeSearchModalStatus(false);

          this.searches.unshift(response.data);
        })
        .catch((error) => {
          window.flash(error.response.data.message, 'error');
        });
    },
    updateSearch() {
      this.$store.dispatch('search/updateSearch', {
        searchId: this.searchToEditId,
        searchData: this.form,
      })
        .then((response) => {
          window.flash('Search successfully updated!', 'success');
          this.changeSearchModalStatus(false);

          const searchIndex = this.searches
            .findIndex((search) => search.id === this.searchToEditId);

          this.$set(this.searches, searchIndex, response.data);
        })
        .catch((error) => {
          window.flash(error.response.data.message, 'error');
        });
    },
    openDeleteSearchConfirmation(search) {
      this.searchToDelete = search;
      this.confirmationModalStatus = true;
    },
    closeDeleteSearchConfirmation() {
      this.searchToDelete = null;
      this.confirmationModalStatus = false;
    },
    deleteSearch(id) {
      this.$store.dispatch('search/deleteSearch', id)
        .then(() => {
          this.closeDeleteSearchConfirmation();
          window.flash('Search successfully deleted!', 'success');

          const searchIndex = this.searches
            .findIndex((search) => search.id === id);

          this.searches.splice(searchIndex, 1);
        })
        .catch((error) => {
          window.flash(error.response.data.message, 'error');
        });
    },
  },
  created() {
    this.getSearches();
  },
};
</script>

<style lang="scss" scoped>
@import 'src/assets/sass/views/employer/searches.scss';
</style>
