var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{ref:"file-wrapper",staticClass:"form-file",attrs:{"tabindex":"0"},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.activateFileInput($event)},"click":_vm.activateFileInput}},[_c('span',{staticClass:"form-file__icon"},[_c('upload-cloud-svg')],1),(_vm.file)?[_c('span',{staticClass:"form-file__title",attrs:{"title":_vm.fileName + '.' + _vm.fileType}},[_vm._v(" "+_vm._s(_vm.fileName)+"."+_vm._s(_vm.fileType)+" ")]),_c('span',{staticClass:"form-file__text form-file__size"},[_vm._v(" "+_vm._s(_vm.fileSize)+" ")])]:[_c('span',{staticClass:"form-file__title"},[_vm._v(" "+_vm._s(_vm.title)+" "),(_vm.required)?_c('span',{staticClass:"text-danger"},[_vm._v("*")]):_vm._e()]),_c('span',{staticClass:"form-file__text"},[_vm._v(" "+_vm._s(_vm.description)+" Filetype: "+_vm._s(_vm.allowedTypesString)+". ")])],_c('input',{ref:"file-input",attrs:{"type":"file","hidden":""},on:{"change":_vm.selectFile}})],2),_c('b-modal',{ref:"cropImageModal",attrs:{"size":"md","title":"Crop image","no-close-on-backdrop":"","centered":""}},[_c('template',{slot:"modal-header-close"},[_c('cross-svg')],1),(_vm.fileUrl)?_c('cropper',{staticClass:"cropper",attrs:{"src":_vm.fileUrl,"stencil-props":{
        handlers: {},
        movable: true,
        scalable: false,
        aspectRatio: 1,
      },"stencilSize":_vm.defaultCropperSize,"resize-image":{
        adjustStencil: true
      },"defaultBoundaries":"fit"},on:{"change":_vm.onCropperChange}}):_vm._e(),_c('template',{slot:"modal-footer"},[_c('button',{staticClass:"action-label action-label--dark",on:{"click":_vm.closeCropModal}},[_vm._v(" Cancel ")]),_c('button',{staticClass:"button button--small",on:{"click":_vm.saveCroppedImage}},[_vm._v(" Save ")])])],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }