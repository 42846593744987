import axios from 'axios';

const actions = {
  createRequest(context, data) {
    return new Promise((resolve, reject) => {
      axios.post('/api/v1/requests/', data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  updateRequest(context, data) {
    return new Promise((resolve, reject) => {
      axios.put(`/api/v1/requests/${data.requestId}`, data.requestData)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  deleteRequest(context, id) {
    return new Promise((resolve, reject) => {
      axios.delete(`/api/v1/requests/${id}`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  contactAgency(context, data) {
    return new Promise((resolve, reject) => {
      axios.put(`/api/v1/requests/${data.requestId}/touch`, data.data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

export default {
  namespaced: true,
  actions,
};
