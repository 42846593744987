<template>
  <!-- eslint-disable -->
  <svg :width="width" :height="height" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g class="fill" transform="translate(-240.000000, -4000.000000)" :fill="color">
        <g transform="translate(0.000000, 3920.000000)">
          <g transform="translate(80.000000, 80.000000)">
            <g transform="translate(160.000000, 0.000000)">
              <path d="M8.99999997,15.5857864 L4.70710675,11.2928932 C4.31658246,10.9023689 3.68341748,10.9023689 3.29289319,11.2928932 C2.90236889,11.6834175 2.90236889,12.3165825 3.29289319,12.7071068 L8.29289319,17.7071068 C8.68341748,18.097631 9.31658246,18.097631 9.70710675,17.7071068 L20.7071067,6.70710676 C21.097631,6.31658246 21.097631,5.68341749 20.7071067,5.29289319 C20.3165825,4.9023689 19.6834175,4.9023689 19.2928932,5.29289319 L8.99999997,15.5857864 Z"></path>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
  <!-- eslint-enable -->
</template>

<script>
export default {
  name: 'CheckSvg',
  props: {
    width: {
      type: String,
      default: '16px',
    },
    height: {
      type: String,
      default: '16px',
    },
    color: {
      type: String,
      default: '#000000',
    },
  },
};
</script>
