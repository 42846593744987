<template>
  <div class="auth-block">
    <div class="auth-block-body">
      <div class="auth-block__title">Confirm email</div>

      <template v-if="responseMessage">
        <info-block
          v-if="responseMessage.type === 'success'"
          :type="responseMessage.type"
        >
          Email was successfully confirmed! Now you can
          <router-link
            :to="{ name: 'SignIn', params: { type: this.$route.params.type } }"
            class="link-regular"
          >
            Sign In
          </router-link>.
        </info-block>

        <info-block
          v-if="responseMessage.type === 'error'"
          :type="responseMessage.type"
        >
          {{ responseMessage.text }}

          <template v-if="responseMessage.status === 'expired'">
            <br />
            <br />
            You can re-send the confirmation link to your email at the
            <router-link
              :to="{ name: 'SendEmailConfirmationLink' }"
              class="link-regular"
            >
              link
            </router-link>
          </template>
        </info-block>
      </template>
    </div>
  </div>
</template>

<script>
import InfoBlock from '@/components/utils/InfoBlock.vue';

export default {
  name: 'ConfirmEmail',
  components: {
    InfoBlock,
  },
  data() {
    return {
      responseMessage: {
        status: null,
        type: null,
        text: null,
      },
    };
  },
  methods: {
    confirmEmail() {
      this.$store.dispatch('auth/confirmEmail', this.$route.params.token)
        .then(() => {
          this.responseMessage.type = 'success';
        })
        .catch((error) => {
          this.responseMessage.status = error.response.data.status;
          this.responseMessage.type = 'error';
          this.responseMessage.text = error.response.data.message;
        });
    },
  },
  created() {
    this.confirmEmail();
  },
};
</script>
