<template>
  <b-form class="profile-form profile-password-form" @submit.prevent="submitForm">
    <b-form-group
      id="currentPasswordGroup"
      label-for="currentPassword"
      invalid-feedback="This is a required field."
      :state="validateState('old_password')"
    >
      <template slot="label">
        Current Password <span class="text-danger">*</span>
      </template>
      <b-form-input
        id="currentPassword"
        type="password"
        v-model="$v.form.old_password.$model"
        :state="validateState('old_password')"
      ></b-form-input>
    </b-form-group>

    <b-form-group
      id="newPasswordGroup"
      label-for="newPassword"
      invalid-feedback="This is a required field and must be at least 6 characters."
      :state="validateState('password1')"
    >
      <template slot="label">
        New Password <span class="text-danger">*</span>
      </template>
      <b-form-input
        id="newPassword"
        type="password"
        v-model="$v.form.password1.$model"
        :state="validateState('password1')"
      ></b-form-input>
    </b-form-group>

    <b-form-group
      id="confirmNewPasswordGroup"
      label-for="confirmNewPassword"
      invalid-feedback="This is a required field."
      :state="validateState('password2')"
    >
      <template slot="label">
        Confirm New Password <span class="text-danger">*</span>
      </template>
      <b-form-input
        id="confirmNewPassword"
        type="password"
        v-model="$v.form.password2.$model"
        :state="validateState('password2')"
      ></b-form-input>
    </b-form-group>

    <div class="form-buttons profile-form-buttons">
      <button
        class="button"
        type="submit"
      >
        Update Password
      </button>
    </div>
  </b-form>
</template>

<script>
import { BForm, BFormGroup, BFormInput } from 'bootstrap-vue';
import { validationMixin } from 'vuelidate';
import { minLength, required, sameAs } from 'vuelidate/lib/validators';

export default {
  name: 'ProfileChangePassword',
  components: {
    BForm,
    BFormGroup,
    BFormInput,
  },
  mixins: [validationMixin],
  data() {
    return {
      form: {
        old_password: null,
        password1: null,
        password2: null,
      },
    };
  },
  validations: {
    form: {
      old_password: {
        required,
      },
      password1: {
        required,
        minLength: minLength(6),
      },
      password2: {
        required,
        minLength: minLength(6),
        sameAs: sameAs('password1'),
      },
    },
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    submitForm() {
      this.$v.form.$reset();
      this.$v.form.$touch();

      if (!this.$v.form.$anyError) {
        this.$store.dispatch('auth/changePassword', this.form)
          .then(() => {
            this.clearForm();
            window.flash('Password changed successfully', 'success');
          })
          .catch((error) => {
            window.flash(error.response.data.message, 'error');
          });
      }
    },
    clearForm() {
      this.$v.form.$reset();

      this.form = {
        old_password: null,
        password1: null,
        password2: null,
      };
    },
  },
};
</script>
