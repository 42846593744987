<template>
  <!-- eslint-disable -->
  <svg width="16px" height="16px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" fill-opacity="0.7">
      <g class="fill" transform="translate(-200.000000, -4000.000000)" fill="#000000">
        <g transform="translate(0.000000, 3920.000000)">
          <g transform="translate(80.000000, 80.000000)">
            <g transform="translate(120.000000, 0.000000)">
              <path d="M12,1.89725596 C13.0486293,1.89725596 14.0211515,2.44479395 14.5678674,3.34612889 L14.5678674,3.34612889 L23.0459906,17.4999396 C23.5790281,18.4230391 23.5822107,19.55966 23.0543508,20.48573 C22.5264909,21.4117999 21.5468725,21.9882243 20.47,22 L20.47,22 L3.51900943,22 C2.45312746,21.9882243 1.47350909,21.4117999 0.945649208,20.48573 C0.417789326,19.55966 0.420971868,18.4230391 0.962132536,17.4861289 L0.962132536,17.4861289 L9.43213257,3.34612889 C9.97884849,2.44479395 10.9513707,1.89725596 12,1.89725596 Z M12,3.89725596 C11.6512233,3.89725596 11.3277045,4.07896916 11.1461945,4.37666383 L11.1461945,4.37666383 L2.68599049,18.5000604 C2.5083113,18.8077602 2.50725045,19.1866339 2.68320375,19.4953239 C2.85915704,19.8040139 3.1856965,19.9961553 3.52999996,20.0000604 L3.52999996,20.0000604 L20.4590095,20.0000604 C20.8143035,19.9961553 21.140843,19.8040139 21.3167963,19.4953239 C21.4927495,19.1866339 21.4916887,18.8077602 21.3221326,18.5138711 L21.3221326,18.5138711 L12.855,4.37862796 C12.6737172,4.07976862 12.3495431,3.89725596 12,3.89725596 Z M11.2928932,16.2928932 C11.6834175,15.9023689 12.3165825,15.9023689 12.7071068,16.2928932 C13.0976311,16.6834175 13.0976311,17.3165825 12.7071068,17.7071068 C12.3165825,18.0976311 11.6834175,18.0976311 11.2928932,17.7071068 C10.9023689,17.3165825 10.9023689,16.6834175 11.2928932,16.2928932 Z M12,7.99999999 C12.5522847,7.99999999 13,8.44771524 13,8.99999999 L13,8.99999999 L13,13 C13,13.5522847 12.5522847,14 12,14 C11.4477152,14 11,13.5522847 11,13 L11,13 L11,8.99999999 C11,8.44771524 11.4477152,7.99999999 12,7.99999999 Z"></path>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
  <!-- eslint-enable -->
</template>

<script>
export default {
  name: 'AlertTriangleSvg',
};
</script>
