<template>
  <div class="auth-block">
    <div class="auth-block-body">
      <div class="auth-block__title">Reset password</div>

      <b-form class="auth-form" @submit.prevent="submitForm">
        <b-form-group
          id="passwordGroup"
          label-for="password"
          invalid-feedback="This is a required field and must be at least 6 characters."
        >
          <template slot="label">
            New password <span class="text-danger">*</span>
          </template>
          <b-form-input
            id="password"
            type="password"
            v-model="$v.form.password.$model"
            :state="validateState('password')"
          ></b-form-input>
        </b-form-group>

        <b-form-group
          id="passwordConfirmGroup"
          label-for="passwordConfirm"
          invalid-feedback="This is a required field."
        >
          <template slot="label">
            Confirm new password <span class="text-danger">*</span>
          </template>
          <b-form-input
            id="passwordConfirm"
            type="password"
            v-model="$v.form.passwordConfirm.$model"
            :state="validateState('passwordConfirm')"
          ></b-form-input>
        </b-form-group>

        <div class="form-buttons">
          <button
            class="button"
            type="submit"
          >
            Set new password
          </button>
          <router-link
            :to="{ name: 'Landing' }"
            class="action-label action-label--dark"
          >
            Cancel
          </router-link>
        </div>
      </b-form>
    </div>
  </div>
</template>

<script>
import { BForm, BFormGroup, BFormInput } from 'bootstrap-vue';
import { validationMixin } from 'vuelidate';
import { required, sameAs, minLength } from 'vuelidate/lib/validators';

export default {
  name: 'ResetPassword',
  components: {
    BForm,
    BFormGroup,
    BFormInput,
  },
  mixins: [validationMixin],
  data() {
    return {
      form: {
        password: null,
        passwordConfirm: null,
      },
    };
  },
  validations: {
    form: {
      password: {
        required,
        minLength: minLength(6),
      },
      passwordConfirm: {
        required,
        minLength: minLength(6),
        sameAs: sameAs('password'),
      },
    },
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    submitForm() {
      this.$v.form.$reset();
      this.$v.form.$touch();

      if (!this.$v.form.$anyError) {
        this.$store.dispatch('auth/setNewPassword', {
          token: this.$route.params.token,
          password1: this.form.password,
          password2: this.form.passwordConfirm,
        })
          .then(() => {
            this.$router.push({
              name: 'SignIn',
              params: {
                type: this.$route.params.type,
                passwordResetMessage: true,
              },
            });
          })
          .catch((error) => {
            window.flash(error.response.data.message, 'error');
          });
      }
    },
  },
};
</script>
