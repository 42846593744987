<template>
  <div
    class="page-alert"
    :class="statusClass"
    role="alert"
    v-if="showFlashMessage"
  >
    <div class="page-alert__text" v-html="body"></div>

    <div class="page-alert__close" @click="hide">
      <cross-svg />
    </div>
  </div>
</template>

<script>
import CrossSvg from '@/components/svg/CrossSvg.vue';

export default {
  name: 'Flash',
  components: {
    CrossSvg,
  },
  props: {
    message: {
      type: String,
      default: 'Unknown error!',
    },
    status: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      statusClass: '',
      body: '',
    };
  },
  computed: {
    showFlashMessage() {
      return this.$store.state.flashMessageStatus;
    },
  },
  created() {
    if (this.message && this.status) {
      this.flash(this.message, this.status);
    }
    window.events.$on('flash', (message, status) => this.flash(message, status));
  },
  methods: {
    flash(message, status) {
      this.$store.commit('setFlashMessageStatus', true);
      this.statusClass = status;

      this.body = message;

      let speed;

      if (status === 'success') {
        speed = 5000;
      } else {
        speed = 10000;
      }

      setTimeout(() => {
        this.hide();
      }, speed);
    },
    hide() {
      this.$store.commit('setFlashMessageStatus', false);
    },
  },
};
</script>
