<template>
  <div class="col-xl-4 col-md-6 col-12 landing-card-wrapper">
    <div class="landing-card">
      <div class="landing-card-body">
        <div class="landing-card__image">
          <img class="fit-image" :src="card.image" :alt="card.title">
        </div>
        <h2 class="landing-card__title">{{ card.title }}</h2>
        <h3 class="landing-card__subtitle">{{ card.subtitle }}</h3>
        <div class="landing-card__text">{{ card.text }}</div>
      </div>
      <div class="landing-card-footer">
        <button
          v-if="card.id === 'candidates'"
          class="button"
          @click="showConnectCandidateModal"
        >
          {{ card.modal.buttonTitle }}
        </button>

        <router-link
          v-else
          class="button"
          :to="{ name: 'SignUp', params: { type: card.linkType } }"
        >
          {{ card.actionButtonTitle }}
        </router-link>

        <button
          class="action-label action-label--dark"
          @click="changeCardModalStatus(true)"
        >
          {{ card.learnMoreButtonTitle }}
        </button>
      </div>
    </div>

    <landing-card-modal
      :card="card"
      :modal-status="cardModalStatus"
      @onChange="changeCardModalStatus"
      @onShowConnectCandidateModal="showConnectCandidateModal"
    ></landing-card-modal>
  </div>
</template>

<script>
import LandingCardModal from '@/components/landing/LandingCardModal.vue';

export default {
  name: 'LandingCard',
  components: {
    LandingCardModal,
  },
  props: {
    card: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      cardModalStatus: false,
    };
  },
  methods: {
    changeCardModalStatus(status) {
      this.cardModalStatus = status;
    },
    showConnectCandidateModal() {
      this.$emit('onShowConnectCandidateModal');
    },
  },
};
</script>
