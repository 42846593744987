<template>
  <!-- eslint-disable -->
  <svg :width="width" :height="height" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g class="fill" transform="translate(-120.000000, -4080.000000)" :fill="color">
        <g transform="translate(0.000000, 3920.000000)">
          <g transform="translate(80.000000, 80.000000)">
            <g transform="translate(40.000000, 80.000000)">
              <path d="M12,1 C18.0751323,1 23,5.92486774 23,12 C23,18.0751323 18.0751323,23 12,23 C5.92486774,23 1,18.0751323 1,12 C1,5.92486777 5.92486777,1 12,1 Z M4.96769257,6.38282435 C3.7363102,7.92241643 3,9.87522099 3,12 C3,16.9705628 7.02943724,21 12,21 C14.124779,21 16.0775836,20.2636898 17.6171757,19.0323075 Z M12,3 C9.87522099,3 7.92241643,3.7363102 6.38282435,4.96769257 L19.0323075,17.6171757 C20.2636898,16.0775836 21,14.124779 21,12 C21,7.02943724 16.9705628,3 12,3 Z" id="icon"></path>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
  <!-- eslint-enable -->
</template>

<script>
export default {
  name: 'StopSvg',
  props: {
    width: {
      type: String,
      default: '16px',
    },
    height: {
      type: String,
      default: '16px',
    },
    color: {
      type: String,
      default: '#000000',
    },
  },
};
</script>
