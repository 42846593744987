<template>
  <b-form class="profile-form" @submit.prevent="submitForm">
    <div class="form-row">
      <b-form-group
        id="agencyNameGroup"
        label-for="agencyName"
        invalid-feedback="This is a required field and must be at least 2 characters."
      >
        <template slot="label">
          Agency Name <span class="text-danger">*</span>
        </template>
        <b-form-input
          id="agencyName"
          type="text"
          v-model="$v.form.name.$model"
          :state="validateState('name')"
        ></b-form-input>
      </b-form-group>

      <b-form-group
        id="agencyWebsiteGroup"
        label-for="agencyWebsite"
        invalid-feedback="This is a required field and must be at least 2 characters."
      >
        <template slot="label">
          Agency Website <span class="text-danger">*</span>
        </template>
        <b-form-input
          id="agencyWebsite"
          type="text"
          v-model="$v.form.website.$model"
          :state="validateState('website')"
        ></b-form-input>
      </b-form-group>
    </div>

    <b-form-group
      id="specializationsGroup"
      label-for="specializations"
      invalid-feedback="Please, select at least one."
      :state="validateState('specialization_ids')"
    >
      <template slot="label">
        What your agency specializes <span class="text-danger">*</span>
      </template>

      <multiselect
        id="specializations"
        class="custom-multiselect"
        placeholder="— Select —"
        v-model="form.specialization_ids"
        :options="specializationOptions.map(option => option.id)"
        :custom-label="opt => specializationOptions.find(x => x.id === opt).name"
        :multiple="true"
        :closeOnSelect="false"
        @close="$v.form.specialization_ids.$touch()"
      >
        <template slot="tag" slot-scope="props">
          <div class="multiselect__tag">
            <span>
              {{ specializationOptions.find(x => x.id === props.option).name }}
            </span>
            <div class="multiselect__tag-remove" @mousedown.prevent="props.remove(props.option)">
              <cross-svg />
            </div>
          </div>
        </template>
      </multiselect>
    </b-form-group>

    <b-form-group
      id="locationsGroup"
      label-for="locations"
      invalid-feedback="Please, select at least one."
      :state="validateState('location_ids')"
    >
      <template slot="label">
        What locations your agency recruits for <span class="text-danger">*</span>
      </template>

      <multiselect
        id="locations"
        class="custom-multiselect"
        placeholder="— Select —"
        v-model="form.location_ids"
        :options="locationOptions.map(option => option.id)"
        :custom-label="opt => locationOptions.find(x => x.id === opt).name"
        :multiple="true"
        :closeOnSelect="false"
        @close="$v.form.location_ids.$touch()"
      >
        <template slot="tag" slot-scope="props">
          <div class="multiselect__tag">
            <span>
              {{ locationOptions.find(x => x.id === props.option).name }}
            </span>
            <div class="multiselect__tag-remove" @mousedown.prevent="props.remove(props.option)">
              <cross-svg />
            </div>
          </div>
        </template>
      </multiselect>
    </b-form-group>

    <div class="form-row">
      <b-form-group
        id="contactNameGroup"
        label-for="contactName"
        invalid-feedback="This is a required field and must be at least 2 characters."
      >
        <template slot="label">
          Contact Name <span class="text-danger">*</span>
        </template>
        <b-form-input
          id="contactName"
          type="text"
          v-model="$v.form.contact_name.$model"
          :state="validateState('contact_name')"
        ></b-form-input>
      </b-form-group>

      <b-form-group
        id="contactTitleGroup"
        label-for="contactTitle"
        invalid-feedback="This is a required field and must be at least 2 characters."
      >
        <template slot="label">
          Contact Title <span class="text-danger">*</span>
        </template>
        <b-form-input
          id="contactTitle"
          type="text"
          v-model="$v.form.contact_title.$model"
          :state="validateState('contact_title')"
        ></b-form-input>
      </b-form-group>
    </div>

    <b-form-group
      id="emailGroup"
      label-for="email"
      invalid-feedback="This is a required field and must be email."
    >
      <template slot="label">
        Contact Email <span class="text-danger">*</span>
      </template>
      <b-form-input
        id="email"
        type="email"
        v-model="$v.form.email.$model"
        :state="validateState('email')"
      ></b-form-input>
    </b-form-group>

    <div class="form-buttons profile-form-buttons">
      <button
        class="button"
        type="submit"
      >
        Update Profile
      </button>
    </div>
  </b-form>
</template>

<script>
import {
  BForm, BFormGroup, BFormInput,
} from 'bootstrap-vue';
import { validationMixin } from 'vuelidate';
import {
  email, minLength, required,
} from 'vuelidate/lib/validators';
import Multiselect from 'vue-multiselect';
import CrossSvg from '@/components/svg/CrossSvg.vue';

import { mapGetters } from 'vuex';

export default {
  name: 'AgencyProfile',
  components: {
    BForm,
    BFormGroup,
    BFormInput,
    Multiselect,
    CrossSvg,
  },
  mixins: [validationMixin],
  data() {
    return {
      form: {
        name: null,
        website: null,
        specialization_ids: [],
        location_ids: [],
        contact_name: null,
        contact_title: null,
        email: null,
      },
      locationOptions: [],
      specializationOptions: [],
    };
  },
  validations: {
    form: {
      name: {
        required,
        minLength: minLength(2),
      },
      website: {
        required,
        minLength: minLength(2),
      },
      specialization_ids: {
        required,
      },
      location_ids: {
        required,
      },
      contact_name: {
        required,
        minLength: minLength(2),
      },
      contact_title: {
        required,
        minLength: minLength(2),
      },
      email: {
        required,
        email,
      },
    },
  },
  computed: {
    ...mapGetters({
      authUserId: 'auth/getAuthUserId',
    }),
  },
  methods: {
    getProfileData() {
      Promise.all([
        this.$store.dispatch('agency/getAgencyProfile', this.authUserId),
        this.$store.dispatch('agency/getAgencyOptions'),
      ])
        .then((response) => {
          this.form = response[0].data;
          this.$v.form.$touch();

          this.locationOptions = response[1].data.location_options;
          this.specializationOptions = response[1].data.specialization_options;
        })
        .catch((error) => {
          window.flash(error.response.data.message, 'error');
        });
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    submitForm() {
      this.$v.form.$reset();
      this.$v.form.$touch();

      if (!this.$v.form.$anyError) {
        this.$store.dispatch('agency/updateAgencyProfile', {
          agencyId: this.authUserId,
          agencyData: this.form,
        })
          .then(() => {
            window.flash('Agency updated successfully', 'success');
          })
          .catch((error) => {
            window.flash(error.response.data.message, 'error');
          });
      }
    },
  },
  created() {
    if (this.authUserId) {
      this.getProfileData();
    }

    this.authUserIdWatcher = this.$store.watch(
      (state, getters) => getters['auth/getAuthUserId'],
      (newValue) => {
        if (newValue) {
          this.getProfileData();
        }
      },
    );
  },
  beforeDestroy() {
    this.authUserIdWatcher();
  },
};
</script>
