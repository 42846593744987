import axios from 'axios';

const state = {
  zipCodeApiKey: 'ed165f00-5b09-11eb-9ab5-6da38884186f',
};

const actions = {
  getZipCodeInfo(context, zipCode) {
    return new Promise((resolve, reject) => {
      axios.get(`https://app.zipcodebase.com/api/v1/search?apikey=${state.zipCodeApiKey}&codes=${zipCode}&country=us`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  actions,
};
