<template>
  <!-- eslint-disable -->
  <svg width="24px" height="24px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g class="fill" transform="translate(-80.000000, -4040.000000)" fill="#000000">
        <g transform="translate(0.000000, 3920.000000)">
          <g transform="translate(80.000000, 80.000000)">
            <g transform="translate(0.000000, 40.000000)">
              <path d="M21,19 C21.5522847,19 22,19.4477153 22,20 C22,20.5128358 21.6139598,20.9355072 21.1166211,20.9932723 L21,21 L12,21 C11.4477153,21 11,20.5522847 11,20 C11,19.4871642 11.3860402,19.0644928 11.8833789,19.0067277 L12,19 L21,19 Z M15.7928932,2.79289322 C16.5814165,2.00436991 17.7307143,1.69641651 18.8078572,1.98503607 C19.885,2.27365563 20.7263444,3.11499998 21.0149639,4.19214285 C21.3035835,5.26928572 20.9956301,6.41858348 20.2071068,7.20710678 L20.2071068,7.20710678 L7.70710678,19.7071068 C7.57894796,19.8352656 7.41836808,19.9261844 7.24253563,19.9701425 L7.24253563,19.9701425 L3.24253563,20.9701425 C2.51015516,21.1532376 1.84676238,20.4898448 2.0298575,19.7574644 L2.0298575,19.7574644 L3.0298575,15.7574644 C3.07381561,15.5816319 3.1647344,15.421052 3.29289322,15.2928932 L3.29289322,15.2928932 Z M18.2902191,3.91688772 C17.9032603,3.81320244 17.4903802,3.92383332 17.2071068,4.20710678 L17.2071068,4.20710678 L4.903,16.511 L4.374,18.625 L6.488,18.096 L18.7928932,5.79289322 C19.0446919,5.54109458 19.1600815,5.18689073 19.1097936,4.83951389 L19.1097936,4.83951389 L19.0831123,4.70978094 C18.979427,4.32282219 18.6771778,4.020573 18.2902191,3.91688772 Z"></path>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
  <!-- eslint-enable -->
</template>

<script>
export default {
  name: 'EditSvg',
};
</script>
