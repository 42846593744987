<template>
  <!-- eslint-disable -->
  <svg width="24px" height="24px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g class="fill" transform="translate(-80.000000, -4080.000000)" fill="#000000">
        <g transform="translate(0.000000, 3920.000000)">
          <g transform="translate(80.000000, 80.000000)">
            <g transform="translate(0.000000, 80.000000)">
              <path d="M16,1.99999996 C16.2652165,1.99999996 16.5195704,2.1053568 16.7071067,2.29289318 L21.7071067,7.29289318 C21.8946431,7.48042956 22,7.73478347 22,7.99999996 L22,19 C22,20.6568543 20.6568543,22 19,22 L4.99999996,22 C3.3431457,22 1.99999996,20.6568543 1.99999996,19 L1.99999996,4.99999996 C1.99999996,3.34314572 3.34314572,1.99999996 4.99999996,1.99999996 L16,1.99999996 Z M15.5857864,3.99999996 L7.99999996,3.99999996 L7.99999998,6.99999996 L15,6.99999996 C15.5522847,6.99999996 16,7.44771521 16,7.99999996 C16,8.55228471 15.5522847,8.99999996 15,8.99999996 L15,8.99999996 L6.99999998,8.99999996 C6.44771523,8.99999996 5.99999998,8.55228471 5.99999998,7.99999996 L5.99999998,7.99999996 L5.99999996,3.99999996 L4.99999996,3.99999996 C4.44771522,3.99999996 3.99999996,4.44771522 3.99999996,4.99999996 L3.99999996,19 C3.99999996,19.5522848 4.44771518,20 4.99999996,20 L5.99999996,20 L5.99999998,13 C5.99999998,12.4871642 6.38604017,12.0644928 6.88337885,12.0067277 L6.99999998,12 L17,12 C17.5522847,12 18,12.4477153 18,13 L18,13 L18,20 L19,20 C19.5522848,20 20,19.5522848 20,19 L20,8.41421352 L15.5857864,3.99999996 Z M16,14 L7.99999998,14 L7.99999996,20 L16,20 L16,14 Z"></path>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
  <!-- eslint-enable -->
</template>

<script>
export default {
  name: 'SaveSvg',
};
</script>
