<template>
  <div class="custom-container">
    <div class="filter">
      <div class="filter__item">
        <multiselect
          id="candidateName"
          class="custom-multiselect"
          placeholder="- Select Candidate Name -"
          v-model="filter.candidate_id"
          :options="candidateNameOptions.map(option => option.id)"
          :custom-label="opt => candidateNameOptions.find(x => x.id === opt).full_name"
          @input="getRequestedCandidates()"
        ></multiselect>
      </div>

      <div class="filter__item">
        <b-form-checkbox
          switch
          v-model="filter.pending_only"
          @input="getRequestedCandidates()"
        >
          Show pending request only
        </b-form-checkbox>
      </div>
    </div>

    <div v-if="candidates && candidates.length" class="candidates-list">
      <requested-candidate
        v-for="(candidate, index) in candidates"
        :key="index"
        :candidate="candidate"
        @onUpdateRequest="updateRequest"
      />
    </div>

    <div v-else class="empty-list">
      No requests
    </div>
  </div>
</template>

<script>
import { BFormCheckbox } from 'bootstrap-vue';
import Multiselect from 'vue-multiselect';
import RequestedCandidate from '@/components/candidate/RequestedCandidate.vue';

export default {
  name: 'RequestedCandidates',
  components: {
    BFormCheckbox,
    Multiselect,
    RequestedCandidate,
  },
  data() {
    return {
      candidates: [],
      filter: {
        candidate_id: null,
        pending_only: false,
      },
      candidateNameOptions: [],
    };
  },
  methods: {
    getRequestedCandidates(filters = 0) {
      this.$store.dispatch('agency/getRequestedCandidates', {
        params: {
          candidate_id: this.filter.candidate_id,
          pending_only: this.filter.pending_only,
          need_filters: filters,
        },
      })
        .then((response) => {
          this.candidates = response.data.candidates;

          if (response.data.filters) {
            this.candidateNameOptions = response.data.filters.candidates;
          }
        })
        .catch((error) => {
          window.flash(error.response.data.message, 'error');
        });
    },
    updateRequest({ requestId, status, message }) {
      this.$store.dispatch('request/updateRequest', {
        requestId,
        requestData: { status, message },
      })
        .then(() => {
          if (status === 'approved') {
            window.flash('Request was successfully accepted', 'success');
          }

          if (status === 'declined') {
            window.flash('Request was successfully declined', 'success');
          }

          this.getRequestedCandidates();
        })
        .catch((error) => {
          window.flash(error.response.data.message, 'error');
        });
    },
  },
  created() {
    this.getRequestedCandidates(1);
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/sass/views/agency/requested-candidates.scss";
</style>
