<template>
  <div>
    <b-modal
      modal-class="candidate-profile-modal"
      size="lg"
      :visible="modalVisibility"
      title="Candidate Profile"
      centered
      @change="changeModalVisibility"
    >
      <template slot="modal-header-close">
        <cross-svg />
      </template>

      <div class="candidate-profile">
        <div v-if="profileType !== 'employer-short'" class="candidate-profile-sidebar">
          <div class="candidate-profile__image">
            <img
              class="fit-image"
              :src="profile.photo_url"
              :alt="profile.first_name + ' ' + profile.last_name">
          </div>

          <div>
            <a
              v-if="profile.cv_url"
              :href="profile.cv_url"
              target="_blank"
              class="icon-label icon-label--small candidate-profile__link"
            >
              <file-text-svg />
              <span>View Resume</span>
            </a>

            <button
              v-if="profile.video_interview_link"
              class="icon-label icon-label--small candidate-profile__link"
              @click="getVideoInterview"
            >
              <youtube-svg />
              <span>Video Interview</span>
            </button>

            <a
              v-if="profile.linkedin_link"
              :href="profile.linkedin_link"
              target="_blank"
              class="icon-label icon-label--small candidate-profile__link"
            >
              <linkedin-svg />
              <span>Linkedin Profile</span>
            </a>
          </div>
        </div>

        <div class="candidate-profile-body">
          <div
            v-if="profile.first_name && profile.last_name"
            class="candidate-profile__name"
          >
            {{ profile.first_name }} {{ profile.last_name }}
          </div>

          <div class="candidate-profile-row">
            <div class="candidate-profile-group">
              <div class="candidate-profile__label">Candidate ID</div>
              <div class="candidate-profile__text">{{ profile.id }}</div>
            </div>

            <div class="candidate-profile-group">
              <div class="candidate-profile__label">Job Title</div>
              <div
                v-if="profile.job_titles && profile.job_titles.length"
                class="candidate-profile__text"
              >
                <div
                  v-for="(jobTitle, index) in profile.job_titles"
                  :key="index"
                >
                  {{ jobTitle.name }}
                </div>
              </div>
            </div>
          </div>

          <div v-if="profile.sell_text" class="candidate-profile-group">
            <div class="candidate-profile__label">Summary</div>
            <div class="candidate-profile__text">{{ profile.sell_text }}</div>
          </div>

          <div v-if="profile.summary" class="candidate-profile-group">
            <div class="candidate-profile__label">What Candidate is looking for</div>
            <div class="candidate-profile__text">{{ profile.summary }}</div>
          </div>

          <div
            v-if="profile.industries && profile.industries.length"
            class="candidate-profile-group"
          >
            <div class="candidate-profile__label">Industry</div>
            <div class="candidate-profile__text">
              {{ arrayFieldToString(profile.industries, 'name') }}
            </div>
          </div>

          <div
            v-if="profile.years_of_experience && profile.years_of_experience.interval"
            class="candidate-profile-group"
          >
            <div class="candidate-profile__label">Years of experience</div>
            <div class="candidate-profile__text">
              {{ profile.years_of_experience.interval }}
            </div>
          </div>

          <div
            v-if="profile.undergraduate_degrees && profile.undergraduate_degrees.length"
            class="candidate-profile-group"
          >
            <div class="candidate-profile__label">Education</div>
            <div class="candidate-profile__text">
              {{ arrayFieldToString(profile.undergraduate_degrees, 'name') }}
            </div>
          </div>

          <div
            v-if="profile.target_salaries && profile.target_salaries.length"
            class="candidate-profile-group">
            <div class="candidate-profile__label">Target Salary</div>
            <div class="candidate-profile__text">
              {{ arrayFieldToString(profile.target_salaries, 'interval') }}
            </div>
          </div>

          <div
            v-if="profile.software && profile.software.length"
            class="candidate-profile-group"
          >
            <div class="candidate-profile__label">Software</div>
            <div class="candidate-profile__text">
              {{ arrayFieldToString(profile.software, 'name') }}
            </div>
          </div>

          <div
            v-if="profile.additional_experience && profile.additional_experience.length"
            class="candidate-profile-group"
          >
            <div class="candidate-profile__label">Additional experience</div>
            <div class="candidate-profile__text">
              {{ arrayFieldToString(profile.additional_experience, 'name') }}
            </div>
          </div>

          <div
            v-if="profile.languages && profile.languages.length"
            class="candidate-profile-group"
          >
            <div class="candidate-profile__label">Languages</div>
            <div class="candidate-profile__text">
              {{ arrayFieldToString(profile.languages, 'name') }}
            </div>
          </div>

          <div class="candidate-profile-group">
            <div class="candidate-profile__label">Location</div>
            <div class="candidate-profile__text">
              {{ profile.zip_code ? profile.zip_code : '' }}
              {{ profile.city ? profile.city : '' }},
              {{ profile.state ? profile.state : '' }}
            </div>
          </div>
        </div>
      </div>

      <template slot="modal-footer">
        <button
          class="action-label action-label--dark"
          @click="changeModalVisibility(false)"
        >
          Cancel
        </button>

        <template
          v-if="profileType === 'employer-short'
          && (!profile.request_info || profile.request_info.status === 'saved')"
        >
          <button
            class="button"
            @click="createRequest"
          >
            Request resume
          </button>
        </template>

        <button
          v-else-if="profileType === 'employer-full'"
          class="button"
          @click="contactAgency"
        >
          Contact Agency
        </button>

        <router-link
          v-else-if="profileType === 'agency-full'"
          :to="{ name: 'EditCandidate', params: { id: profile.id } }"
          class="button"
        >
          Edit
        </router-link>
      </template>
    </b-modal>

    <b-modal
      id="video-modal"
      ref="video-modal"
      modal-class="candidate-video-modal"
      size="xl"
      title="Video Interview"
      centered
    >
      <template slot="modal-header-close">
        <cross-svg />
      </template>

      <div
        v-if="profile.video_interview_link"
        v-html="embedVideoInterview"
        class="video-wrapper"
      ></div>

      <template slot="modal-footer">
        <div></div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { BModal } from 'bootstrap-vue';
import CrossSvg from '@/components/svg/CrossSvg.vue';
import FileTextSvg from '@/components/svg/FileTextSvg.vue';
import YoutubeSvg from '@/components/svg/YoutubeSvg.vue';
import LinkedinSvg from '@/components/svg/LinkedinSvg.vue';

export default {
  name: 'CandidateProfileModal',
  components: {
    BModal,
    CrossSvg,
    FileTextSvg,
    YoutubeSvg,
    LinkedinSvg,
  },
  props: {
    profileType: {
      type: String,
      required: true,
    },
    profile: {
      type: Object,
      required: true,
    },
    modalVisibility: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      embedVideoInterview: null,
    };
  },
  methods: {
    changeModalVisibility(visibility) {
      this.$emit('onVisibilityChange', visibility);
    },
    arrayFieldToString(array, field) {
      if (!array || !array.length) return '';

      const newArray = array.map((item) => item[field]);

      return newArray.join(', ');
    },
    getVideoInterview() {
      const videoLink = this.profile.video_interview_link;
      let embedVideo = '<p>Video not found!</p>';

      if (videoLink.match(/vimeo\.com/ig)) {
        this.$store.dispatch('video/getVimeoVideoInfo', videoLink)
          .then((response) => {
            embedVideo = response.data.html;
          })
          .finally(() => {
            this.embedVideoInterview = embedVideo;
            this.$refs['video-modal'].show();
          });
      } else if (videoLink.match(/youtu\.be|www\.youtube\.com/ig)) {
        this.$store.dispatch('video/getYoutubeVideoInfo', videoLink)
          .then((response) => {
            embedVideo = response.data.html;
          })
          .finally(() => {
            this.embedVideoInterview = embedVideo;
            this.$refs['video-modal'].show();
          });
      }
    },
    createRequest() {
      this.$emit('onCreateRequest');

      this.changeModalVisibility(false);
    },
    contactAgency() {
      this.$emit('onContactAgency');

      this.changeModalVisibility(false);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/sass/views/candidate/candidate-profile-modal";
</style>
