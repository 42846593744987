<template>
  <div  class="candidate">
    <div class="candidate-header">
      <div class="candidate-overview">
        <div class="candidate-image">
          <img
            class="fit-image"
            :src="candidate.photo_url"
            :alt="candidate.full_name"
          >
        </div>

        <div class="candidate-name">
          <div>{{ candidate.full_name }}</div>
          <div class="candidate-update-time d-sm-none">
            Profile last update: {{ candidate.last_update }}
          </div>
        </div>

        <div class="candidate-info">
          <div class="candidate-info__item">
            <span>Candidate ID:</span>
            <span>
              <b>{{ candidate.id }}</b>
            </span>
          </div>
          <div class="candidate-info__item">
            <span>Job Title(s):</span>
            <span>
              <b>{{ candidate.job_titles.join(', ') }}</b>
            </span>
          </div>
        </div>
      </div>

      <div class="candidate-actions">
        <div class="candidate-update-time d-none d-sm-block">
          Profile last update: {{ candidate.last_update }}
        </div>

        <div class="d-flex">
          <button
            class="icon-label icon-label--small"
            @click="getCandidateProfile(candidate)"
          >
            <eye-svg />
            <span>Profile</span>
          </button>

          <router-link
            :to="{ name: 'EditCandidate', params: { id: candidate.id } }"
            class="icon-label icon-label--small"
          >
            <edit-svg />
            <span>Edit</span>
          </router-link>
        </div>
      </div>
    </div>

    <div class="candidate-body">
      <div
        v-if="candidate.pending_requests && candidate.pending_requests.length"
        class="pending-requests"
      >
        <div
          v-for="(request, index) in candidate.pending_requests"
          :key="index"
          class="pending-request"
        >
          <button
            class="request-title"
            @click="getEmployerProfile(request.employer.id)"
          >
            <span>{{ request.employer.company_name }}</span>
            <info-svg width="12px" height="12px" color="#3D8EDC" />
          </button>

          <div class="request-time">
            Request sent {{ request.timestamp_string }}
          </div>

          <div class="request-actions">
            <button
              class="icon-label icon-label--small icon-label--green"
              @click="showUpdateRequestModal(candidate, request, 'approved')"
            >
              <check-svg />
              <span>Accept Request</span>
            </button>

            <button
              class="icon-label icon-label--small icon-label--red"
              @click="showUpdateRequestModal(candidate, request, 'declined')"
            >
              <stop-svg />
              <span>Decline Request</span>
            </button>
          </div>

          <div v-if="request.message" class="request-text">{{ request.message }}</div>
        </div>
      </div>

      <div
        v-if="candidate.past_requests && candidate.past_requests.length"
        class="past-requests"
      >
        <div class="past-requests-header">
          <div class="past-requests__title">
            Past requests ({{ candidate.past_requests.length }})
          </div>
          <div class="past-requests__line"></div>
          <button
            class="past-requests__action"
            @click="showPastRequests = !showPastRequests"
          >
            <template v-if="showPastRequests">Hide Past requests</template>
            <template v-else>Show Past requests</template>
          </button>
        </div>

        <template v-if="showPastRequests">
          <div
            v-for="(request, index) in candidate.past_requests"
            :key="index"
            class="past-request"
          >
            <div class="request-title">
              <span>{{ request.employer.company_name }}</span>
              <button @click="getEmployerProfile(request.employer.id)">
                <info-svg width="12px" height="12px" color="#3D8EDC" />
              </button>
            </div>

            <div class="request-time">
              Request sent {{ request.timestamp_string }}
            </div>

            <div class="request-status" :class="'request-status--' + request.status">
              <template v-if="request.status === 'approved'">
                <div class="request-status__label">Sent</div>
              </template>

              <template v-if="request.status === 'declined'">
                <div class="request-status__label">Declined</div>
              </template>
            </div>

            <div v-if="request.message" class="request-text">{{ request.message }}</div>
          </div>
        </template>
      </div>
    </div>

    <candidate-profile
      v-if="candidateProfile"
      profileType="agency-full"
      :profile="candidateProfile"
      :modalVisibility="candidateProfileModal"
      @onVisibilityChange="changeCandidateProfileModalVisibility"
    ></candidate-profile>

    <employer-profile-modal
      v-if="employerProfile"
      :profile="employerProfile"
      :modalVisibility="employerProfileModal"
      @onVisibilityChange="changeEmployerProfileModalVisibility"
    />

    <b-modal
      v-if="updatedRequest"
      id="update-request"
      modal-class="update-request-modal"
      size="lg"
      v-model="updateRequestModal"
      centered
      @hidden="clearUpdateRequestForm"
    >
      <template slot="modal-header-close">
        <cross-svg />
      </template>

      <template slot="modal-title">
        <template v-if="updateRequestModalType === 'approved'">
          Accept the request
        </template>
        <template v-if="updateRequestModalType === 'declined'">
          Decline the request
        </template>
      </template>

      <template v-if="updateRequestModalType === 'approved'">
        Are you sure you want to accept this request?
      </template>

      <template v-if="updateRequestModalType === 'declined'">
        <b-form-textarea
          class="update-request-message"
          v-model="requestForm.message"
          placeholder="Optional message"
        ></b-form-textarea>
      </template>

      <template slot="modal-footer">
        <button
          class="action-label action-label--dark"
          @click="closeUpdateRequestModal"
        >
          Cancel
        </button>

        <button
          class="button"
          @click="updateRequest"
        >
          <template v-if="updateRequestModalType === 'approved'">
            Accept
          </template>
          <template v-if="updateRequestModalType === 'declined'">
            Decline
          </template>
        </button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { BModal, BFormTextarea } from 'bootstrap-vue';
import CandidateProfile from '@/components/candidate/CandidateProfileModal.vue';
import EmployerProfileModal from '@/components/employer/EmployerProfileModal.vue';
import EyeSvg from '@/components/svg/EyeSvg.vue';
import EditSvg from '@/components/svg/EditSvg.vue';
import InfoSvg from '@/components/svg/InfoSvg.vue';
import CheckSvg from '@/components/svg/CheckSvg.vue';
import StopSvg from '@/components/svg/StopSvg.vue';
import CrossSvg from '@/components/svg/CrossSvg.vue';

export default {
  name: 'RequestedCandidate',
  props: {
    candidate: {
      type: Object,
      required: true,
    },
  },
  components: {
    BModal,
    BFormTextarea,
    CandidateProfile,
    EmployerProfileModal,
    EyeSvg,
    EditSvg,
    InfoSvg,
    CheckSvg,
    StopSvg,
    CrossSvg,
  },
  data() {
    return {
      candidateProfile: null,
      candidateProfileModal: false,
      employerProfile: null,
      employerProfileModal: false,
      showPastRequests: true,
      requestForm: {
        message: null,
      },
      updateRequestModal: false,
      updateRequestModalType: null,
      updatedRequest: null,
    };
  },
  methods: {
    changeCandidateProfileModalVisibility(visibility) {
      this.candidateProfileModal = visibility;
    },
    changeEmployerProfileModalVisibility(visibility) {
      this.employerProfileModal = visibility;
    },
    getCandidateProfile(candidate) {
      this.$store.dispatch('candidate/getCandidate', {
        candidateId: candidate.id,
        data: {
          params: {
            level: 2,
          },
        },
      })
        .then((response) => {
          this.candidateProfile = response.data;

          this.$nextTick(() => this.changeCandidateProfileModalVisibility(true));
        })
        .catch((error) => {
          window.flash(error.response.data.message, 'error');
        });
    },
    getEmployerProfile(employerId) {
      this.$store.dispatch('employer/getEmployerProfile', employerId)
        .then((response) => {
          this.employerProfile = response.data;

          this.changeEmployerProfileModalVisibility(true);
        })
        .catch((error) => {
          window.flash(error.response.data.message, 'error');
        });
    },
    showUpdateRequestModal(candidate, request, status) {
      this.updatedRequest = request;
      this.updatedRequest.candidate = candidate;
      this.updatedRequest.newStatus = status;

      this.updateRequestModalType = status;
      this.updateRequestModal = true;
    },
    updateRequest() {
      this.$emit('onUpdateRequest', {
        requestId: this.updatedRequest.id,
        status: this.updatedRequest.newStatus,
        message: this.requestForm.message,
      });

      this.closeUpdateRequestModal();
    },
    clearUpdateRequestForm() {
      this.requestForm.message = null;
    },
    closeUpdateRequestModal() {
      this.updateRequestModal = false;
      this.updatedRequest = null;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/sass/views/agency/requested-candidate.scss";
</style>
