<template>
  <!-- eslint-disable -->
  <svg width="16px" height="16px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" fill-opacity="0.7">
      <g class="fill" transform="translate(-160.000000, -4000.000000)" fill="#000000">
        <g transform="translate(0.000000, 3920.000000)">
          <g transform="translate(80.000000, 80.000000)">
            <g transform="translate(80.000000, 0.000000)">
              <path d="M16.14,0.999999958 C16.4052165,0.999999958 16.6595704,1.1053568 16.8471068,1.29289318 L22.7071067,7.15289321 C22.8946431,7.34042958 23,7.5947835 23,7.85999998 L23,16.14 C23,16.4052165 22.8946431,16.6595704 22.7071067,16.8471068 L16.8471068,22.7071067 C16.6595704,22.8946431 16.4052165,23 16.14,23 L7.85999998,23 C7.5947835,23 7.34042958,22.8946431 7.15289321,22.7071067 L1.29289318,16.8471068 C1.1053568,16.6595704 0.999999958,16.4052165 0.999999958,16.14 L0.999999958,7.85999998 C0.999999958,7.59478349 1.1053568,7.34042958 1.29289318,7.1528932 L7.1528932,1.29289318 C7.34042958,1.1053568 7.59478349,0.999999958 7.85999998,0.999999958 L16.14,0.999999958 Z M15.7257865,2.99999996 L8.27421355,2.99999996 L2.99999996,8.27421355 L2.99999996,15.7257865 L8.27421354,21 L15.7257865,21 L21,15.7257865 L21,8.27421354 L15.7257865,2.99999996 Z M11.2928932,15.2928932 C11.6834175,14.9023689 12.3165825,14.9023689 12.7071068,15.2928932 C13.0976311,15.6834175 13.0976311,16.3165825 12.7071068,16.7071068 C12.3165825,17.0976311 11.6834175,17.0976311 11.2928932,16.7071068 C10.9023689,16.3165825 10.9023689,15.6834175 11.2928932,15.2928932 Z M12,6.99999998 C12.5522847,6.99999998 13,7.44771523 13,7.99999998 L13,7.99999998 L13,12 C13,12.5522847 12.5522847,13 12,13 C11.4477152,13 11,12.5522847 11,12 L11,12 L11,7.99999998 C11,7.44771523 11.4477152,6.99999998 12,6.99999998 Z"></path>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
  <!-- eslint-enable -->
</template>

<script>
export default {
  name: 'AlertOctagonSvg',
};
</script>
