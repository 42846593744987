<template>
  <!-- eslint-disable -->
  <svg width="16px" height="16px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g class="fill" transform="translate(-280.000000, -4080.000000)" fill="#000000">
        <g transform="translate(0.000000, 3920.000000)">
          <g transform="translate(80.000000, 80.000000)">
            <g transform="translate(200.000000, 80.000000)">
              <path d="M16,7 C19.8659932,7 23,10.1340068 23,14 L23,14 L23,21 C23,21.5522847 22.5522847,22 22,22 L22,22 L18,22 C17.4477153,22 17,21.5522847 17,21 L17,21 L17,14 C17,13.4477153 16.5522847,13 16,13 C15.4477153,13 15,13.4477153 15,14 L15,14 L15,21 C15,21.5522847 14.5522847,22 14,22 L14,22 L10,22 C9.44771525,22 9,21.5522847 9,21 L9,21 L9,14 C9,10.1340068 12.1340068,7 16,7 Z M6,8 C6.55228475,8 7,8.44771525 7,9 L7,9 L7,21 C7,21.5522847 6.55228475,22 6,22 L6,22 L2,22 C1.44771525,22 1,21.5522847 1,21 L1,21 L1,9 C1,8.44771525 1.44771525,8 2,8 L2,8 Z M16,9 C13.2385763,9 11,11.2385763 11,14 L11,14 L11,20 L13,20 L13,14 C13,12.4023191 14.24892,11.0963391 15.8237272,11.0050927 L15.8237272,11.0050927 L16,11 C17.6568542,11 19,12.3431458 19,14 L19,14 L19,20 L21,20 L21,14 C21,11.3112453 18.8776933,9.11818189 16.2168896,9.00461951 L16.2168896,9.00461951 Z M5,10 L3,10 L3,20 L5,20 L5,10 Z M4,1 C5.65685425,1 7,2.34314575 7,4 C7,5.65685425 5.65685425,7 4,7 C2.34314575,7 1,5.65685425 1,4 C1,2.34314575 2.34314575,1 4,1 Z M4,3 C3.44771525,3 3,3.44771525 3,4 C3,4.55228475 3.44771525,5 4,5 C4.55228475,5 5,4.55228475 5,4 C5,3.44771525 4.55228475,3 4,3 Z" id="icon"></path>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
  <!-- eslint-enable -->
</template>

<script>
export default {
  name: 'LinkedinSvg',
};
</script>
