<template>
  <div class="custom-container">
    <candidate-form
      form-type="update"
      :candidate-id="candidateId"
      @onUpdate="updateCandidate"
      @onDelete="openConfirmDeleteModal"
    />

    <b-modal
      size="md"
      v-model="confirmDeleteModal"
      title="Delete Candidate"
      centered
      no-stacking
    >
      <template slot="modal-header-close">
        <cross-svg />
      </template>

      <p>
        Are you sure you want to delete this candidate?
      </p>

      <template slot="modal-footer">
        <button
          class="action-label action-label--dark"
          @click="closeConfirmDeleteModal"
        >
          Cancel
        </button>
        <button
          @click="deleteCandidate"
          class="button"
        >
          Delete
        </button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { BModal } from 'bootstrap-vue';
import CandidateForm from '@/components/candidate/CandidateForm.vue';
import CrossSvg from '@/components/svg/CrossSvg.vue';

export default {
  name: 'EditCandidate',
  components: {
    BModal,
    CandidateForm,
    CrossSvg,
  },
  data() {
    return {
      confirmDeleteModal: false,
    };
  },
  computed: {
    candidateId() {
      return parseInt(this.$route.params.id, 10);
    },
  },
  methods: {
    openConfirmDeleteModal() {
      this.confirmDeleteModal = true;
    },
    closeConfirmDeleteModal() {
      this.confirmDeleteModal = false;
    },
    updateCandidate(candidateData) {
      const formData = new FormData();
      const data = { ...candidateData };

      delete data.cv;
      delete data.photo;

      formData.append('data', JSON.stringify(data));

      if (candidateData.cv && candidateData.cv.file) {
        formData.append('cv', candidateData.cv.file);
      }

      if (candidateData.photo && candidateData.photo.file) {
        formData.append('photo', candidateData.photo.file);
      }

      this.$store.dispatch('candidate/updateCandidate', {
        candidateId: this.candidateId,
        data: formData,
      })
        .then(() => {
          window.flash('Candidate successfully updated!', 'success');
          this.$router.push({ name: 'MyCandidates' });
        })
        .catch((error) => {
          let errorText = '';

          if (error.response) {
            if (error.response.data.message) {
              errorText = error.response.data.message;
            } else {
              errorText = error.response.data;
            }
          }

          window.flash(errorText, 'error');
        });
    },
    deleteCandidate() {
      this.$store.dispatch('candidate/deleteCandidate', this.candidateId)
        .then(() => {
          window.flash('Candidate successfully deleted!', 'success');
          this.$router.push({ name: 'MyCandidates' });
        })
        .catch((error) => {
          window.flash(error.response.data.message, 'error');
        })
        .finally(() => {
          this.closeConfirmDeleteModal();
        });
    },
  },
};
</script>
