<template>
  <div class="custom-container">
    <div class="filter">
      <div class="filter__item">
        <multiselect
          id="candidateName"
          class="custom-multiselect"
          placeholder="- Select Candidate Name -"
          v-model="filter.candidate_id"
          :options="candidateNameOptions.map(option => option.id)"
          :custom-label="opt => candidateNameOptions.find(x => x.id === opt).full_name"
          @input="getMyVaultCandidates()"
        ></multiselect>
      </div>

      <div class="filter__item">
        <multiselect
          id="jobTitle"
          class="custom-multiselect"
          placeholder="- Select Job Title -"
          v-model="filter.job_title_id"
          :options="jobTitleOptions.map(option => option.id)"
          :custom-label="opt => jobTitleOptions.find(x => x.id === opt).name"
          @input="getMyVaultCandidates()"
        ></multiselect>
      </div>
    </div>

    <candidates-preview-list page="my-vault" :candidates="candidates" />
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect';
import CandidatesPreviewList from '@/components/candidate/CandidatesPreviewList.vue';

import { mapGetters } from 'vuex';

export default {
  name: 'MyVault',
  components: {
    Multiselect,
    CandidatesPreviewList,
  },
  data() {
    return {
      candidates: [],
      filter: {
        job_title_id: null,
        candidate_id: null,
      },
      candidateNameOptions: [],
      jobTitleOptions: [],
    };
  },
  computed: {
    ...mapGetters({
      authUserId: 'auth/getAuthUserId',
    }),
  },
  methods: {
    getMyVaultCandidates(filters = 0) {
      this.$store.dispatch('employer/getVaultCandidates', {
        employerId: parseInt(this.authUserId, 10),
        data: {
          params: {
            job_title_id: this.filter.job_title_id,
            candidate_id: this.filter.candidate_id,
            need_filters: filters,
          },
        },
      })
        .then((response) => {
          this.candidates = response.data.candidates;

          if (response.data.filters) {
            this.candidateNameOptions = response.data.filters.candidates;
            this.jobTitleOptions = response.data.filters.job_titles;
          }
        })
        .catch((error) => {
          window.flash(error.response.data.message, 'error');
        });
    },
  },
  created() {
    if (this.authUserId) {
      this.getMyVaultCandidates(1);
    }

    this.authUserIdWatcher = this.$store.watch(
      (state, getters) => getters['auth/getAuthUserId'],
      (newValue) => {
        if (newValue) {
          this.getMyVaultCandidates(1);
        }
      },
    );
  },
  beforeDestroy() {
    this.authUserIdWatcher();
  },
};
</script>

<style lang="scss" scoped>
@import "src/assets/sass/views/employer/my-vault.scss";
</style>
