import axios from 'axios';

const state = {
  modalStatus: false,
};

const getters = {};

const mutations = {
  changeModalStatus(e, status) {
    e.modalStatus = status;
  },
};

const actions = {
  sendCandidateData(context, data) {
    return new Promise((resolve, reject) => {
      axios.post('/api/v1/candidates/connect_now', data, {
        headers: { 'content-type': 'multipart/form-data' },
      })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
