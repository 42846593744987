<template>
  <div>
    <profile-change-password />
  </div>
</template>

<script>
import ProfileChangePassword from '@/components/profile/ProfileChangePassword.vue';

export default {
  name: 'EmployerChangePassword',
  components: {
    ProfileChangePassword,
  },
};
</script>
