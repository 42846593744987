<template>
  <div class="info" :class="type ? 'info--' + type : ''">
    <div class="info__icon">
      <component :is="getIcon"></component>
    </div>
    <div class="info__text">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import InfoSvg from '@/components/svg/InfoSvg.vue';
import CheckCircleSvg from '@/components/svg/CheckCircleSvg.vue';
import AlertTriangleSvg from '@/components/svg/AlertTriangleSvg.vue';
import AlertOctagonSvg from '@/components/svg/AlertOctagonSvg.vue';

export default {
  name: 'InfoBlock',
  components: {
    InfoSvg,
    CheckCircleSvg,
    AlertTriangleSvg,
    AlertOctagonSvg,
  },
  props: {
    type: {
      type: String,
    },
  },
  computed: {
    getIcon() {
      let iconComponent;

      switch (this.type) {
        case 'primary':
          iconComponent = InfoSvg;
          break;

        case 'success':
          iconComponent = CheckCircleSvg;
          break;

        case 'warning':
          iconComponent = AlertTriangleSvg;
          break;

        case 'error':
          iconComponent = AlertOctagonSvg;
          break;

        default:
          iconComponent = InfoSvg;
          break;
      }

      return iconComponent;
    },
  },
};
</script>
