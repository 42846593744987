<template>
  <div class="auth-block">
    <div class="auth-block-body">
      <div class="auth-block__title">Confirm email</div>

      <info-block type="primary">
        We will send you confirmation link on this email.
      </info-block>

      <b-form class="auth-form" @submit.prevent="submitForm">
        <b-form-group
          id="emailGroup"
          label-for="email"
          invalid-feedback="This is a required field and must be email."
        >
          <template slot="label">
            Email <span class="text-danger">*</span>
          </template>
          <b-form-input
            id="email"
            type="email"
            v-model="$v.form.email.$model"
            :state="validateState('email')"
          ></b-form-input>
        </b-form-group>

        <div class="form-buttons">
          <button
            class="button"
            type="submit"
          >
            Send Link
          </button>
        </div>
      </b-form>
    </div>
  </div>
</template>

<script>
import { BForm, BFormGroup, BFormInput } from 'bootstrap-vue';
import { validationMixin } from 'vuelidate';
import { email, required } from 'vuelidate/lib/validators';
import InfoBlock from '@/components/utils/InfoBlock.vue';

export default {
  name: 'SendEmailConfirmationLink',
  components: {
    BForm,
    BFormGroup,
    BFormInput,
    InfoBlock,
  },
  props: {
    email: {
      type: String,
      default: null,
    },
  },
  mixins: [validationMixin],
  data() {
    return {
      form: {
        email: null,
      },
    };
  },
  validations: {
    form: {
      email: {
        required,
        email,
      },
    },
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    submitForm() {
      this.$v.form.$reset();
      this.$v.form.$touch();

      if (!this.$v.form.$anyError) {
        this.$store.dispatch('auth/sendConfirmEmailLink', this.form)
          .then((response) => {
            this.$v.form.$reset();
            this.form.email = null;
            window.flash(response.data.message, 'success');
          })
          .catch((error) => {
            window.flash(error.response.data.message, 'error');
          });
      }
    },
  },
  created() {
    this.form.email = this.email;
  },
};
</script>
