<template>
  <div class="auth-block auth-block--lg">
    <div class="auth-block-body">
      <div v-if="signUpTitle" class="auth-block__title">{{ signUpTitle }}</div>

      <component :is="signUpForm"></component>
    </div>

    <div class="auth-block-footer">
      <p>Already have an Account?</p>
      <router-link
        :to="{ name: 'SignIn', params: { type: $route.params.type } }"
        class="link-regular link-regular--bold"
      >
        Sign In
      </router-link>
    </div>
  </div>
</template>

<script>
import AgencySignUpForm from '@/components/auth/AgencySignUpForm.vue';
import EmployerSignUpForm from '@/components/auth/EmployerSignUpForm.vue';

export default {
  name: 'SignUp',
  components: {
    EmployerSignUpForm,
  },
  data() {
    return {
      signUpTitle: null,
      signUpForm: null,
    };
  },
  created() {
    switch (this.$route.params.type) {
      case 'agency':
        this.signUpTitle = 'Agency Sign Up';
        this.signUpForm = AgencySignUpForm;
        break;

      case 'employer':
        this.signUpTitle = 'Employer Sign Up';
        this.signUpForm = EmployerSignUpForm;
        break;

      default:
        break;
    }
  },
};
</script>
