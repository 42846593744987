<template>
  <div class="custom-container">
    <div v-if="candidates && candidates.length" class="candidates-wrapper">
      <candidate-card
        v-for="(candidate, index) in candidates"
        :key="index"
        :candidate="candidate"
        @showProfile="getCandidateProfile(candidate)"
      ></candidate-card>

      <candidate-profile
        v-if="candidateProfile"
        profileType="agency-full"
        :profile="candidateProfile"
        :modalVisibility="candidateProfileModal"
        @onVisibilityChange="changeProfileModalVisibility"
      ></candidate-profile>
    </div>

    <div v-else class="candidates-empty">
      <p>You don't have candidates yet.</p>
      <router-link
        :to="{ name: 'SubmitCandidate' }"
        class="action-label action-label--dark"
      >
        Add the first one!
      </router-link>
    </div>
  </div>
</template>

<script>
import CandidateCard from '@/components/candidate/CandidateCard.vue';
import CandidateProfile from '@/components/candidate/CandidateProfileModal.vue';

import { mapGetters } from 'vuex';

export default {
  name: 'MyCandidates',
  components: {
    CandidateCard,
    CandidateProfile,
  },
  data() {
    return {
      candidates: [],
      candidateProfileModal: false,
      candidateProfile: null,
    };
  },
  computed: {
    ...mapGetters({
      authUserId: 'auth/getAuthUserId',
    }),
  },
  methods: {
    getCandidates() {
      this.$store.dispatch('agency/getCandidates', this.authUserId)
        .then((response) => {
          this.candidates = response.data.candidates;
        })
        .catch((error) => {
          window.flash(error.response.data.message, 'error');
        });
    },
    getCandidateProfile(candidate) {
      this.$store.dispatch('candidate/getCandidate', {
        candidateId: candidate.id,
        data: {
          params: {
            level: 2,
          },
        },
      })
        .then((response) => {
          this.candidateProfile = response.data;

          this.$nextTick(() => this.changeProfileModalVisibility(true));
        })
        .catch((error) => {
          window.flash(error.response.data.message, 'error');
        });
    },
    changeProfileModalVisibility(visibility) {
      this.candidateProfileModal = visibility;
    },
  },
  created() {
    if (this.authUserId) {
      this.getCandidates();
    }

    this.authUserIdWatcher = this.$store.watch(
      (state, getters) => getters['auth/getAuthUserId'],
      (newValue) => {
        if (newValue) {
          this.getCandidates();
        }
      },
    );
  },
  beforeDestroy() {
    this.authUserIdWatcher();
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/sass/views/agency/my-candidates.scss";
</style>
