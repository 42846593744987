<template>
  <!-- eslint-disable -->
  <svg :width="width" :height="height" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g class="fill" transform="translate(-320.000000, -4000.000000)" :fill="color">
        <g transform="translate(0.000000, 3920.000000)">
          <g transform="translate(80.000000, 80.000000)">
            <g transform="translate(240.000000, 0.000000)">
              <path d="M12,0.999999958 C18.0751322,0.999999958 23,5.9248677 23,12 C23,18.0751323 18.0751323,23 12,23 C5.9248677,23 0.999999958,18.0751322 0.999999958,12 C0.999999958,5.92486773 5.92486773,0.999999958 12,0.999999958 Z M12,2.99999996 C7.02943723,2.99999996 2.99999996,7.02943723 2.99999996,12 C2.99999996,16.9705627 7.0294372,21 12,21 C16.9705628,21 21,16.9705628 21,12 C21,7.0294372 16.9705627,2.99999996 12,2.99999996 Z M12,4.99999997 C12.5522847,4.99999997 13,5.44771523 13,5.99999997 L13,5.99999997 L13,11.381966 L16.4472136,13.1055728 C16.9411921,13.352562 17.1414164,13.9532351 16.8944272,14.4472136 C16.6474379,14.9411921 16.0467649,15.1414164 15.5527864,14.8944272 L15.5527864,14.8944272 L11.5527864,12.8944272 C11.2140024,12.7250352 11,12.378772 11,12 L11,12 L11,5.99999997 C11,5.44771523 11.4477153,4.99999997 12,4.99999997 Z"></path>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
  <!-- eslint-enable -->
</template>

<script>
export default {
  name: 'ClockSvg',
  props: {
    width: {
      type: String,
      default: '16px',
    },
    height: {
      type: String,
      default: '16px',
    },
    color: {
      type: String,
      default: '#000000',
    },
  },
};
</script>
