<template>
  <b-form class="auth-form" @submit.prevent="submitForm">
    <div class="form-row">
      <b-form-group
        id="companyNameGroup"
        label-for="companyName"
        :state="validateState('company_name')"
        invalid-feedback="This is a required field and must be at least 2 characters."
      >
        <template slot="label">
          Company Name <span class="text-danger">*</span>
        </template>
        <b-form-input
          id="companyName"
          type="text"
          v-model="$v.form.company_name.$model"
          :state="validateState('company_name')"
        ></b-form-input>
      </b-form-group>

      <b-form-group
        id="companyWebsiteGroup"
        label-for="companyWebsite"
        :state="validateState('company_website')"
        invalid-feedback="This is a required field and must be at least 2 characters."
      >
        <template slot="label">
          Company Website <span class="text-danger">*</span>
        </template>
        <b-form-input
          id="companyWebsite"
          type="text"
          v-model="$v.form.company_website.$model"
          :state="validateState('company_website')"
        ></b-form-input>
      </b-form-group>
    </div>

    <div class="form-row">
      <b-form-group
        id="employeesCountGroup"
        label-for="employeesCount"
        :state="validateState('number_of_company_employees')"
        invalid-feedback="This is a required field and must be greater than 0."
      >
        <template slot="label">
          # of employees in the company <span class="text-danger">*</span>
        </template>
        <b-form-input
          id="employeesCount"
          type="number"
          min="1"
          v-model.number="$v.form.number_of_company_employees.$model"
          :state="validateState('number_of_company_employees')"
        ></b-form-input>
      </b-form-group>

      <b-form-group
        :state="validateState('company_type')"
        label-for="companyType"
        invalid-feedback="This is a required field"
      >
        <template slot="label">
          Company type <span class="text-danger">*</span>
        </template>

        <b-form-radio-group
          id="companyType"
          v-model="$v.form.company_type.$model"
          :state="validateState('company_type')"
        >
          <b-form-radio
            name="company-type"
            value="Public"
          >
            Public
          </b-form-radio>
          <b-form-radio
            name="company-type"
            value="Private"
          >
            Private
          </b-form-radio>
        </b-form-radio-group>
      </b-form-group>
    </div>

    <div class="form-row">
      <b-form-group
        id="contactNameGroup"
        label-for="contactName"
        :state="validateState('contact_name')"
        invalid-feedback="This is a required field and must be at least 2 characters."
      >
        <template slot="label">
          Contact Name <span class="text-danger">*</span>
        </template>
        <b-form-input
          id="contactName"
          type="text"
          placeholder="First and last name"
          v-model="$v.form.contact_name.$model"
          :state="validateState('contact_name')"
        ></b-form-input>
      </b-form-group>

      <b-form-group
        id="contactTitleGroup"
        label-for="contactTitle"
        :state="validateState('contact_title')"
        invalid-feedback="This is a required field and must be at least 2 characters."
      >
        <template slot="label">
          Contact Title <span class="text-danger">*</span>
        </template>
        <b-form-input
          id="contactTitle"
          type="text"
          v-model="$v.form.contact_title.$model"
          :state="validateState('contact_title')"
        ></b-form-input>
      </b-form-group>
    </div>

    <b-form-group
      id="briefGroup"
      label-for="brief"
      invalid-feedback="This is a required field and must be at least 2 characters."
    >
      <template slot="label">
        Brief description of the company and why someone would want to work there...
        <span class="text-danger">*</span>
      </template>
      <b-form-textarea
        id="brief"
        rows="5"
        max-rows="10"
        v-model="$v.form.brief.$model"
        :state="validateState('brief')"
      ></b-form-textarea>
    </b-form-group>

    <b-form-group
      id="emailGroup"
      label-for="email"
      invalid-feedback="This is a required field and must be email."
    >
      <template slot="label">
        Contact Email <span class="text-danger">*</span>
      </template>
      <b-form-input
        id="email"
        type="email"
        v-model="$v.form.email.$model"
        :state="validateState('email')"
      ></b-form-input>
    </b-form-group>

    <div class="form-row">
      <b-form-group
        id="passwordGroup"
        label-for="password"
        invalid-feedback="This is a required field and must be at least 6 characters."
      >
        <template slot="label">
          Password <span class="text-danger">*</span>
        </template>
        <b-form-input
          id="password"
          type="password"
          v-model="$v.form.password1.$model"
          :state="validateState('password1')"
        ></b-form-input>
      </b-form-group>

      <b-form-group
        id="passwordConfirmGroup"
        label-for="passwordConfirm"
        invalid-feedback="This is a required field."
      >
        <template slot="label">
          Confirm password <span class="text-danger">*</span>
        </template>
        <b-form-input
          id="passwordConfirm"
          type="password"
          v-model="$v.form.password2.$model"
          :state="validateState('password2')"
        ></b-form-input>
      </b-form-group>
    </div>

    <b-form-group id="termsGroup">
      <template slot="label">
        I have read and agree to the terms of <span class="text-danger">*</span>
      </template>

      <b-form-checkbox
        id="feeAgreement"
        v-model="$v.form.fee_agreement.$model"
        name="feeAgreement"
        :state="validateState('fee_agreement')"
      >
        <a href="https://the-vault-biz-prod.s3.eu-central-1.amazonaws.com/docs/fee_agreement.pdf" target="_blank" class="link-regular link-regular--bold">Fee Agreement</a>
      </b-form-checkbox>

      <b-form-checkbox
        id="releaseOfLiability"
        v-model="$v.form.release_of_liability.$model"
        name="releaseOfLiability"
        :state="validateState('release_of_liability')"
      >
        <a href="https://the-vault-biz-prod.s3.eu-central-1.amazonaws.com/docs/release_of_liability.pdf" target="_blank" class="link-regular link-regular--bold">Release of Liability</a>.
      </b-form-checkbox>

      <b-form-invalid-feedback
        :state="validateTerms()"
      >
        This is required fields.
      </b-form-invalid-feedback>
    </b-form-group>

    <div class="form-buttons">
      <button
        class="button"
        type="submit"
      >
        Sign Up
      </button>
    </div>
  </b-form>
</template>

<script>
import {
  BForm, BFormGroup, BFormInput, BFormTextarea, BFormRadioGroup, BFormRadio,
  BFormCheckbox, BFormInvalidFeedback,
} from 'bootstrap-vue';
import { validationMixin } from 'vuelidate';
import {
  email, minLength, required, sameAs, minValue,
} from 'vuelidate/lib/validators';

export default {
  name: 'EmployerSignUpForm',
  components: {
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BFormRadioGroup,
    BFormRadio,
    BFormCheckbox,
    BFormInvalidFeedback,
  },
  mixins: [validationMixin],
  data() {
    return {
      form: {
        company_name: null,
        company_website: null,
        number_of_company_employees: null,
        company_type: null,
        contact_name: null,
        contact_title: null,
        brief: null,
        email: null,
        password1: null,
        password2: null,
        fee_agreement: false,
        release_of_liability: false,
        user_role: 'employer',
      },
    };
  },
  validations: {
    form: {
      company_name: {
        required,
        minLength: minLength(2),
      },
      company_website: {
        required,
        minLength: minLength(2),
      },
      number_of_company_employees: {
        required,
        minValue: minValue(1),
      },
      company_type: {
        required,
      },
      contact_name: {
        required,
        minLength: minLength(2),
      },
      contact_title: {
        required,
        minLength: minLength(2),
      },
      brief: {
        required,
        minLength: minLength(2),
      },
      email: {
        required,
        email,
      },
      password1: {
        required,
        minLength: minLength(6),
      },
      password2: {
        required,
        minLength: minLength(6),
        sameAs: sameAs('password1'),
      },
      fee_agreement: {
        checked: (value) => value === true,
      },
      release_of_liability: {
        checked: (value) => value === true,
      },
    },
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    validateTerms() {
      const fields = ['fee_agreement', 'release_of_liability'];

      let state = null;

      fields.forEach((field) => {
        const fieldState = this.validateState(field);

        if (fieldState === false) {
          state = fieldState;
        }
      });

      return state;
    },
    submitForm() {
      this.$v.form.$reset();
      this.$v.form.$touch();

      if (!this.$v.form.$anyError) {
        const data = { ...this.form };
        delete data.fee_agreement;
        delete data.release_of_liability;

        this.$store.dispatch('auth/signUp', data)
          .then(() => {
            window.flash('Employer account created successfully', 'success');

            this.$router.push({
              name: 'SignIn',
              params: {
                type: this.$route.params.type,
                confirmEmailMessage: true,
              },
            });
          })
          .catch((error) => {
            window.flash(error.response.data.message, 'error');
          });
      }
    },
  },
};
</script>
