export default {
  data() {
    return {
      timeout: null,
    };
  },
  methods: {
    debounce(func) {
      clearTimeout(this.timeout);

      this.timeout = setTimeout(() => {
        func.call();
      }, 200);
    },
  },
  destroyed() {
    clearTimeout(this.timeout);
  },
};
