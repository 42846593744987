import EmployersImage from '@/assets/img/landing/employers.jpg';
import AgenciesImage from '@/assets/img/landing/agencies.jpg';
import CandidatesImage from '@/assets/img/landing/candidates.jpg';

export const landingTitle = 'The Vault is a recruiter populated <span>E-Commerce</span> marketplace '
  + 'where employers can access and search top vetted talent for hire.';

export const landingText = 'Invite only! Staffing agencies invite their most qualified candidates to be '
  + 'confidentially displayed and accessible to employers looking to hire the best talent, quickly.';

export const landingCards = [
  {
    id: 'employers',
    title: 'Employers',
    image: EmployersImage,
    subtitle: 'Sign up to find your next hire here!  ',
    text: 'Our inventory is current and has been screened and approved by our AGENCY partners'
      + ' as their most qualified and accomplished talent.',
    actionButtonTitle: 'Sign up',
    linkType: 'employer',
    learnMoreButtonTitle: 'Learn More',
    modal: {
      size: 'xl',
      title: 'For Employer',
      howItWorksList: [
        'Staffing agencies qualify and import their best talent.',
        'TheVault will email the candidates to have them verify their approval for participation, representation and accuracy of information to finalize submission.',
        'Employers who are members of TheVault are free to search TheVault inventory from all participating staffing agencies.',
        'TheVault will email agencies when an employer expresses interest in one of their candidates.',
        'Agency will approve or decline the Employers request.',
        'Once agency approves the request, full access to candidate details will be available to employer. (including resume, picture, test results, references, video, work samples, social media links etc..)',
        'The rest of the transaction including questions, communication, scheduling of interviews, additional fee negotiation, hiring process, fee payment etc...will be done through the agency and employer direct.',
        'Employers rate the Agencies',
      ],
      blocks: [
        {
          title: 'What agencies expect from you:',
          list: [
            'Feedback on resumes and interviews within 24 hours (M-F)',
            'You will still pay the agency their standard 20% fee (see “fee agreement”)',
          ],
        },
        {
          title: 'What you can expect from the agency:',
          list: [
            'To respond to your request for a candidate within 24 hours (M-F)',
            'To supply the best quality candidates in their inventory',
            'To provide accurate information',
          ],
        },
      ],
      showDisclaimer: true,
      linkTitle: 'Sign up for free',
      linkType: 'employer',
    },
  },
  {
    id: 'agencies',
    title: 'Agencies',
    image: AgenciesImage,
    subtitle: 'Get your top talent hired here!',
    text: 'Sign up and invite your most qualified and accomplished candidates for hire. '
      + 'Their identity remains confidential and you still get your fee.',
    actionButtonTitle: 'Sign up',
    linkType: 'agency',
    learnMoreButtonTitle: 'Learn More',
    modal: {
      size: 'xl',
      title: 'For Agency',
      howItWorksList: [
        'Staffing agencies qualify and import their best talent.',
        'TheVault will email the candidates to have them verify their approval for participation, representation and accuracy of information to finalize submission.',
        'Employers who are members of TheVault are free to search TheVault inventory from all participating staffing agencies.',
        'TheVault will email agencies when an employer expresses interest in one of their candidates.',
        'Agency will approve or decline the Employers request.',
        'Once agency approves the request, full access to candidate details will be available to employer. (including resume, picture, test results, references, video, work samples, social media links etc..)',
        'The rest of the transaction including questions, communication, scheduling of interviews, additional fee negotiation, hiring process, fee payment etc...will be done through the agency and employer direct.',
        'Employers rate the Agencies',
      ],
      blocks: [
        {
          title: 'What you can expect from the client:',
          list: [
            'Feedback on resumes and interviews within 24 hours (M-F)',
            'They will still pay you your standard 20% fee (see “fee agreement”)',
          ],
        },
        {
          title: 'What clients expect from you:',
          list: [
            'To respond to their request for a candidate within 24 hours (M-F)',
            'To supply the best quality candidates in your inventory',
            'To provide accurate information',
          ],
        },
      ],
      showDisclaimer: true,
      linkTitle: 'Sign up for free',
      linkType: 'agency',
    },
  },
  {
    id: 'candidates',
    title: 'Candidates',
    image: CandidatesImage,
    subtitle: 'Connect with a Recruiter!',
    text: 'Connect with one of our "Agency" members now to see about having your profile'
      + ' featured and displayed in TheVault (CONFIDENTIALLY). This will make you available '
      + 'to all of the top employer members without them having any identifiable information on you.',
    actionButtonTitle: 'Connect Now',
    linkType: 'candidate',
    learnMoreButtonTitle: 'Learn More',
    modal: {
      size: 'lg',
      title: 'For Candidate',
      howItWorksList: [],
      text: '<p>Your participation in TheVault is free and confidential. Every candidate must be represented by a staffing agency. Your information will remain confidential until your agency representative approves your information on a client by client basis and will never be public.</p>'
        + '<p>This is not a binding situation. You are free to work with as many agencies outside TheVault as you would like.</p>'
        + '<p>There are many different criteria to receive an invitation from TheVault. To see if you qualify to be in TheVault, there are two ways to be considered.</p>'
        + '<p>You contact any of our Agency Members (list provided in the "contact now" section on the home page.) Or you can "connect now" (below) to have your information sent to all relevant agencies.</p>'
        + '<p>You can contact as many agencies as you would like but can only be represented by one in TheVault.</p>'
        + '<p>If an agency is going to represent you in TheVault, you will receive an invitation email from the agency to have your profile featured.</p>'
        + '<p>You must accept the terms sent to you in the email before you will be active in TheVault</p>'
        + '<p>If you are in TheVault, your identifying information such as name, school name, current employer, and phone number will be kept confidential until your agency approves the release of your information..</p>'
        + '<p>When an employer requests your full profile, your “agency representative” will have the authority to make your information visible to each employer independently.</p>',
      blocks: [],
      showDisclaimer: false,
      buttonTitle: 'Connect Now',
      linkType: 'candidate',
    },
  },
];
