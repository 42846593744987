<template>
  <div class="search-wrapper">
    <div class="search-filter">
      <button
        class="button search-filter-toggle d-lg-none"
        @click="toggleMobileFilter"
      >
        <span v-if="mobileFilter">Close filter</span>
        <span v-else>Open filter</span>

        <span
          v-if="filtersCount"
          class="custom-badge custom-badge--small custom-badge--inverted"
        >{{ filtersCount }}</span>
      </button>

      <div class="search-filter-wrapper" :class="{ 'mobile-open' : mobileFilter }">
        <b-form-group
          id="jobTitleGroup"
          label-for="jobTitle"
        >
          <template slot="label">
            Job Title
          </template>

          <multiselect
            id="jobTitle"
            class="custom-multiselect"
            placeholder="— Select —"
            v-model="filter.job_title_ids"
            :options="jobTitleOptions.map(option => option.id)"
            :custom-label="opt => jobTitleOptions.find(x => x.id === opt).name"
            :multiple="true"
            :closeOnSelect="false"
          >
            <template slot="tag" slot-scope="props">
              <div class="multiselect__tag">
                <span>
                  {{ jobTitleOptions.find(x => x.id === props.option).name }}
                </span>
                <div
                  class="multiselect__tag-remove"
                  @mousedown.prevent="props.remove(props.option)"
                >
                  <cross-svg />
                </div>
              </div>
            </template>
          </multiselect>
        </b-form-group>

        <b-form-group
          id="industryGroup"
          label-for="industry"
        >
          <template slot="label">
            Industry
          </template>

          <multiselect
            id="industry"
            class="custom-multiselect"
            placeholder="— Select —"
            v-model="filter.industry_ids"
            :options="industryOptions.map(option => option.id)"
            :custom-label="opt => industryOptions.find(x => x.id === opt).name"
            :multiple="true"
            :closeOnSelect="false"
          >
            <template slot="tag" slot-scope="props">
              <div class="multiselect__tag">
                <span>
                  {{ industryOptions.find(x => x.id === props.option).name }}
                </span>
                <div
                  class="multiselect__tag-remove"
                  @mousedown.prevent="props.remove(props.option)"
                >
                  <cross-svg />
                </div>
              </div>
            </template>
          </multiselect>
        </b-form-group>

        <b-form-group
          id="experienceGroup"
          label-for="experience"
        >
          <template slot="label">
            Years of experience
          </template>

          <multiselect
            id="experience"
            class="custom-multiselect"
            placeholder="— Select —"
            v-model="filter.years_of_experience_ids"
            :options="experienceOptions.map(option => option.id)"
            :custom-label="opt => experienceOptions.find(x => x.id === opt).interval"
            :multiple="true"
            :closeOnSelect="false"
          >
            <template slot="tag" slot-scope="props">
              <div class="multiselect__tag">
                <span>
                  {{ experienceOptions.find(x => x.id === props.option).interval }}
                </span>
                <div
                  class="multiselect__tag-remove"
                  @mousedown.prevent="props.remove(props.option)"
                >
                  <cross-svg />
                </div>
              </div>
            </template>
          </multiselect>
        </b-form-group>

        <b-form-group
          id="undergraduateDegreeGroup"
          label-for="undergraduateDegree"
        >
          <template slot="label">
            Undergraduate Degree
          </template>

          <multiselect
            id="undergraduateDegree"
            class="custom-multiselect"
            placeholder="— Select —"
            v-model="filter.undergraduate_degree_ids"
            :options="undergraduateDegreeOptions.map(option => option.id)"
            :custom-label="opt => undergraduateDegreeOptions.find(x => x.id === opt).name"
            :multiple="true"
            :closeOnSelect="false"
          >
            <template slot="tag" slot-scope="props">
              <div class="multiselect__tag">
                <span>
                  {{ undergraduateDegreeOptions.find(x => x.id === props.option).name }}
                </span>
                <div
                  class="multiselect__tag-remove"
                  @mousedown.prevent="props.remove(props.option)"
                >
                  <cross-svg />
                </div>
              </div>
            </template>
          </multiselect>
        </b-form-group>

        <b-form-group
          id="targetSalaryGroup"
          label-for="targetSalary"
        >
          <template slot="label">
            Target Salary
          </template>

          <multiselect
            id="targetSalary"
            class="custom-multiselect"
            placeholder="— Select —"
            v-model="filter.target_salary_ids"
            :options="targetSalaryOptions.map(option => option.id)"
            :custom-label="opt => targetSalaryOptions.find(x => x.id === opt).interval"
            :multiple="true"
            :closeOnSelect="false"
          >
            <template slot="tag" slot-scope="props">
              <div class="multiselect__tag">
                <span>
                  {{ targetSalaryOptions.find(x => x.id === props.option).interval }}
                </span>
                <div
                  class="multiselect__tag-remove"
                  @mousedown.prevent="props.remove(props.option)"
                >
                  <cross-svg />
                </div>
              </div>
            </template>
          </multiselect>
        </b-form-group>

        <b-form-group
          id="softwareGroup"
          label-for="software"
        >
          <template slot="label">
            Software
          </template>

          <multiselect
            id="software"
            class="custom-multiselect"
            placeholder="— Select —"
            v-model="filter.software_ids"
            :options="softwareOptions.map(option => option.id)"
            :custom-label="opt => softwareOptions.find(x => x.id === opt).name"
            :multiple="true"
            :closeOnSelect="false"
          >
            <template slot="tag" slot-scope="props">
              <div class="multiselect__tag">
                <span>
                  {{ softwareOptions.find(x => x.id === props.option).name }}
                </span>
                <div
                  class="multiselect__tag-remove"
                  @mousedown.prevent="props.remove(props.option)"
                >
                  <cross-svg />
                </div>
              </div>
            </template>
          </multiselect>
        </b-form-group>

        <b-form-group
          id="additionalExperienceGroup"
          label-for="additionalExperience"
        >
          <template slot="label">
            Additional Experience
          </template>

          <multiselect
            id="additionalExperience"
            class="custom-multiselect"
            placeholder="— Select —"
            v-model="filter.additional_experience_ids"
            :options="additionalExperienceOptions.map(option => option.id)"
            :custom-label="opt => additionalExperienceOptions.find(x => x.id === opt).name"
            :multiple="true"
            :closeOnSelect="false"
          >
            <template slot="tag" slot-scope="props">
              <div class="multiselect__tag">
                <span>
                  {{ additionalExperienceOptions.find(x => x.id === props.option).name }}
                </span>
                <div
                  class="multiselect__tag-remove"
                  @mousedown.prevent="props.remove(props.option)"
                >
                  <cross-svg />
                </div>
              </div>
            </template>
          </multiselect>
        </b-form-group>

        <div class="form-row">
          <b-form-group
            id="zipCodeGroup"
            label-for="zipCode"
          >
            <template slot="label">
              Location Zip Code
            </template>

            <b-form-input v-model="filter.zip_code" />
          </b-form-group>

          <b-form-group
            id="milesGroup"
            label-for="miles"
          >
            <template slot="label">
              + XX miles
            </template>

            <multiselect
              id="miles"
              class="custom-multiselect"
              placeholder="— Select —"
              selectLabel="Select"
              deselectLabel="Remove"
              v-model="filter.miles_id"
              :options="milesOptions.map(option => option.id)"
              :custom-label="opt => milesOptions.find(x => x.id === opt).value"
            >
              <template slot="tag" slot-scope="props">
                <div class="multiselect__tag">
                <span>
                  {{ milesOptions.find(x => x.id === props.option).value }}
                </span>
                  <div
                    class="multiselect__tag-remove"
                    @mousedown.prevent="props.remove(props.option)"
                  >
                    <cross-svg />
                  </div>
                </div>
              </template>
            </multiselect>
          </b-form-group>
        </div>

        <b-form-group
          id="languageGroup"
          label-for="language"
        >
          <template slot="label">
            Language (s)
          </template>

          <multiselect
            id="language"
            class="custom-multiselect"
            placeholder="— Select —"
            v-model="filter.language_ids"
            :options="languageOptions.map(option => option.id)"
            :custom-label="opt => languageOptions.find(x => x.id === opt).name"
            :multiple="true"
            :closeOnSelect="false"
          >
            <template slot="tag" slot-scope="props">
              <div class="multiselect__tag">
                <span>
                  {{ languageOptions.find(x => x.id === props.option).name }}
                </span>
                <div
                  class="multiselect__tag-remove"
                  @mousedown.prevent="props.remove(props.option)"
                >
                  <cross-svg />
                </div>
              </div>
            </template>
          </multiselect>
        </b-form-group>

        <b-form-group
          id="militaryGroup"
          label-for="military"
        >
          <b-form-checkbox
            id="military"
            v-model="filter.military"
          >
            Military
          </b-form-checkbox>
        </b-form-group>

        <div class="search-filter-buttons">
          <button
            @click="applyFilters"
            class="button search-filter__apply"
          >
            Apply Filers
          </button>

          <button
            class="icon-label search-filter__reset"
            @click="resetFilters"
          >
            <rotate-svg />
            <span>Reset Filters</span>
          </button>
        </div>
      </div>
    </div>

    <candidates-preview-list
      page="all"
      :candidates="candidates"
      :searchId="searchId"
    />
  </div>
</template>

<script>
import { BFormGroup, BFormCheckbox, BFormInput } from 'bootstrap-vue';
import Multiselect from 'vue-multiselect';
import CandidatesPreviewList from '@/components/candidate/CandidatesPreviewList.vue';
import RotateSvg from '@/components/svg/RotateSvg.vue';
import CrossSvg from '@/components/svg/CrossSvg.vue';

export default {
  name: 'SearchAllCandidates',
  components: {
    BFormGroup,
    BFormCheckbox,
    BFormInput,
    Multiselect,
    CandidatesPreviewList,
    RotateSvg,
    CrossSvg,
  },
  data() {
    return {
      candidates: [],
      filter: {
        job_title_ids: [],
        industry_ids: [],
        years_of_experience_ids: [],
        undergraduate_degree_ids: [],
        target_salary_ids: [],
        software_ids: [],
        additional_experience_ids: [],
        zip_code: null,
        miles_id: null,
        language_ids: [],
        military: false,
      },
      jobTitleOptions: [],
      industryOptions: [],
      experienceOptions: [],
      undergraduateDegreeOptions: [],
      targetSalaryOptions: [],
      softwareOptions: [],
      additionalExperienceOptions: [],
      languageOptions: [],
      milesOptions: [],
      mobileFilter: false,
    };
  },
  computed: {
    searchId() {
      return parseInt(this.$route.params.id, 10);
    },
    filtersCount() {
      let count = 0;

      Object.values(this.filter).forEach((value) => {
        if (Array.isArray(value)) {
          if (value.length) {
            count += 1;
          }
        } else if (value) {
          count += 1;
        }
      });

      return count;
    },
  },
  methods: {
    setCandidateOptions(data) {
      this.jobTitleOptions = data.job_titles;
      this.industryOptions = data.industries;
      this.experienceOptions = data.years_of_experience;
      this.undergraduateDegreeOptions = data.undergraduate_degrees;
      this.targetSalaryOptions = data.target_salaries;
      this.softwareOptions = data.software;
      this.additionalExperienceOptions = data.additional_experience;
      this.languageOptions = data.languages;
      this.milesOptions = data.miles;
    },
    resetFilters() {
      const setProps = (obj) => Object.entries(obj).map((item) => {
        const [key, value] = item;
        let newValue = null;

        if (Array.isArray(value)) {
          newValue = [];
        }

        return [key, newValue];
      });

      this.filter = Object.fromEntries(setProps(this.filter));
    },
    applyFilters() {
      if (this.filter.miles_id && !this.filter.zip_code) {
        window.flash('Miles filter can\'t be used without zip code', 'error');
        return;
      }

      if (this.filter.zip_code) {
        this.$store.commit('setFlashMessageStatus', false);

        this.verifyZipCode()
          .then((status) => {
            if (status) {
              this.searchCandidates();
            } else {
              window.flash('Such zip code doesn\'t exist', 'error');
            }
          })
          .catch((error) => {
            window.flash(error.response.data.message, 'error');
          });
      } else {
        this.searchCandidates();
      }
    },
    searchCandidates() {
      this.$store.dispatch('search/getAllCandidates', {
        need_filters: false,
        search_id: parseInt(this.$route.params.id, 10),
        filters: this.filter,
      })
        .then((response) => {
          this.candidates = response.data.candidates;

          this.$store.commit(
            'search/setAllCandidatesCount',
            response.data.number_of_candidates,
          );

          this.mobileFilter = false;
        })
        .catch((error) => {
          window.flash(error.response.data.message, 'error');
        });
    },
    toggleMobileFilter() {
      this.mobileFilter = !this.mobileFilter;
    },
    verifyZipCode() {
      return new Promise((resolve, reject) => {
        this.$store.dispatch('zipCode/getZipCodeInfo', this.filter.zip_code)
          .then((response) => {
            const { results } = response.data;
            let status = false;

            if (results) {
              const zipCodeData = results[this.filter.zip_code];

              status = zipCodeData && zipCodeData.length;
            }

            resolve(status);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  },
  created() {
    Promise.all([
      this.$store.dispatch('search/getAllCandidatesFilters', {
        params: {
          search_id: this.searchId,
        },
      }),
      this.$store.dispatch('search/getAllCandidates', {
        need_filters: true,
        search_id: this.searchId,
      }),
    ])
      .then((response) => {
        this.setCandidateOptions(response[0].data);
        this.candidates = response[1].data.candidates;

        this.filter = Object.assign(this.filter, response[1].data.filters);
      })
      .catch((error) => {
        window.flash(error.response.data.message, 'error');
      });
  },
};
</script>

<style lang="scss" scoped>
@import "src/assets/sass/views/employer/search.scss";
</style>
