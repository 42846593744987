<template>
  <b-form class="auth-form" @submit.prevent="submitForm">
    <div class="form-row">
      <b-form-group
        id="agencyNameGroup"
        label-for="agencyName"
        invalid-feedback="This is a required field and must be at least 2 characters."
      >
        <template slot="label">
          Agency Name <span class="text-danger">*</span>
        </template>
        <b-form-input
          id="agencyName"
          type="text"
          v-model="$v.form.name.$model"
          :state="validateState('name')"
        ></b-form-input>
      </b-form-group>

      <b-form-group
        id="agencyWebsiteGroup"
        label-for="agencyWebsite"
        invalid-feedback="This is a required field and must be at least 2 characters."
      >
        <template slot="label">
          Agency Website <span class="text-danger">*</span>
        </template>
        <b-form-input
          id="agencyWebsite"
          type="text"
          v-model="$v.form.website.$model"
          :state="validateState('website')"
        ></b-form-input>
      </b-form-group>
    </div>

    <b-form-group
      id="specializationsGroup"
      label-for="specializations"
      invalid-feedback="Please, select at least one."
      :state="validateState('specialization_ids')"
    >
      <template slot="label">
        What your agency specializes <span class="text-danger">*</span>
      </template>

      <multiselect
        id="specializations"
        class="custom-multiselect"
        placeholder="— Select —"
        v-model="form.specialization_ids"
        :options="specializationOptions.map(option => option.id)"
        :custom-label="opt => specializationOptions.find(x => x.id === opt).name"
        :multiple="true"
        :closeOnSelect="false"
        @close="$v.form.specialization_ids.$touch()"
      >
        <template slot="tag" slot-scope="props">
          <div class="multiselect__tag">
            <span>
              {{ specializationOptions.find(x => x.id === props.option).name }}
            </span>
            <div
              class="multiselect__tag-remove"
              @mousedown.prevent="props.remove(props.option)"
            >
              <cross-svg />
            </div>
          </div>
        </template>
      </multiselect>
    </b-form-group>

    <b-form-group
      id="locationsGroup"
      label-for="locations"
      invalid-feedback="Please, select at least one."
      :state="validateState('location_ids')"
    >
      <template slot="label">
        What locations your agency recruits for <span class="text-danger">*</span>
      </template>

      <multiselect
        id="locations"
        class="custom-multiselect"
        placeholder="— Select —"
        v-model="form.location_ids"
        :options="locationOptions.map(option => option.id)"
        :custom-label="opt => locationOptions.find(x => x.id === opt).name"
        :multiple="true"
        :closeOnSelect="false"
        @close="$v.form.location_ids.$touch()"
      >
        <template slot="tag" slot-scope="props">
          <div class="multiselect__tag">
            <span>
              {{ locationOptions.find(x => x.id === props.option).name }}
            </span>
            <div
              class="multiselect__tag-remove"
              @mousedown.prevent="props.remove(props.option)"
            >
              <cross-svg />
            </div>
          </div>
        </template>
      </multiselect>
    </b-form-group>

    <div class="form-row">
      <b-form-group
        id="contactNameGroup"
        label-for="contactName"
        invalid-feedback="This is a required field and must be at least 2 characters."
      >
        <template slot="label">
          Contact Name <span class="text-danger">*</span>
        </template>
        <b-form-input
          id="contactName"
          type="text"
          placeholder="First and last name"
          v-model="$v.form.contact_name.$model"
          :state="validateState('contact_name')"
        ></b-form-input>
      </b-form-group>

      <b-form-group
        id="contactTitleGroup"
        label-for="contactTitle"
        invalid-feedback="This is a required field and must be at least 2 characters."
      >
        <template slot="label">
          Contact Title <span class="text-danger">*</span>
        </template>
        <b-form-input
          id="contactTitle"
          type="text"
          v-model="$v.form.contact_title.$model"
          :state="validateState('contact_title')"
        ></b-form-input>
      </b-form-group>
    </div>

    <b-form-group
      id="emailGroup"
      label-for="email"
      invalid-feedback="This is a required field and must be email."
    >
      <template slot="label">
        Contact Email <span class="text-danger">*</span>
      </template>
      <b-form-input
        id="email"
        type="email"
        v-model="$v.form.email.$model"
        :state="validateState('email')"
      ></b-form-input>
    </b-form-group>

    <div class="form-row">
      <b-form-group
        id="passwordGroup"
        label-for="password"
        invalid-feedback="This is a required field and must be at least 6 characters."
      >
        <template slot="label">
          Password <span class="text-danger">*</span>
        </template>
        <b-form-input
          id="password"
          type="password"
          v-model="$v.form.password1.$model"
          :state="validateState('password1')"
        ></b-form-input>
      </b-form-group>

      <b-form-group
        id="passwordConfirmGroup"
        label-for="passwordConfirm"
        invalid-feedback="This is a required field."
      >
        <template slot="label">
          Confirm password <span class="text-danger">*</span>
        </template>
        <b-form-input
          id="passwordConfirm"
          type="password"
          v-model="$v.form.password2.$model"
          :state="validateState('password2')"
        ></b-form-input>
      </b-form-group>
    </div>

    <b-form-group id="termsGroup">
      <template slot="label">
        I have read and agree to the terms of <span class="text-danger">*</span>
      </template>

      <b-form-checkbox
        id="feeAgreement"
        v-model="$v.form.fee_agreement.$model"
        name="feeAgreement"
        :state="validateState('fee_agreement')"
      >
        <a href="https://the-vault-biz-prod.s3.eu-central-1.amazonaws.com/docs/fee_agreement.pdf" target="_blank" class="link-regular link-regular--bold">Fee Agreement</a>
      </b-form-checkbox>

      <b-form-checkbox
        id="releaseOfLiability"
        v-model="$v.form.release_of_liability.$model"
        name="releaseOfLiability"
        :state="validateState('release_of_liability')"
      >
        <a href="https://the-vault-biz-prod.s3.eu-central-1.amazonaws.com/docs/release_of_liability.pdf" target="_blank" class="link-regular link-regular--bold">Release of Liability</a>.
      </b-form-checkbox>

      <b-form-invalid-feedback
        :state="validateTerms()"
      >
        This is required fields.
      </b-form-invalid-feedback>
    </b-form-group>

    <div class="form-buttons">
      <button
        class="button"
        type="submit"
      >
        Sign Up
      </button>
    </div>
  </b-form>
</template>

<script>
import {
  BForm, BFormGroup, BFormInput, BFormCheckbox, BFormInvalidFeedback,
} from 'bootstrap-vue';
import { validationMixin } from 'vuelidate';
import {
  email, minLength, required, sameAs,
} from 'vuelidate/lib/validators';
import Multiselect from 'vue-multiselect';
import CrossSvg from '@/components/svg/CrossSvg.vue';

export default {
  name: 'AgencySignUpForm',
  components: {
    CrossSvg,
    BForm,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BFormInvalidFeedback,
    Multiselect,
  },
  mixins: [validationMixin],
  data() {
    return {
      form: {
        name: null,
        website: null,
        specialization_ids: [],
        location_ids: [],
        contact_name: null,
        contact_title: null,
        email: null,
        password1: null,
        password2: null,
        fee_agreement: false,
        release_of_liability: false,
        user_role: 'agency',
      },
      specializationOptions: [],
      locationOptions: [],
    };
  },
  validations: {
    form: {
      name: {
        required,
        minLength: minLength(2),
      },
      website: {
        required,
        minLength: minLength(2),
      },
      specialization_ids: {
        required,
      },
      location_ids: {
        required,
      },
      contact_name: {
        required,
        minLength: minLength(2),
      },
      contact_title: {
        required,
        minLength: minLength(2),
      },
      email: {
        required,
        email,
      },
      password1: {
        required,
        minLength: minLength(6),
      },
      password2: {
        required,
        minLength: minLength(6),
        sameAs: sameAs('password1'),
      },
      fee_agreement: {
        checked: (value) => value === true,
      },
      release_of_liability: {
        checked: (value) => value === true,
      },
    },
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    validateTerms() {
      const fields = ['fee_agreement', 'release_of_liability'];

      let state = null;

      fields.forEach((field) => {
        const fieldState = this.validateState(field);

        if (fieldState === false) {
          state = fieldState;
        }
      });

      return state;
    },
    submitForm() {
      this.$v.form.$reset();
      this.$v.form.$touch();

      if (!this.$v.form.$anyError) {
        const data = { ...this.form };
        delete data.fee_agreement;
        delete data.release_of_liability;

        this.$store.dispatch('auth/signUp', data)
          .then(() => {
            window.flash('Agency account created successfully', 'success');

            this.$router.push({
              name: 'SignIn',
              params: {
                type: this.$route.params.type,
                confirmEmailMessage: true,
              },
            });
          })
          .catch((error) => {
            window.flash(error.response.data.message, 'error');
          });
      }
    },
    getAgencyOptions() {
      this.$store.dispatch('agency/getAgencyOptions')
        .then((response) => {
          this.locationOptions = response.data.location_options;
          this.specializationOptions = response.data.specialization_options;
        })
        .catch((error) => {
          window.flash(error.response.data.message, 'error');
        });
    },
  },
  created() {
    this.getAgencyOptions();
  },
};
</script>
