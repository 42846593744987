<template>
  <!-- eslint-disable -->
  <svg :width="width" :height="height" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g class="fill" transform="translate(-200.000000, -4040.000000)" :fill="color">
        <g transform="translate(0.000000, 3920.000000)">
          <g transform="translate(80.000000, 80.000000)">
            <g transform="translate(120.000000, 40.000000)">
              <path d="M12,0.999999958 C18.0751322,0.999999958 23,5.9248677 23,12 C23,18.0751323 18.0751323,23 12,23 C5.9248677,23 0.999999958,18.0751322 0.999999958,12 C0.999999958,5.92486773 5.92486773,0.999999958 12,0.999999958 Z M12,2.99999996 C7.02943723,2.99999996 2.99999996,7.02943723 2.99999996,12 C2.99999996,16.9705627 7.0294372,21 12,21 C16.9705628,21 21,16.9705628 21,12 C21,7.0294372 16.9705627,2.99999996 12,2.99999996 Z M12,11 C12.5522847,11 13,11.4477153 13,12 L13,12 L13,16 C13,16.5522847 12.5522847,17 12,17 C11.4477152,17 11,16.5522847 11,16 L11,16 L11,12 C11,11.4477153 11.4477152,11 12,11 Z M11.2928932,7.2928932 C11.6834175,6.90236891 12.3165825,6.90236891 12.7071068,7.2928932 C13.0976311,7.68341749 13.0976311,8.31658247 12.7071068,8.70710676 C12.3165825,9.09763105 11.6834175,9.09763105 11.2928932,8.70710676 C10.9023689,8.31658247 10.9023689,7.68341749 11.2928932,7.2928932 Z"></path>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
  <!-- eslint-enable -->
</template>

<script>
export default {
  name: 'InfoSvg',
  props: {
    width: {
      type: String,
      default: '16px',
    },
    height: {
      type: String,
      default: '16px',
    },
    color: {
      type: String,
      default: '#000000',
    },
  },
};
</script>
