<template>
  <div>
    <vue-extend-layouts />

    <loader v-if="isLoading" />
    <flash />
  </div>
</template>

<script>
import VueExtendLayouts from 'vue-extend-layout';
import axios from 'axios';
import Loader from '@/components/utils/Loader.vue';
import Flash from '@/components/utils/Flash.vue';

export default {
  name: 'App',
  components: {
    VueExtendLayouts,
    Loader,
    Flash,
  },
  computed: {
    isLoading() {
      return this.$store.state.isLoading;
    },
  },
  created() {
    axios.defaults.headers.common.accept = 'application/json';

    axios.interceptors.request.use((config) => {
      this.$store.commit('setLoadingStatus', true);
      return config;
    }, (error) => {
      this.$store.commit('setLoadingStatus', false);
      return Promise.reject(error);
    });

    axios.interceptors.response.use((response) => {
      this.$store.commit('setLoadingStatus', false);
      return response;
    }, (error) => {
      this.$store.commit('setLoadingStatus', false);

      if (error.response.status === 401) {
        this.$store.commit('auth/signOut');
        this.$router.push({ name: 'Landing' });
        window.flash('Authorization error!', 'error');
      }

      return Promise.reject(error);
    });
  },
};
</script>

<style lang="scss">
@import "assets/sass/main.scss";
</style>
