import Vue from 'vue';

export default {
  init() {
    window.events = new Vue();

    window.flash = (message, status) => {
      window.events.$emit('flash', message, status);
    };
  },
};
