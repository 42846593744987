import Vue from 'vue';
import Vuex from 'vuex';

import auth from './modules/auth';
import employer from './modules/employer';
import agency from './modules/agency';
import candidate from './modules/candidate';
import search from './modules/search';
import request from './modules/request';
import zipCode from './modules/zip-code';
import video from './modules/video';
import connectCandidate from './modules/connect-candidate';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    mobileMenuStatus: false,
    isLoading: false,
    requestsCount: 0,
    flashMessageStatus: false,
  },
  getters: {
    getMobileMenuStatus(state) {
      return state.mobileMenuStatus;
    },
  },
  mutations: {
    setMobileMenuStatus(state, status) {
      state.mobileMenuStatus = status;
    },
    setLoadingStatus(state, isLoading) {
      if (isLoading) {
        state.requestsCount += 1;
        state.isLoading = true;
      } else if (state.requestsCount > 0) {
        state.requestsCount -= 1;
        state.isLoading = (state.requestsCount > 0);
      }
    },
    setFlashMessageStatus(state, status) {
      state.flashMessageStatus = status;
    },
  },
  actions: {},
  modules: {
    auth,
    employer,
    agency,
    candidate,
    search,
    request,
    zipCode,
    video,
    connectCandidate,
  },
});
