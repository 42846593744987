<template>
  <div></div>
</template>

<script>
export default {
  name: 'SignOut',
  methods: {
    signOut() {
      this.$store.dispatch('auth/signOut')
        .finally(() => {
          this.$store.commit('auth/signOut');
          this.$router.push({ name: 'Landing' });
        });
    },
  },
  created() {
    this.signOut();
  },
};
</script>
