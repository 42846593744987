<template>
  <!-- eslint-disable -->
  <svg :width="width" :height="height" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g transform="translate(-200.000000, -4000.000000)" :fill="color">
        <g transform="translate(0.000000, 3920.000000)">
          <g transform="translate(80.000000, 80.000000)">
            <g transform="translate(120.000000, 0.000000)">
              <path d="M8.29289322,18.7071068 C7.93240926,18.3466228 7.90467972,17.7793918 8.20970461,17.3871006 L8.29289322,17.2928932 L13.585,12 L8.29289322,6.70710678 C7.93240926,6.34662282 7.90467972,5.77939176 8.20970461,5.38710056 L8.29289322,5.29289322 C8.65337718,4.93240926 9.22060824,4.90467972 9.61289944,5.20970461 L9.70710678,5.29289322 L15.7071068,11.2928932 C16.0675907,11.6533772 16.0953203,12.2206082 15.7902954,12.6128994 L15.7071068,12.7071068 L9.70710678,18.7071068 C9.31658249,19.0976311 8.68341751,19.0976311 8.29289322,18.7071068 Z"></path>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
  <!-- eslint-enable -->
</template>

<script>
export default {
  name: 'ChevronRightSvg',
  props: {
    width: {
      type: String,
      default: '16px',
    },
    height: {
      type: String,
      default: '16px',
    },
    color: {
      type: String,
      default: '#000000',
    },
  },
};
</script>
