<template>
  <!-- eslint-disable -->
  <svg width="24px" height="24px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g class="fill" transform="translate(-200.000000, -4080.000000)" fill="#000000">
        <g transform="translate(0.000000, 3920.000000)">
          <g transform="translate(80.000000, 80.000000)">
            <g transform="translate(120.000000, 80.000000)">
              <path d="M5.29289319,5.29289319 C5.68341749,4.9023689 6.31658246,4.9023689 6.70710676,5.29289319 L6.70710676,5.29289319 L12,10.585 L17.2928932,5.29289319 C17.6533772,4.93240923 18.2206082,4.9046797 18.6128994,5.20970459 L18.7071068,5.29289319 C19.097631,5.68341749 19.097631,6.31658246 18.7071068,6.70710676 L18.7071068,6.70710676 L13.415,12 L18.7071068,17.2928932 C19.0675907,17.6533772 19.0953203,18.2206082 18.7902954,18.6128994 L18.7071068,18.7071068 C18.3165825,19.097631 17.6834175,19.097631 17.2928932,18.7071068 L17.2928932,18.7071068 L12,13.415 L6.70710676,18.7071068 C6.34662279,19.0675907 5.77939174,19.0953203 5.38710053,18.7902954 L5.29289319,18.7071068 C4.9023689,18.3165825 4.9023689,17.6834175 5.29289319,17.2928932 L5.29289319,17.2928932 L10.585,12 L5.29289319,6.70710676 C4.93240923,6.34662279 4.9046797,5.77939174 5.20970459,5.38710053 Z"></path>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
  <!-- eslint-enable -->
</template>

<script>
export default {
  name: 'CrossSvg',
};
</script>
