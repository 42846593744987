<template>
  <div class="custom-container">
    <profile-menu :links="profileLinks" />

    <router-view />
  </div>
</template>

<script>
import ProfileMenu from '@/components/profile/ProfileMenu.vue';

export default {
  name: 'AgencyProfileWrapper',
  components: {
    ProfileMenu,
  },
  data() {
    return {
      profileLinks: [
        {
          label: 'Profile',
          routeName: 'AgencyProfile',
        },
        {
          label: 'Change password',
          routeName: 'AgencyChangePassword',
        },
      ],
    };
  },
};
</script>

<style lang="scss">
@import 'src/assets/sass/views/profile/profile';
</style>
