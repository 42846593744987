import Vue from 'vue';
import VueRouter from 'vue-router';

import Landing from '@/views/Landing.vue';
import NotFound from '@/views/NotFound.vue';

import SignIn from '@/views/auth/SignIn.vue';
import SignUp from '@/views/auth/SignUp.vue';
import SignOut from '@/views/auth/SignOut.vue';
import ResetPassword from '@/views/auth/ResetPassword.vue';
import SetNewPassword from '@/views/auth/SetNewPassword.vue';
import ConfirmEmail from '@/views/auth/ConfirmEmail.vue';
import SendEmailConfirmationLink from '@/views/auth/SendEmailConfirmationLink.vue';

import MyCandidates from '@/views/agency/MyCandidates.vue';
import SubmitCandidate from '@/views/agency/SubmitCandidate.vue';
import EditCandidate from '@/views/agency/EditCandidate.vue';
import ConfirmCandidate from '@/views/agency/ConfirmCandidate.vue';
import RequestedCandidates from '@/views/agency/RequestedCandidates.vue';

import AgencyProfileWrapper from '@/views/agency/AgencyProfileWrapper.vue';
import AgencyProfile from '@/views/agency/AgencyProfile.vue';
import AgencyChangePassword from '@/views/agency/AgencyChangePassword.vue';

import Searches from '@/views/employer/Searches.vue';
import MyVault from '@/views/employer/MyVault.vue';
import SearchWrapper from '@/components/employer/SearchWrapper.vue';
import SearchAllCandidates from '@/views/employer/SearchAllCandidates.vue';
import SearchSavedCandidates from '@/views/employer/SearchSavedCandidates.vue';
import SearchRequestedCandidates from '@/views/employer/SearchRequestedCandidates.vue';

import EmployerProfileWrapper from '@/views/employer/EmployerProfileWrapper.vue';
import EmployerProfile from '@/views/employer/EmployerProfile.vue';
import EmployerChangePassword from '@/views/employer/EmployerChangePassword.vue';

import store from '../store';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Landing',
    component: Landing,
    meta: {
      layout: 'landing',
      permissions: [0],
    },
  },
  {
    path: '/sign-in/:type(agency|employer)',
    name: 'SignIn',
    component: SignIn,
    props: true,
    meta: {
      layout: 'auth',
      permissions: [0],
    },
  },
  {
    path: '/sign-up/:type(agency|employer)',
    name: 'SignUp',
    component: SignUp,
    meta: {
      layout: 'auth',
      permissions: [0],
    },
  },
  {
    path: '/sign-out/:type(agency|employer)',
    name: 'SignOut',
    component: SignOut,
    meta: {
      layout: 'auth',
      permissions: [1, 2],
    },
  },
  {
    path: '/reset-password/:type(agency|employer)',
    name: 'ResetPassword',
    component: ResetPassword,
    meta: {
      layout: 'auth',
      permissions: [0],
    },
  },
  {
    path: '/set-password/:type(agency|employer)/:token',
    name: 'SetNewPassword',
    component: SetNewPassword,
    meta: {
      layout: 'auth',
      permissions: [0],
    },
  },
  {
    path: '/confirm-email/:type(agency|employer)/:token',
    name: 'ConfirmEmail',
    component: ConfirmEmail,
    meta: {
      layout: 'auth',
      permissions: [0],
    },
  },
  {
    path: '/send-email-confirmation-link',
    name: 'SendEmailConfirmationLink',
    component: SendEmailConfirmationLink,
    props: true,
    meta: {
      layout: 'auth',
      permissions: [0],
    },
  },
  {
    path: '/my-candidates',
    name: 'MyCandidates',
    component: MyCandidates,
    meta: {
      layout: 'agency',
      permissions: [2],
    },
  },
  {
    path: '/submit-candidate',
    name: 'SubmitCandidate',
    component: SubmitCandidate,
    meta: {
      layout: 'agency',
      permissions: [2],
    },
  },
  {
    path: '/edit-candidate/:id',
    name: 'EditCandidate',
    component: EditCandidate,
    meta: {
      layout: 'agency',
      permissions: [2],
    },
  },
  {
    path: '/confirm-candidate/:token',
    name: 'ConfirmCandidate',
    component: ConfirmCandidate,
    meta: {
      layout: 'auth',
      permissions: [0],
    },
  },
  {
    path: '/requested-candidates',
    name: 'RequestedCandidates',
    component: RequestedCandidates,
    meta: {
      layout: 'agency',
      permissions: [2],
    },
  },
  {
    path: '/profile/agency',
    component: AgencyProfileWrapper,
    meta: {
      layout: 'agency',
      permissions: [2],
    },
    children: [
      {
        path: '',
        name: 'AgencyProfile',
        component: AgencyProfile,
        meta: {
          layout: 'agency',
          permissions: [2],
        },
      },
      {
        path: 'change-password',
        name: 'AgencyChangePassword',
        component: AgencyChangePassword,
        meta: {
          layout: 'agency',
          permissions: [2],
        },
      },
    ],
  },
  {
    path: '/searches',
    name: 'Searches',
    component: Searches,
    meta: {
      layout: 'employer',
      permissions: [1],
    },
  },
  {
    path: '/searches/:id',
    component: SearchWrapper,
    name: 'Search',
    redirect: { name: 'SearchAllCandidates' },
    meta: {
      layout: 'employer',
      permissions: [1],
    },
    children: [
      {
        path: 'all',
        name: 'SearchAllCandidates',
        component: SearchAllCandidates,
        meta: {
          layout: 'employer',
          permissions: [1],
        },
      },
      {
        path: 'saved',
        name: 'SearchSavedCandidates',
        component: SearchSavedCandidates,
        meta: {
          layout: 'employer',
          permissions: [1],
        },
      },
      {
        path: 'requested',
        name: 'SearchRequestedCandidates',
        component: SearchRequestedCandidates,
        meta: {
          layout: 'employer',
          permissions: [1],
        },
      },
    ],
  },
  {
    path: '/my-vault',
    name: 'MyVault',
    component: MyVault,
    meta: {
      layout: 'employer',
      permissions: [1],
    },
  },
  {
    path: '/profile/employer',
    component: EmployerProfileWrapper,
    meta: {
      layout: 'employer',
      permissions: [1],
    },
    children: [
      {
        path: '',
        name: 'EmployerProfile',
        component: EmployerProfile,
        meta: {
          layout: 'employer',
          permissions: [1],
        },
      },
      {
        path: 'change-password',
        name: 'EmployerChangePassword',
        component: EmployerChangePassword,
        meta: {
          layout: 'employer',
          permissions: [1],
        },
      },
    ],
  },
  {
    path: '*',
    name: 'NotFound',
    component: NotFound,
    meta: {
      layout: 'not-found',
      permissions: [0, 1, 2],
    },
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  store.commit('setMobileMenuStatus', false);

  const userPermissions = parseInt(store.state.auth.userPermissions, 10);
  const pagePermissions = (to.meta && to.meta.permissions && to.meta.permissions.length)
    ? to.meta.permissions
    : [0];

  if (userPermissions && !store.state.auth.authUser) {
    store.dispatch('auth/getAuthUser')
      .catch((error) => {
        window.flash(error.response.data.message, 'error');
      });
  }

  if (pagePermissions.includes(userPermissions)) {
    next();
  } else if (to.path === '/' && userPermissions === 1) {
    next({ name: 'Searches' });
  } else if (to.path === '/' && userPermissions === 2) {
    next({ name: 'MyCandidates' });
  } else {
    next({ name: 'NotFound', params: [to.path] });
  }
});

export default router;
