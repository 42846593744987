<template>
  <div class="candidates-preview-list">
    <template v-if="candidates && candidates.length">
      <candidate-preview
        v-for="(candidate, index) in candidates"
        :key="index"
        :page="page"
        :candidate="candidate"
        :fullInfo="showFullInfo"
        :searchId="searchId"
        @onCreateRequest="openCreateRequestModal(candidate)"
        @onDeleteRequest="deleteRequest(candidate)"
        @onAddCandidateToSaved="addCandidateToSaved(candidate)"
        @onDeleteCandidateFromSaved="deleteCandidateFromSaved(candidate)"
        @onContactAgency="openContactAgencyModal(candidate)"
      />

      <b-modal
        v-if="requestedCandidate"
        id="create-request"
        modal-class="create-request-modal"
        size="lg"
        v-model="createRequestModal"
        title="Request Candidate Resume"
        centered
        @hidden="clearRequestForm"
      >
        <template slot="modal-header-close">
          <cross-svg />
        </template>

        <div class="create-request-title">
          You are going to request resume for the following Candidate
        </div>

        <div class="request-candidate">
          <div class="request-candidate-header">
            <div class="request-candidate-id">
              <span>Candidate ID:</span>
              <b>{{ requestedCandidate.id }}</b>
            </div>

            <div class="request-candidate-agency">
              <b>{{ requestedCandidate.agency_name }}</b>
            </div>
          </div>

          <div class="request-candidate-summary">
            {{ requestedCandidate.summary }}
          </div>

          <b-form-textarea
            class="request-candidate-message"
            v-model="requestForm.message"
            :placeholder="requestMessagePlaceholder"
          ></b-form-textarea>
        </div>

        <template slot="modal-footer">
          <button
            class="action-label action-label--dark"
            @click="closeCreateRequestModal"
          >
            Cancel
          </button>

          <button
            class="button"
            @click="createRequest"
          >
            Send Request
          </button>
        </template>
      </b-modal>

      <b-modal
        v-if="contactAgencyCandidate"
        id="contact-agency"
        modal-class="contact-agency-modal"
        size="lg"
        v-model="contactAgencyModal"
        title="Contact Agency"
        centered
        @hidden="clearContactAgencyForm"
      >
        <template slot="modal-header-close">
          <cross-svg />
        </template>

        <div class="contact-agency-text">
          Please type your message to the Recruiter/Staffing Agency here and your message will
          be sent to the appropriate representative for this candidate.
        </div>

        <b-form-textarea
          class="contact-agency-message"
          v-model="contactAgencyForm.message"
        ></b-form-textarea>

        <template slot="modal-footer">
          <button
            class="action-label action-label--dark"
            @click="closeContactAgencyModal"
          >
            Cancel
          </button>

          <button
            class="button"
            @click="contactAgency"
          >
            Contact Agency
          </button>
        </template>
      </b-modal>
    </template>

    <template v-else>
      <div class="empty-list">
        No candidates
      </div>
    </template>
  </div>
</template>

<script>
import { BModal, BFormTextarea } from 'bootstrap-vue';
import CandidatePreview from '@/components/candidate/CandidatePreview.vue';
import CrossSvg from '@/components/svg/CrossSvg.vue';

export default {
  name: 'CandidatePreviewsList',
  props: {
    page: {
      type: String,
      required: true,
    },
    searchId: {
      type: Number,
      default: 0,
    },
    candidates: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    BModal,
    BFormTextarea,
    CandidatePreview,
    CrossSvg,
  },
  data() {
    return {
      createRequestModal: false,
      requestedCandidate: null,
      requestForm: {
        message: null,
      },
      contactAgencyModal: false,
      contactAgencyCandidate: null,
      contactAgencyForm: {
        message: null,
      },
      requestMessagePlaceholder: 'Message (optional) - if you have additional questions you '
        + 'would like to ask before requesting the profile, please ask those questions here.',
    };
  },
  computed: {
    showFullInfo() {
      return this.page === 'my-vault';
    },
  },
  methods: {
    openCreateRequestModal(candidate) {
      this.requestedCandidate = candidate;
      this.createRequestModal = true;
    },
    openContactAgencyModal(candidate) {
      this.contactAgencyCandidate = candidate;
      this.contactAgencyModal = true;
    },
    closeCreateRequestModal() {
      this.createRequestModal = false;
    },
    closeContactAgencyModal() {
      this.contactAgencyModal = false;
    },
    clearRequestForm() {
      this.requestForm.message = null;
    },
    clearContactAgencyForm() {
      this.contactAgencyForm.message = null;
    },
    createRequest() {
      this.$store.dispatch('request/createRequest', {
        candidate_id: this.requestedCandidate.id,
        search_id: this.searchId,
        message: this.requestForm.message,
      })
        .then((response) => {
          if (this.page === 'all') {
            const requestInfo = {
              id: response.data.id,
              status: response.data.status,
              search: {
                id: response.data.search_id,
              },
              timestamp_string: '<1min ago',
            };

            this.updateCandidatePreview(response.data.candidate_id, requestInfo);
          } else if (this.page === 'saved') {
            this.removeCandidatePreview(this.requestedCandidate.id);
          }

          if (this.requestedCandidate.is_saved) {
            this.$store.commit(
              'search/setSavedCandidatesCount',
              this.$store.state.search.savedCandidatesCount - 1,
            );
          }

          this.$store.commit(
            'search/setRequestedCandidatesCount',
            this.$store.state.search.requestedCandidatesCount + 1,
          );

          this.closeCreateRequestModal();
          window.flash('Request was sent successfully!', 'success');
        })
        .catch((error) => {
          window.flash(error.response.data.message, 'error');
        });
    },
    deleteRequest(candidate) {
      this.$store.dispatch('request/deleteRequest', candidate.request_info.id)
        .then(() => {
          if (this.page === 'all') {
            this.updateCandidatePreview(candidate.id, null);
          } else if (this.page === 'requested') {
            this.removeCandidatePreview(candidate.id);
          }

          this.$store.commit(
            'search/setRequestedCandidatesCount',
            this.$store.state.search.requestedCandidatesCount - 1,
          );

          window.flash('Request successfully deleted!', 'success');
        })
        .catch((error) => {
          window.flash(error.response.data.message, 'error');
        });
    },
    addCandidateToSaved(candidate) {
      this.$store.dispatch('candidate/addCandidateToSaved', {
        searchId: this.searchId,
        candidateId: candidate.id,
      })
        .then(() => {
          this.updateCandidatePreview(candidate.id, null, true);

          this.$store.commit(
            'search/setSavedCandidatesCount',
            this.$store.state.search.savedCandidatesCount + 1,
          );

          window.flash('Candidate successfully saved!', 'success');
        })
        .catch((error) => {
          window.flash(error.response.data.message, 'error');
        });
    },
    deleteCandidateFromSaved(candidate) {
      this.$store.dispatch('candidate/deleteCandidateFromSaved', {
        searchId: this.searchId,
        candidateId: candidate.id,
      })
        .then(() => {
          if (this.page === 'all') {
            this.updateCandidatePreview(candidate.id, null, false);
          } else if (this.page === 'saved') {
            this.removeCandidatePreview(candidate.id);
          }

          this.$store.commit(
            'search/setSavedCandidatesCount',
            this.$store.state.search.savedCandidatesCount - 1,
          );

          window.flash('Candidate successfully deleted!', 'success');
        })
        .catch((error) => {
          window.flash(error.response.data.message, 'error');
        });
    },
    contactAgency() {
      this.$store.dispatch('request/contactAgency', {
        requestId: this.contactAgencyCandidate.request_info.id,
        data: {
          message: this.contactAgencyForm.message,
        },
      })
        .then(() => {
          const candidate = this.contactAgencyCandidate;
          this.updateCandidatePreview(candidate.id, candidate.request_info, false, true);
          this.closeContactAgencyModal();

          window.flash('Candidate is in touch now', 'success');
        })
        .catch((error) => {
          window.flash(error.response.data.message, 'error');
        });
    },
    updateCandidatePreview(candidateId, requestInfo = null, saved = false, inTouch = false) {
      const candidateIndex = this.candidates
        .findIndex((candidate) => candidate.id === candidateId);

      const candidateData = { ...this.candidates[candidateIndex] };

      candidateData.request_info = requestInfo;

      if (candidateData.request_info) {
        candidateData.request_info.in_touch = inTouch;
      }

      candidateData.is_saved = saved;

      this.$set(this.candidates, candidateIndex, candidateData);
    },
    removeCandidatePreview(candidateId) {
      const candidateIndex = this.candidates
        .findIndex((candidate) => candidate.id === candidateId);

      this.candidates.splice(candidateIndex, 1);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/assets/sass/views/candidate/candidates-preview-list.scss";
</style>
