import axios from 'axios';

const state = {
  allCandidatesCount: 0,
  savedCandidatesCount: 0,
  requestedCandidatesCount: 0,
};

const mutations = {
  setAllCandidatesCount(st, count) {
    state.allCandidatesCount = count;
  },
  setSavedCandidatesCount(st, count) {
    state.savedCandidatesCount = count;
  },
  setRequestedCandidatesCount(st, count) {
    state.requestedCandidatesCount = count;
  },
};

const actions = {
  getSearches(context, data) {
    return new Promise((resolve, reject) => {
      axios.get('/api/v1/searches/', data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  createSearch(context, data) {
    return new Promise((resolve, reject) => {
      axios.post('/api/v1/searches/', data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  updateSearch(context, data) {
    return new Promise((resolve, reject) => {
      axios.put(`/api/v1/searches/${data.searchId}`, data.searchData)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  deleteSearch(context, searchId) {
    return new Promise((resolve, reject) => {
      axios.delete(`/api/v1/searches/${searchId}`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getJobFunctionOptions() {
    return new Promise((resolve, reject) => {
      axios.get('/api/v1/searches/job_functions')
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getAllCandidatesFilters(context, data) {
    return new Promise((resolve, reject) => {
      axios.get('/api/v1/searches/filters', data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getAllCandidates(context, data) {
    return new Promise((resolve, reject) => {
      axios.post('/api/v1/candidates/search', data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getSavedCandidates(context, searchId) {
    return new Promise((resolve, reject) => {
      axios.get(`/api/v1/searches/${searchId}/saved_candidates`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getRequestedCandidates(context, searchId) {
    return new Promise((resolve, reject) => {
      axios.get(`/api/v1/searches/${searchId}/requested_candidates`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
