import axios from 'axios';

const state = {};

const getters = {};

const mutations = {};

const actions = {
  getEmployerProfile(context, employerId) {
    return new Promise((resolve, reject) => {
      axios.get(`/api/v1/employers/${employerId}/profile`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  updateEmployerProfile(context, data) {
    return new Promise((resolve, reject) => {
      axios.put(`/api/v1/employers/${data.employerId}/profile`, data.employerData)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getVaultCandidates(context, data) {
    return new Promise((resolve, reject) => {
      axios.get(`/api/v1/employers/${data.employerId}/my_vault`, data.data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
