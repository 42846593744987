<template>
  <div class="page-loader">
    <loader-svg />
  </div>
</template>

<script>
import LoaderSvg from '@/components/svg/LoaderSvg.vue';

export default {
  name: 'Loader',
  components: {
    LoaderSvg,
  },
};
</script>
