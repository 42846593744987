<template>
  <b-modal
    modal-class="landing-modal"
    :size="card.modal.size"
    :visible="modalStatus"
    :ref="card.id"
    :title="card.modal.title"
    centered
    no-stacking
    @change="modalStatusChange"
  >
    <template slot="modal-header-close">
      <cross-svg />
    </template>

    <template v-if="card.modal.howItWorksList.length">
      <div class="landing-modal__label">Here is how it works:</div>

      <ol class="landing-modal-list landing-modal-list--ordered">
        <li
          v-for="(text, index) in card.modal.howItWorksList"
          :key="index"
          class="landing-modal-list__item"
        >
          <span class="landing-modal-list__number">{{ index + 1 }}.</span>
          <span>{{ text }}</span>
        </li>
      </ol>
    </template>

    <div
      v-if="card.modal.text"
      v-html="card.modal.text"
      class="landing-modal-text"
    ></div>

    <div
      v-if="card.modal.blocks && card.modal.blocks.length"
      class="landing-modal-blocks"
    >
      <div
        v-for="(block, index) in card.modal.blocks"
        :key="index"
        class="landing-modal-block"
      >
        <div class="landing-modal__label">{{ block.title }}</div>
        <ul
          v-if="block.list && block.list.length"
          class="landing-modal-list landing-modal-list--unordered"
        >
          <li
            v-for="(blockText, textIndex) in block.list"
            :key="textIndex"
            class="landing-modal-list__item"
          >
            <span>{{ blockText }}</span>
          </li>
        </ul>
      </div>
    </div>

    <template slot="modal-footer">
      <button
        v-if="card.id === 'candidates'"
        class="button"
        @click="showConnectCandidateModal"
      >
        {{ card.modal.buttonTitle }}
      </button>
      <router-link
        v-else
        :to="{ name: 'SignUp', params: { type: card.linkType } }"
        class="button"
      >
        {{ card.modal.linkTitle }}
      </router-link>

      <span v-if="card.modal.showDisclaimer">No credit card required</span>
    </template>
  </b-modal>
</template>

<script>
import { BModal, VBModal } from 'bootstrap-vue';
import CrossSvg from '@/components/svg/CrossSvg.vue';

export default {
  name: 'LandingCardModal',
  components: {
    BModal,
    CrossSvg,
  },
  directives: {
    'b-modal': VBModal,
  },
  props: {
    card: {
      type: Object,
      required: true,
    },
    modalStatus: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    modalStatusChange(status) {
      this.$emit('onChange', status);
    },
    showConnectCandidateModal() {
      this.$emit('onShowConnectCandidateModal');
    },
  },
};
</script>
