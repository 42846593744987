<template>
  <div class="landing">
    <div class="landing-main">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <h1 class="landing-main__title" v-html="landingTitle"></h1>
          </div>

          <div class="col-lg-8 col-12 offset-lg-2">
            <div class="landing-main__text" v-html="landingText"></div>
          </div>
        </div>
      </div>

      <img
        class="landing-main__image fit-image"
        src="@/assets/img/landing/landing-bg.jpg"
        alt="The Vault"
      >
    </div>

    <div class="container">
      <div class="row landing-cards">
        <landing-card
          v-for="(card, index) in landingCards"
          :key="index"
          :card="card"
          @onShowConnectCandidateModal="showConnectCandidateModal"
        ></landing-card>
      </div>
    </div>

    <landing-connect-candidate-modal
      :modalStatus="connectCandidateModal"
      :jobFunctionOptions="jobFunctionOptions"
      @onChangeModalStatus="changeConnectCandidateModalStatus"
      @onConnectCandidate="connectCandidate"
    />

    <b-modal
      modal-class="landing-modal"
      size="lg"
      ref="success-connect"
      title="Connect Now"
      :visible="successConnectModal"
      centered
      footer-class="modal-footer--right"
      @change="changeSuccessConnectModalStatus"
    >
      <template slot="modal-header-close">
        <cross-svg />
      </template>

      <info-block type="success">
        Thank you {{ connectCandidateName }}! Your request was successfully submitted.
        <br />
        You should hear from one of our Agency members shortly. If not, please feel
        free to contact us via email <a href="mailto:info@thevault.biz">info@thevault.biz</a>
      </info-block>

      <template slot="modal-footer">
        <button
          class="action-label action-label--dark"
          @click="changeSuccessConnectModalStatus(false)"
        >
          Close
        </button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { BModal } from 'bootstrap-vue';
import LandingCard from '@/components/landing/LandingCard.vue';
import LandingConnectCandidateModal from '@/components/landing/LandingConnectCandidateModal.vue';
import InfoBlock from '@/components/utils/InfoBlock.vue';
import CrossSvg from '@/components/svg/CrossSvg.vue';
import { landingTitle, landingText, landingCards } from '@/helpers/constants';

export default {
  name: 'Landing',
  components: {
    BModal,
    LandingCard,
    LandingConnectCandidateModal,
    InfoBlock,
    CrossSvg,
  },
  data() {
    return {
      connectCandidateModal: false,
      successConnectModal: false,
      connectCandidateName: null,
      jobFunctionOptions: [],
      landingTitle,
      landingText,
      landingCards,
    };
  },
  methods: {
    changeConnectCandidateModalStatus(status) {
      this.connectCandidateModal = status;
    },
    changeSuccessConnectModalStatus(status) {
      this.successConnectModal = status;
    },
    showConnectCandidateModal() {
      if (!this.jobFunctionOptions || !this.jobFunctionOptions.length) {
        this.getJobFunctionOptions()
          .then(() => {
            this.changeConnectCandidateModalStatus(true);
          });
      } else {
        this.changeConnectCandidateModalStatus(true);
      }
    },
    getJobFunctionOptions() {
      return new Promise((resolve, reject) => {
        this.$store.dispatch('search/getJobFunctionOptions')
          .then((response) => {
            this.jobFunctionOptions = response.data.job_functions;
            resolve();
          })
          .catch((error) => {
            window.flash(error.response.data.message, 'error');
            reject();
          });
      });
    },
    connectCandidate(data) {
      const formData = new FormData();

      formData.append('cv', data.cv.file);

      delete data.cv;

      formData.append('data', JSON.stringify(data));

      this.$store.dispatch('connectCandidate/sendCandidateData', formData)
        .then(() => {
          this.connectCandidateName = `${data.first_name} ${data.last_name}`;
          this.changeSuccessConnectModalStatus(true);
        })
        .catch((error) => {
          window.flash(error.response.data.message, 'error');
        });
    },
  },
};
</script>
