<template>
  <div class="auth-block">
    <div class="auth-block-body">
      <div v-if="signInTitle" class="auth-block__title">{{ signInTitle }}</div>

      <info-block v-if="confirmEmailMessage" type="warning">
        You have to confirm your email. Confirmation link was sent to your email.
      </info-block>

      <info-block v-if="passwordResetMessage" type="success">
        Your password has been successfully updated, you can now Sign in with your new password
      </info-block>

      <info-block v-if="errorMessage" type="error">
        {{ errorMessage }}
        <template v-if="errorStatus === 'not confirmed'">
          <br />
          <br />
          You can re-send the confirmation link to your email at the
          <router-link
            :to="{ name: 'SendEmailConfirmationLink', params: { email: confirmationEmail } }"
            class="link-regular"
          >
            link
          </router-link>
        </template>
      </info-block>

      <sign-in-form :type="$route.params.type" @onSubmit="signIn"></sign-in-form>
    </div>

    <div class="auth-block-footer">
      <p>Do not have an Account?</p>
      <router-link
        :to="{ name: 'SignUp', params: { type: $route.params.type } }"
        class="link-regular link-regular--bold"
      >
        Sign Up
      </router-link>
    </div>
  </div>
</template>

<script>
import SignInForm from '@/components/auth/SignInForm.vue';
import InfoBlock from '@/components/utils/InfoBlock.vue';

export default {
  name: 'SignIn',
  components: {
    SignInForm,
    InfoBlock,
  },
  props: {
    confirmEmailMessage: {
      type: Boolean,
      default: false,
    },
    passwordResetMessage: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      signInTitle: null,
      errorMessage: null,
      errorStatus: null,
      confirmationEmail: null,
    };
  },
  methods: {
    signIn(data) {
      this.$store.dispatch('auth/signIn', data)
        .then((response) => {
          const { permissions } = response.data;

          localStorage.setItem('permissions', permissions);
          this.$store.commit('auth/setUserPermissions', permissions);

          if (permissions === 1) {
            this.$router.push({ name: 'Searches' });
          } else if (permissions === 2) {
            this.$router.push({ name: 'MyCandidates' });
          }
        })
        .catch((error) => {
          this.errorMessage = error.response.data.message;
          this.errorStatus = error.response.data.status;

          this.confirmationEmail = data.email;
        });
    },
  },
  created() {
    switch (this.$route.params.type) {
      case 'agency':
        this.signInTitle = 'Agency Sign In';
        break;

      case 'employer':
        this.signInTitle = 'Employer Sign In';
        break;

      default:
        break;
    }
  },
};
</script>
