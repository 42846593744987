<template>
  <div>
    <b-modal
      modal-class="employer-profile-modal"
      size="lg"
      :visible="modalVisibility"
      title="Company Profile"
      centered
      @change="changeModalVisibility"
    >
      <template slot="modal-header-close">
        <cross-svg />
      </template>

      <div class="employer-profile">
        <div class="employer-profile-row">
          <div class="employer-profile-group">
            <div class="employer-profile__label">Company Name</div>
            <div class="employer-profile__text">{{ profile.company_name }}</div>
          </div>
          <div class="employer-profile-group">
            <div class="employer-profile__label">Company Website</div>
            <div class="employer-profile__text">{{ profile.company_website }}</div>
          </div>
        </div>

        <div class="employer-profile-row">
          <div class="employer-profile-group">
            <div class="employer-profile__label"># of employees in the company</div>
            <div class="employer-profile__text">{{ profile.number_of_company_employees }}</div>
          </div>
          <div class="employer-profile-group">
            <div class="employer-profile__label">Company type</div>
            <div class="employer-profile__text">{{ profile.company_type }}</div>
          </div>
        </div>

        <div class="employer-profile-row">
          <div class="employer-profile-group">
            <div class="employer-profile__label">Contact Name</div>
            <div class="employer-profile__text">{{ profile.contact_name }}</div>
          </div>
          <div class="employer-profile-group">
            <div class="employer-profile__label">Contact Title</div>
            <div class="employer-profile__text">{{ profile.contact_title }}</div>
          </div>
        </div>

        <div class="employer-profile-group">
          <div class="employer-profile__label">
            Brief description of the company and why someone would want to work there...
          </div>
          <div class="employer-profile__text">{{ profile.brief }}</div>
        </div>
      </div>

      <template slot="modal-footer">
        <button
          class="action-label action-label--dark"
          @click="changeModalVisibility(false)"
        >
          Close
        </button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { BModal } from 'bootstrap-vue';
import CrossSvg from '@/components/svg/CrossSvg.vue';

export default {
  name: 'EmployerProfileModal',
  components: {
    BModal,
    CrossSvg,
  },
  props: {
    modalVisibility: {
      type: Boolean,
      default: false,
    },
    profile: {
      type: Object,
      required: true,
    },
  },
  methods: {
    changeModalVisibility(visibility) {
      this.$emit('onVisibilityChange', visibility);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/sass/views/employer/employer-profile-modal.scss";
</style>
