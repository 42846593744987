<template>
  <div class="candidate-wrapper">
    <div class="candidate">
      <div class="candidate-image">
        <img
          class="fit-image"
          :src="candidate.photo_url"
          :alt="candidate.first_name + ' ' + candidate.last_name"
        >
      </div>

      <div class="candidate-body">
        <div
          v-if="candidate.active"
          class="candidate-requests"
          :class="{ 'candidate-requests--green' : candidate.number_of_requests > 0 }"
        >
          {{ candidate.number_of_requests }}
          {{ candidate.number_of_requests === 1 ? 'request' : 'requests '}}
        </div>

        <div v-else class="candidate-status">
          Pending Candidate Approval
        </div>

        <div class="candidate-name">{{ candidate.first_name }} {{ candidate.last_name }}</div>
        <div class="candidate-position">{{ candidate.job_title }}</div>

        <div class="candidate-info">
          <div>
            <div class="candidate-info__title">ID</div>
            <div class="candidate-info__text">{{ candidate.id }}</div>
          </div>
          <div>
            <div class="candidate-info__title">Created</div>
            <div class="candidate-info__text">{{ candidate.created_at }}</div>
          </div>
        </div>
      </div>

      <div class="candidate-footer">
        <button
          class="icon-label icon-label--small candidate-footer__action"
          @click="showProfile"
        >
          <eye-svg />
          <span>Profile</span>
        </button>

        <router-link
          :to="{ name: 'EditCandidate', params: { id: candidate.id } }"
          class="icon-label icon-label--small candidate-footer__action"
        >
          <edit-svg />
          <span>Edit</span>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import EyeSvg from '@/components/svg/EyeSvg.vue';
import EditSvg from '@/components/svg/EditSvg.vue';

export default {
  name: 'CandidateCard',
  components: {
    EyeSvg,
    EditSvg,
  },
  props: {
    candidate: {
      type: Object,
      required: true,
    },
  },
  methods: {
    showProfile() {
      this.$emit('showProfile');
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/assets/sass/views/candidate/candidate-card";
</style>
