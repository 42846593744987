<template>
  <div>
    <div
      ref="file-wrapper"
      class="form-file"
      tabindex="0"
      @keyup.enter="activateFileInput"
      @click="activateFileInput"
    >
    <span class="form-file__icon">
      <upload-cloud-svg />
    </span>

      <template v-if="file">
      <span
        class="form-file__title"
        :title="fileName + '.' + fileType"
      >
        {{ fileName }}.{{ fileType }}
      </span>

        <span class="form-file__text form-file__size">
        {{ fileSize }}
      </span>
      </template>

      <template v-else>
      <span class="form-file__title">
        {{ title }}
        <span v-if="required" class="text-danger">*</span>
      </span>
        <span class="form-file__text">
        {{ description }}
        Filetype: {{ allowedTypesString }}.
      </span>
      </template>

      <input ref="file-input" type="file" hidden @change="selectFile">
    </div>

    <b-modal
      ref="cropImageModal"
      size="md"
      title="Crop image"
      no-close-on-backdrop
      centered
    >
      <template slot="modal-header-close">
        <cross-svg />
      </template>

      <cropper
        v-if="fileUrl"
        class="cropper"
        :src="fileUrl"
        :stencil-props="{
          handlers: {},
          movable: true,
          scalable: false,
          aspectRatio: 1,
        }"
        :stencilSize="defaultCropperSize"
        :resize-image="{
          adjustStencil: true
        }"
        defaultBoundaries="fit"
        @change="onCropperChange"
      ></cropper>

      <template slot="modal-footer">
        <button
          class="action-label action-label--dark"
          @click="closeCropModal"
        >
          Cancel
        </button>
        <button
          class="button button--small"
          @click="saveCroppedImage"
        >
          Save
        </button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { BModal } from 'bootstrap-vue';
import { Cropper } from 'vue-advanced-cropper';
import UploadCloudSvg from '@/components/svg/UploadCloudSvg.vue';
import CrossSvg from '@/components/svg/CrossSvg.vue';

export default {
  name: 'FormFile',
  components: {
    BModal,
    Cropper,
    UploadCloudSvg,
    CrossSvg,
  },
  props: {
    required: {
      type: Boolean,
      default: false,
    },
    allowedTypes: {
      type: Array,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    cropper: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      file: null,
      fileName: null,
      fileSize: null,
      fileType: null,
      fileUrl: null,
      croppedImage: null,
    };
  },
  computed: {
    allowedTypesString() {
      return this.allowedTypes.join(', ');
    },
  },
  methods: {
    activateFileInput() {
      const resumeFileInput = this.$refs['file-input'];
      const resumeFileWrapper = this.$refs['file-wrapper'];

      const stopClickBubbling = (e) => {
        e.stopPropagation();
      };

      resumeFileInput.addEventListener('click', stopClickBubbling);
      resumeFileInput.click();

      const fileInputFocus = () => {
        setTimeout(() => {
          this.$emit('validate');
        }, 200);

        resumeFileWrapper.removeEventListener('focus', fileInputFocus);
        resumeFileInput.removeEventListener('click', stopClickBubbling);
      };

      setTimeout(() => {
        resumeFileWrapper.addEventListener('focus', fileInputFocus);
      }, 500);
    },
    selectFile(e) {
      console.log(e);
      const file = e.target.files[0];

      this.$refs['file-input'].value = null;

      if (file) {
        this.file = file;
        [, this.fileType] = file.type.split('/');
        this.fileName = file.name.substring(0, file.name.lastIndexOf('.'));

        if (this.cropper && this.allowedTypes.includes(this.fileType)) {
          this.openCropModal();
        } else {
          this.fileSize = this.formatBytes(file.size);

          if (this.fileType === 'msword') {
            this.fileType = 'doc';
          }

          if (this.fileType === 'vnd.openxmlformats-officedocument.wordprocessingml.document') {
            this.fileType = 'docx';
          }

          if (this.fileType === 'plain') {
            this.fileType = 'txt';
          }

          this.$emit('change', {
            name: this.fileName,
            type: this.fileType,
            size: this.fileSize,
            file: this.file,
          });
        }
      }
    },
    formatBytes(bytes, decimals = 2) {
      if (bytes === 0) return '0 Bytes';

      const k = 1024;
      const dm = decimals < 0 ? 0 : decimals;
      const sizes = ['Bytes', 'Kb', 'Mb', 'Gb', 'Tb', 'Pb', 'Eb', 'Zb', 'Yb'];

      const i = Math.floor(Math.log(bytes) / Math.log(k));

      return `${parseFloat((bytes / (k ** i)).toFixed(dm))} ${sizes[i]}`;
    },
    defaultCropperSize({ boundaries }) {
      return {
        width: boundaries.width,
        height: boundaries.height,
      };
    },
    openCropModal() {
      const reader = new FileReader();
      const $this = this;

      reader.readAsDataURL(this.file);

      reader.onload = function (e) {
        $this.fileUrl = e.target.result;

        $this.$refs.cropImageModal.show();
      };
    },
    onCropperChange({ canvas }) {
      this.croppedImage = canvas.toDataURL('image/jpeg');
    },
    saveCroppedImage() {
      const img = new Image();
      img.src = this.croppedImage;

      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');

      const $this = this;

      img.onload = function () {
        canvas.width = 200;
        canvas.height = 200;

        ctx.drawImage(img, 0, 0, img.width, img.height, 0, 0, 200, 200);

        canvas.toBlob((blob) => {
          const imageFile = new File([blob], 'photo', { type: 'image/jpeg' });
          $this.fileSize = $this.formatBytes(imageFile.size);

          $this.$emit('change', {
            name: $this.fileName,
            type: 'jpeg',
            size: $this.fileSize,
            file: imageFile,
          });
        }, 'image/jpeg');

        $this.$refs.cropImageModal.hide();
      };
    },
    closeCropModal() {
      this.file = null;
      this.fileType = null;
      this.fileName = null;
      this.fileUrl = null;
      this.croppedImage = null;

      this.$emit('change', null);

      this.$refs.cropImageModal.hide();
    },
  },
};
</script>
