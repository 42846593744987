<template>
  <!-- eslint-disable -->
  <svg width="24px" height="24px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g class="fill" transform="translate(-160.000000, -4080.000000)" fill="#000000">
        <g transform="translate(0.000000, 3920.000000)">
          <g transform="translate(80.000000, 80.000000)">
            <g transform="translate(80.000000, 80.000000)">
              <path d="M14,0.999999958 C15.6568543,0.999999958 17,2.3431457 17,3.99999996 L17,4.99999996 L21,4.99999997 C21.5128358,4.99999997 21.9355071,5.38604016 21.9932722,5.88337885 L22,5.99999997 C22,6.55228472 21.5522847,6.99999997 21,6.99999997 L21,6.99999997 L20,6.99999996 L20,20 C20,21.6568543 18.6568543,23 17,23 L6.99999997,23 C5.34314571,23 3.99999997,21.6568543 3.99999997,20 L3.99999996,6.99999996 L2.99999996,6.99999997 C2.48716412,6.99999997 2.0644928,6.61395978 2.00672769,6.1166211 L1.99999996,5.99999997 C1.99999996,5.44771522 2.44771521,4.99999997 2.99999996,4.99999997 L2.99999996,4.99999997 L6.99999996,4.99999996 L6.99999998,3.99999996 C6.99999998,2.34314571 8.34314574,0.999999958 9.99999998,0.999999958 L14,0.999999958 Z M18,6.99999996 L5.99999996,6.99999996 L5.99999997,20 C5.99999997,20.5522848 6.4477152,21 6.99999997,21 L17,21 C17.5522848,21 18,20.5522848 18,20 L18,6.99999996 Z M9.99999999,10 C10.5522847,10 11,10.4477152 11,11 L11,11 L11,17 C11,17.5522847 10.5522847,18 9.99999999,18 C9.44771524,18 8.99999999,17.5522847 8.99999999,17 L8.99999999,17 L8.99999999,11 C8.99999999,10.4477152 9.44771524,10 9.99999999,10 Z M14,10 C14.5522848,10 15,10.4477152 15,11 L15,11 L15,17 C15,17.5522847 14.5522848,18 14,18 C13.4477153,18 13,17.5522847 13,17 L13,17 L13,11 C13,10.4477152 13.4477153,10 14,10 Z M14,2.99999996 L9.99999998,2.99999996 C9.44771524,2.99999996 8.99999998,3.44771521 8.99999998,3.99999996 L8.99999996,4.99999996 L15,4.99999996 L15,3.99999996 C15,3.4477152 14.5522847,2.99999996 14,2.99999996 Z"></path>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
  <!-- eslint-enable -->
</template>

<script>
export default {
  name: 'TrashSvg',
};
</script>
