import axios from 'axios';

const state = {
  userPermissions: localStorage.getItem('permissions') || 0,
  authUser: null,
};

const getters = {
  getAuthUserName() {
    return state.authUser ? state.authUser.name : null;
  },
  getAuthUserId() {
    return state.authUser ? state.authUser.id : null;
  },
  getAuthUserPermissions() {
    return parseInt(state.userPermissions, 10);
  },
};

const mutations = {
  setUserPermissions(st, permissions) {
    state.userPermissions = permissions;
  },
  signOut() {
    localStorage.removeItem('permissions');
    state.userPermissions = 0;
    state.authUser = null;
  },
  setAuthUser(st, user) {
    state.authUser = user;
  },
};

const actions = {
  getAuthUser({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get('/api/v1/auth/me')
        .then((response) => {
          commit('setAuthUser', response.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  signUp(context, data) {
    return new Promise((resolve, reject) => {
      axios.post('/api/v1/auth/signup', data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  signIn(context, data) {
    return new Promise((resolve, reject) => {
      axios.post('/api/v1/auth/login', data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  signOut() {
    return new Promise((resolve, reject) => {
      axios.get('/api/v1/auth/logout')
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  changePassword(context, data) {
    return new Promise((resolve, reject) => {
      axios.put('/api/v1/auth/change_password', data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  confirmEmail(context, token) {
    return new Promise((resolve, reject) => {
      axios.get(`/api/v1/auth/confirm_email/${token}`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  sendConfirmEmailLink(context, data) {
    return new Promise((resolve, reject) => {
      axios.post('/api/v1/auth/send_new_email_confirmation_link', data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  sendResetPasswordLink(context, data) {
    return new Promise((resolve, reject) => {
      axios.post('/api/v1/auth/send_reset_password_link', data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  setNewPassword(context, data) {
    return new Promise((resolve, reject) => {
      axios.put('/api/v1/auth/reset_password', data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
