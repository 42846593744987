<template>
  <div v-if="links" class="profile-menu">
    <router-link
      v-for="(link, index) in links"
      :key="index"
      :to="{ name: link.routeName }"
      class="profile-menu__link"
    >
      {{ link.label }}
    </router-link>
  </div>
</template>

<script>
export default {
  name: 'ProfileMenu',
  props: {
    links: {
      type: Array,
      required: true,
    },
  },
};
</script>
