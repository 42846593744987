<template>
  <div class="custom-container not-found-wrapper">
    <div class="not-found__title">
      The requested page wasn't found :(
    </div>
    <router-link
      v-if="prevRoute"
      :to="prevRoute.path"
      class="icon-label not-found__link"
    >
      <chevron-left-svg />
      <span>Back to previous page</span>
    </router-link>
  </div>
</template>

<script>
import ChevronLeftSvg from '@/components/svg/ChevronLeftSvg.vue';

export default {
  name: 'NotFound',
  components: {
    ChevronLeftSvg,
  },
  data() {
    return {
      prevRoute: null,
    };
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.prevRoute = from;
    });
  },
};
</script>

<style lang="scss" scoped>
@import 'src/assets/sass/views/404.scss';
</style>
