<template>
  <div v-if="candidate" class="candidate">
    <div class="candidate-wrapper">
      <div v-if="fullInfo" class="candidate-header">
        <div class="candidate__name">{{ candidate.full_name }}</div>
        <div class="candidate__update-time">Last update: {{ candidate.last_update }}</div>
      </div>

      <div class="candidate-body" :class="{ 'candidate-body--with-image' : fullInfo }">
        <div v-if="fullInfo" class="candidate-image">
          <img
            :src="candidate.photo_url"
            :alt="candidate.full_name"
            class="fit-image">
        </div>

        <div class="candidate-info">
          <div class="candidate-info__item">
            <div>Candidate ID</div>
            <div>
              <b>{{ candidate.id }}</b>
            </div>
          </div>

          <div class="candidate-info__item">
            <div>Job Title</div>
            <div>
              <b>{{ jobTitles }}</b>
            </div>
          </div>

          <div class="candidate-info__item">
            <div>Experience</div>
            <div>
              <b>{{ candidate.years_of_experience }}</b>
            </div>
          </div>

          <div class="candidate-info__item">
            <div>Degree</div>
            <div>
              <b>{{ undergraduateDegrees }}</b>
            </div>
          </div>
        </div>

        <div class="candidate-text">
          {{ candidate.summary }}
        </div>

        <div class="candidate-footer">
          <div class="candidate-materials">
            <button
              class="icon-label icon-label--small candidate-profile-button"
              @click="getCandidateProfile(candidate)"
            >
              <eye-svg />
              <span>View Profile</span>
            </button>

            <a
              v-if="fullInfo"
              :href="candidate.cv_url"
              target="_blank"
              class="icon-label icon-label--small"
            >
              <file-text-svg />
              <span>View Resume</span>
            </a>

            <button
              v-if="fullInfo && candidate.video_interview_link"
              class="icon-label icon-label--small"
              @click="getVideoInterview"
            >
              <youtube-svg />
              <span>Video Interview</span>
            </button>

            <a
              v-if="fullInfo"
              :href="candidate.linkedin_link"
              target="_blank"
              class="icon-label icon-label--small"
            >
              <linkedin-svg />
              <span>Linkedin Profile</span>
            </a>
          </div>

          <div class="candidate-agency">
            {{ candidate.agency_name }}
          </div>
        </div>

        <info-block v-if="requestInfo && requestInfo.error_message" type="error">
          {{ requestInfo.error_message }} <br />
          <template v-if="requestInfo.message">
            Message from the Agency: "{{ requestInfo.message }}"
          </template>
        </info-block>

        <info-block v-if="requestInfo && requestInfo.warning_message" type="error">
          {{ requestInfo.warning_message }}
        </info-block>
      </div>
    </div>

    <div class="candidate-sidebar">
      <template v-if="requestInfo">
        <template v-if="requestInfo.status === 'requested'">
          <div class="candidate-status candidate-status--pending">
            <div class="candidate-status__icon">
              <clock-svg width="24px" height="24px" color="#DEC0A6" />
            </div>

            <div class="candidate-status__title">
              Pending Resume
              <span class="d-md-none">
                ({{ requestInfo.timestamp_string }})
              </span>
            </div>

            <div class="candidate-status__text d-none d-md-block">
              Request sent {{ requestInfo.timestamp_string }}
            </div>

            <template
              v-if="(page === 'all' && searchId === requestInfo.search.id) || page === 'requested'"
            >
              <button
                class="icon-label icon-label--small candidate-cancel-request"
                @click="deleteRequest"
              >
                <stop-svg />
                <span>Cancel request</span>
              </button>
            </template>

            <template v-else>
              <div class="candidate-status__text">
                in search
                <router-link
                  class="link-regular"
                  :to="{ name: 'SearchRequestedCandidates', params: { id: requestInfo.search.id } }"
                >
                  "{{ requestInfo.search.name }}"
                </router-link>
              </div>
            </template>

            <button
              v-if="requestInfo.warning_message"
              class="button button--small candidate-repeat-request"
              @click="createRequest"
            >
              Repeat Request
            </button>
          </div>
        </template>

        <template v-if="requestInfo.status === 'declined'">
          <div class="candidate-status candidate-status--declined">
            <div class="candidate-status__icon">
              <stop-svg width="24px" height="24px" color="#FF524A" />
            </div>
            <div class="candidate-status__title">
              Request declined
              <span class="d-md-none">
                ({{ requestInfo.timestamp_string }})
              </span>
            </div>
            <div class="candidate-status__text d-none d-md-block">
              Declined {{ requestInfo.timestamp_string }}
            </div>
          </div>
        </template>

        <template v-if="requestInfo.status === 'approved'">
          <div
            v-if="requestInfo.in_touch"
            class="candidate-status candidate-status--in-touch"
          >
            <div class="candidate-status__icon">
              <check-circle-svg width="24px" height="24px" color="#B0886D" />
            </div>

            <div class="candidate-status__title">In touch with the agency</div>

            <div class="candidate-status__text">{{ requestInfo.timestamp_string }}</div>
          </div>

          <div v-else class="candidate-status candidate-status--received">
            <button
              class="button button--small candidate-contact-agency"
              @click="contactAgency"
            >
              Contact Agency
            </button>

            <div class="candidate-status__text">{{ requestInfo.timestamp_string }}</div>
          </div>
        </template>
      </template>

      <template v-else>
        <button
          class="button button--small candidate-resume-button"
          @click="createRequest"
        >
          Request Resume
        </button>

        <button
          v-if="candidate.is_saved"
          class="icon-label icon-label--small candidate-delete-button"
          @click="deleteCandidateFromSaved"
        >
          <trash-svg />
          <span>Delete from saved</span>
        </button>

        <button
          v-else
          class="icon-label icon-label--small candidate-save-button"
          @click="addCandidateToSaved"
        >
          <save-svg />
          <span>Save Candidate</span>
        </button>
      </template>
    </div>

    <candidate-profile
      v-if="candidateProfile"
      :profileType="this.fullInfo ? 'employer-full' : 'employer-short'"
      :profile="candidateProfile"
      :modalVisibility="candidateProfileModal"
      @onVisibilityChange="changeProfileModalVisibility"
      @onCreateRequest="createRequest"
      @onContactAgency="contactAgency"
    ></candidate-profile>

    <b-modal
      id="video-modal"
      ref="video-modal"
      modal-class="candidate-video-modal"
      size="xl"
      title="Video Interview"
      centered
    >
      <template slot="modal-header-close">
        <cross-svg />
      </template>

      <div
        v-if="candidate.video_interview_link"
        v-html="embedVideoInterview"
        class="video-wrapper"
      ></div>

      <template slot="modal-footer">
        <div></div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { BModal } from 'bootstrap-vue';
import CandidateProfile from '@/components/candidate/CandidateProfileModal.vue';
import InfoBlock from '@/components/utils/InfoBlock.vue';
import EyeSvg from '@/components/svg/EyeSvg.vue';
import SaveSvg from '@/components/svg/SaveSvg.vue';
import StopSvg from '@/components/svg/StopSvg.vue';
import ClockSvg from '@/components/svg/ClockSvg.vue';
import TrashSvg from '@/components/svg/TrashSvg.vue';
import FileTextSvg from '@/components/svg/FileTextSvg.vue';
import YoutubeSvg from '@/components/svg/YoutubeSvg.vue';
import LinkedinSvg from '@/components/svg/LinkedinSvg.vue';
import CrossSvg from '@/components/svg/CrossSvg.vue';
import CheckCircleSvg from '@/components/svg/CheckCircleSvg.vue';

export default {
  name: 'CandidatePreview',
  props: {
    candidate: {
      type: Object,
      required: true,
    },
    searchId: {
      type: Number,
      default: 0,
    },
    fullInfo: {
      type: Boolean,
      default: false,
    },
    page: {
      type: String,
      required: true,
    },
  },
  components: {
    BModal,
    CandidateProfile,
    InfoBlock,
    EyeSvg,
    SaveSvg,
    StopSvg,
    ClockSvg,
    TrashSvg,
    FileTextSvg,
    YoutubeSvg,
    LinkedinSvg,
    CrossSvg,
    CheckCircleSvg,
  },
  data() {
    return {
      candidateProfileModal: false,
      candidateProfile: null,
      embedVideoInterview: null,
    };
  },
  computed: {
    requestInfo() {
      return (this.candidate && this.candidate.request_info) ? this.candidate.request_info : null;
    },
    jobTitles() {
      if (this.candidate && this.candidate.job_titles) {
        return this.candidate.job_titles.join(', ');
      }

      return 'No data';
    },
    undergraduateDegrees() {
      if (this.candidate && this.candidate.undergraduate_degrees) {
        return this.candidate.undergraduate_degrees.join(', ');
      }

      return 'No data';
    },
  },
  methods: {
    getVideoInterview() {
      const videoLink = this.candidate.video_interview_link;
      let embedVideo = '<p>Video not found!</p>';

      if (videoLink.match(/vimeo\.com/ig)) {
        this.$store.dispatch('video/getVimeoVideoInfo', videoLink)
          .then((response) => {
            embedVideo = response.data.html;
          })
          .finally(() => {
            this.embedVideoInterview = embedVideo;
            this.$refs['video-modal'].show();
          });
      } else if (videoLink.match(/youtu\.be|www\.youtube\.com/ig)) {
        this.$store.dispatch('video/getYoutubeVideoInfo', videoLink)
          .then((response) => {
            embedVideo = response.data.html;
          })
          .finally(() => {
            this.embedVideoInterview = embedVideo;
            this.$refs['video-modal'].show();
          });
      }
    },
    getCandidateProfile(candidate) {
      this.$store.dispatch('candidate/getCandidate', {
        candidateId: candidate.id,
        data: {
          params: {
            level: this.fullInfo ? 2 : 1,
          },
        },
      })
        .then((response) => {
          this.candidateProfile = response.data;

          if (this.requestInfo) {
            this.candidateProfile.request_info = this.requestInfo;
          }

          this.$nextTick(() => this.changeProfileModalVisibility(true));
        })
        .catch((error) => {
          window.flash(error.response.data.message, 'error');
        });
    },
    changeProfileModalVisibility(visibility) {
      this.candidateProfileModal = visibility;
    },
    createRequest() {
      this.$emit('onCreateRequest');
    },
    deleteRequest() {
      this.$emit('onDeleteRequest');
    },
    addCandidateToSaved() {
      this.$emit('onAddCandidateToSaved');
    },
    deleteCandidateFromSaved() {
      this.$emit('onDeleteCandidateFromSaved');
    },
    contactAgency() {
      this.$emit('onContactAgency');
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/assets/sass/views/candidate/candidate-preview.scss";
</style>
