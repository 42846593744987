<template>
  <div class="custom-container">
    <div class="search-header">
      <vue-slick-carousel
        :key="searches.length"
        :class="{ 'no-buttons' : !showSliderButtons }"
        ref="links-slider"
        v-if="searches.length"
        v-bind="sliderSettings"
      >
        <template #prevArrow>
          <div class="search-links-button search-links-button--left">
            <chevron-left-svg width="24px" height="24px" color="#FFFFFF" />
          </div>
        </template>

        <router-link
          v-for="(search, index) in searches"
          :key="index"
          :to="{
            name: 'Search',
            params: { id: search.id },
          }"
          class="search-link"
        >
          <span class="search-link__name" :title="search.name">
            {{ search.name }}
          </span>
          <span class="search-link__job" :title="search.job_function.name">
            {{ search.job_function.name }}
          </span>
        </router-link>

        <template #nextArrow>
          <div class="search-links-button search-links-button--right">
            <chevron-right-svg width="24px" height="24px" color="#FFFFFF" />
          </div>
        </template>
      </vue-slick-carousel>

      <button
        @click="addSearch"
        class="button search-button"
      >
        Start a new search
      </button>
    </div>

    <div class="search-menu">
      <router-link
        :to="{
          name: 'SearchAllCandidates',
          params: { id: $route.params.id },
        }"
        class="search-menu__link"
      >
        <span>All <span class="d-none d-md-inline">Candidates</span></span>
        <span class="custom-badge">
          {{ candidateNumbers.all }}
        </span>
      </router-link>

      <router-link
        :to="{
          name: 'SearchSavedCandidates',
          params: { id: $route.params.id },
        }"
        class="search-menu__link"
      >
        <span>Saved <span class="d-none d-md-inline">Candidates</span></span>
        <span class="custom-badge">
          {{ candidateNumbers.saved }}
        </span>
      </router-link>

      <router-link
        :to="{
          name: 'SearchRequestedCandidates',
          params: { id: $route.params.id },
        }"
        class="search-menu__link"
      >
        <span>Requested <span class="d-none d-md-inline">Candidates</span></span>
        <span class="custom-badge">
          {{ candidateNumbers.requested }}
        </span>
      </router-link>
    </div>

    <router-view :key="$route.fullPath" />

    <b-modal
      size="md"
      title="New Search"
      v-model="searchModalStatus"
      centered
      @hidden="clearSearchForm"
    >
      <template slot="modal-header-close">
        <cross-svg />
      </template>

      <b-form @submit.prevent="createSearch">
        <b-form-group
          id="nameGroup"
          label-for="name"
          :state="validateState('name')"
          invalid-feedback="This is a required field and must be at least 2 characters."
        >
          <template slot="label">
            Search Name <span class="text-danger">*</span>
          </template>
          <b-form-input
            id="name"
            type="text"
            v-model="$v.form.name.$model"
            :state="validateState('name')"
          ></b-form-input>
        </b-form-group>

        <b-form-group
          id="jobFunctionGroup"
          label-for="jobFunction"
          invalid-feedback="Please, select at least one."
          :state="validateState('job_function_id')"
        >
          <template slot="label">
            Job function <span class="text-danger">*</span>
          </template>

          <multiselect
            id="industry"
            class="custom-multiselect"
            placeholder="— Select —"
            v-model="form.job_function_id"
            :options="jobFunctionOptions.map(option => option.id)"
            :custom-label="opt => jobFunctionOptions.find(x => x.id === opt).name">
            @close="$v.form.job_function_id.$touch()"
            ></multiselect>
        </b-form-group>
      </b-form>

      <template slot="modal-footer">
        <button
          class="action-label action-label--dark"
          @click="changeSearchModalStatus(false)"
        >
          Cancel
        </button>

        <button
          class="button"
          @click="createSearch"
        >
          Start new Search
        </button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import {
  BModal, BForm, BFormGroup, BFormInput,
} from 'bootstrap-vue';
import { validationMixin } from 'vuelidate';
import { required, minLength } from 'vuelidate/lib/validators';
import Multiselect from 'vue-multiselect';
import VueSlickCarousel from 'vue-slick-carousel';
import CrossSvg from '@/components/svg/CrossSvg.vue';
import ChevronLeftSvg from '@/components/svg/ChevronLeftSvg.vue';
import ChevronRightSvg from '@/components/svg/ChevronRightSvg.vue';

import debounce from '@/mixins/debounce';

export default {
  name: 'SearchWrapper',
  components: {
    BModal,
    BForm,
    BFormGroup,
    BFormInput,
    Multiselect,
    VueSlickCarousel,
    CrossSvg,
    ChevronLeftSvg,
    ChevronRightSvg,
  },
  mixins: [validationMixin, debounce],
  data() {
    return {
      searches: [],
      form: {
        name: null,
        job_function_id: null,
      },
      searchModalStatus: false,
      jobFunctionOptions: [],
      windowWidth: 0,
      sliderSettings: {
        arrows: true,
        slidesToShow: 4,
        draggable: false,
        swipe: false,
        infinite: false,
        initialSlide: 0,
        responsive: [
          {
            breakpoint: 1199,
            settings: {
              slidesToShow: 3,
            },
          },
          {
            breakpoint: 991,
            settings: {
              slidesToShow: 2,
            },
          },
          {
            breakpoint: 567,
            settings: {
              slidesToShow: 1,
            },
          },
        ],
      },
    };
  },
  validations: {
    form: {
      name: {
        required,
        minLength: minLength(2),
      },
      job_function_id: {
        required,
      },
    },
  },
  computed: {
    searchId() {
      return parseInt(this.$route.params.id, 10);
    },
    showSliderButtons() {
      if (this.windowWidth >= 1200) {
        return this.searches.length > 4;
      }

      if (this.windowWidth >= 992) {
        return this.searches.length > 3;
      }

      if (this.windowWidth >= 568) {
        return this.searches.length > 2;
      }

      return this.searches.length > 1;
    },
    candidateNumbers() {
      return {
        all: this.$store.state.search.allCandidatesCount,
        saved: this.$store.state.search.savedCandidatesCount,
        requested: this.$store.state.search.requestedCandidatesCount,
      };
    },
  },
  methods: {
    getSearches() {
      this.$store.dispatch('search/getSearches')
        .then((response) => {
          this.countSearchesSlidesPosition(response.data.searches);

          this.searches = response.data.searches;

          this.setCandidatesCount();
        })
        .catch((error) => {
          window.flash(error.response.data.message, 'error');
        });
    },
    countSearchesSlidesPosition(searchesList) {
      const searchesCount = searchesList.length;

      let slidesCount;

      if (this.windowWidth >= 1200) {
        slidesCount = 4;
      } else if (this.windowWidth >= 992) {
        slidesCount = 3;
      } else if (this.windowWidth >= 768) {
        slidesCount = 2;
      } else {
        slidesCount = 1;
      }

      const lastPosition = searchesCount - slidesCount;

      const activeSearchIndex = searchesList
        .findIndex((search) => search.id === this.searchId);

      if (activeSearchIndex > lastPosition) {
        this.sliderSettings.initialSlide = lastPosition;
      } else {
        this.sliderSettings.initialSlide = activeSearchIndex;
      }
    },
    getJobFunctionOptions() {
      return new Promise((resolve, reject) => {
        this.$store.dispatch('search/getJobFunctionOptions')
          .then((response) => {
            this.jobFunctionOptions = response.data.job_functions;
            resolve();
          })
          .catch((error) => {
            window.flash(error.response.data.message, 'error');
            reject();
          });
      });
    },
    changeSearchModalStatus(status) {
      this.searchModalStatus = status;
    },
    addSearch() {
      if (!this.jobFunctionOptions || !this.jobFunctionOptions.length) {
        this.getJobFunctionOptions()
          .then(() => {
            this.changeSearchModalStatus(true);
          });
      } else {
        this.changeSearchModalStatus(true);
      }
    },
    clearSearchForm() {
      this.$v.form.$reset();

      this.form = {
        name: null,
        job_function_id: null,
      };
    },
    createSearch() {
      this.$v.form.$reset();
      this.$v.form.$touch();

      if (!this.$v.form.$anyError) {
        this.$store.dispatch('search/createSearch', this.form)
          .then((response) => {
            window.flash('Search successfully created!', 'success');
            this.changeSearchModalStatus(false);

            this.searches.unshift(response.data);
            this.$router.push({ name: 'Search', params: { id: response.data.id } });

            this.countSearchesSlidesPosition(this.searches);
          })
          .catch((error) => {
            window.flash(error.response.data.message, 'error');
          });
      }
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    getWindowWidth() {
      this.debounce(() => {
        this.windowWidth = window.innerWidth;
      });
    },
    setCandidatesCount() {
      const currentSearch = this.searches
        .filter((search) => search.id === this.searchId)[0];

      this.$store.commit(
        'search/setAllCandidatesCount',
        currentSearch.number_of_candidates,
      );

      this.$store.commit(
        'search/setSavedCandidatesCount',
        currentSearch.number_of_saved_candidates,
      );

      this.$store.commit(
        'search/setRequestedCandidatesCount',
        currentSearch.number_of_requested_candidates,
      );
    },
  },
  watch: {
    searchId() {
      this.getSearches();
    },
  },
  created() {
    this.getSearches();
  },
  mounted() {
    this.windowWidth = window.innerWidth;

    window.addEventListener('resize', this.getWindowWidth);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.getWindowWidth);
  },
};
</script>

<style lang="scss" scoped>
@import "src/assets/sass/views/employer/search-wrapper.scss";
</style>

<style lang="css">
@import '~vue-slick-carousel/dist/vue-slick-carousel.css';
</style>
