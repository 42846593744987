import axios from 'axios';

const actions = {
  saveCandidate(context, data) {
    return new Promise((resolve, reject) => {
      axios.post('/api/v1/candidates/', data, {
        headers: { 'content-type': 'multipart/form-data' },
      })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getCandidate(context, { candidateId, data }) {
    return new Promise((resolve, reject) => {
      axios.get(`/api/v1/candidates/${candidateId}`, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getCandidateOptions() {
    return new Promise((resolve, reject) => {
      axios.get('/api/v1/searches/filters')
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  updateCandidate(context, { candidateId, data }) {
    return new Promise((resolve, reject) => {
      axios.post(`/api/v1/candidates/${candidateId}`, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  deleteCandidate(context, candidateId) {
    return new Promise((resolve, reject) => {
      axios.delete(`/api/v1/candidates/${candidateId}`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  addCandidateToSaved(context, data) {
    return new Promise((resolve, reject) => {
      axios.post(`/api/v1/searches/${data.searchId}/saved_candidates/${data.candidateId}`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  deleteCandidateFromSaved(context, data) {
    return new Promise((resolve, reject) => {
      axios.delete(`/api/v1/searches/${data.searchId}/saved_candidates/${data.candidateId}`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  confirmCandidate(context, token) {
    return new Promise((resolve, reject) => {
      axios.get(`/api/v1/candidates/confirm/${token}`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

export default {
  namespaced: true,
  actions,
};
