<template>
  <div class="custom-container">
    <candidate-form
      form-type="create"
      @onCreate="openConfirmCreateModal"
    />

    <b-modal
      size="lg"
      v-model="confirmCreateModal"
      title="Submit Candidate"
      centered
      no-stacking
    >
      <template slot="modal-header-close">
        <cross-svg />
      </template>

      <p>
        By clicking SUBMIT, you understand that your candidate will be emailed
        for approval before being submitted into TheVault. You also promise that
        you have interviewed and approved the quality of this candidate.
        You understand that you will be rated by companies who are also members
        of TheVault. These ratings will be based on candidate quality, you honest
        representation of candidate quality and your performance as a recruiter.
      </p>

      <template slot="modal-footer">
        <button
          class="action-label action-label--dark"
          @click="closeConfirmCreateModal"
        >
          Cancel
        </button>
        <button
          @click="createCandidate"
          class="button"
        >
          Submit
        </button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { BModal } from 'bootstrap-vue';
import CandidateForm from '@/components/candidate/CandidateForm.vue';
import CrossSvg from '@/components/svg/CrossSvg.vue';

export default {
  name: 'SubmitCandidate',
  components: {
    BModal,
    CandidateForm,
    CrossSvg,
  },
  data() {
    return {
      confirmCreateModal: false,
      candidateData: null,
    };
  },
  methods: {
    openConfirmCreateModal(data) {
      this.candidateData = data;
      this.confirmCreateModal = true;
    },
    closeConfirmCreateModal() {
      this.candidateData = false;
      this.confirmCreateModal = false;
    },
    createCandidate() {
      const formData = new FormData();
      const data = { ...this.candidateData };

      delete data.cv;
      delete data.photo;

      formData.append('data', JSON.stringify(data));
      formData.append('cv', this.candidateData.cv.file);
      formData.append('photo', this.candidateData.photo.file);

      this.$store.dispatch('candidate/saveCandidate', formData)
        .then(() => {
          window.flash('Candidate successfully created!', 'success');
          this.$router.push({ name: 'MyCandidates' });
        })
        .catch((error) => {
          let errorText = '';

          if (error.response) {
            if (error.response.data.message) {
              errorText = error.response.data.message;
            } else {
              errorText = error.response.data;
            }
          }

          window.flash(errorText, 'error');
        })
        .finally(() => {
          this.closeConfirmCreateModal();
        });
    },
  },
};
</script>
