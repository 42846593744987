<template>
  <b-form class="auth-form" @submit.prevent="submitForm">
    <b-form-group
      id="emailGroup"
      label-for="email"
      invalid-feedback="This is a required field and must be email."
    >
      <template slot="label">
        Email <span class="text-danger">*</span>
      </template>
      <b-form-input
        id="email"
        type="email"
        v-model="$v.form.email.$model"
        :state="validateState('email')"
      ></b-form-input>
    </b-form-group>

    <b-form-group
      id="passwordGroup"
      label-for="password"
      invalid-feedback="This is a required field."
    >
      <template slot="label">
        Password <span class="text-danger">*</span>
      </template>
      <b-form-input
        id="password"
        type="password"
        v-model="$v.form.password.$model"
        :state="validateState('password')"
      ></b-form-input>
    </b-form-group>

    <div class="form-buttons">
      <button
        class="button"
        type="submit"
      >
        Sign In
      </button>
      <router-link
        :to="{ name: 'ResetPassword', params: { type: type } }"
        class="action-label action-label--dark"
      >
        Reset Password
      </router-link>
    </div>
  </b-form>
</template>

<script>
import { BForm, BFormGroup, BFormInput } from 'bootstrap-vue';
import { validationMixin } from 'vuelidate';
import { email, required } from 'vuelidate/lib/validators';

export default {
  name: 'SignInForm',
  components: {
    BForm,
    BFormGroup,
    BFormInput,
  },
  props: {
    type: {
      type: String,
      required: true,
    },
  },
  mixins: [validationMixin],
  data() {
    return {
      form: {
        email: null,
        password: null,
      },
    };
  },
  validations: {
    form: {
      email: {
        required,
        email,
      },
      password: {
        required,
      },
    },
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    submitForm() {
      this.$v.form.$reset();
      this.$v.form.$touch();

      if (!this.$v.form.$anyError) {
        this.$emit('onSubmit', this.form);
      }
    },
  },
};
</script>
