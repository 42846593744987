import axios from 'axios';

const state = {
  modalStatus: false,
};

const getters = {};

const mutations = {
  changeModalStatus(e, status) {
    e.modalStatus = status;
  },
};

const actions = {
  getAgencyOptions() {
    return new Promise((resolve, reject) => {
      axios.get('/api/v1/agencies/options')
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getAgencyProfile(context, agencyId) {
    return new Promise((resolve, reject) => {
      axios.get(`/api/v1/agencies/${agencyId}/profile`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  updateAgencyProfile(context, data) {
    return new Promise((resolve, reject) => {
      axios.put(`/api/v1/agencies/${data.agencyId}/profile`, data.agencyData)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getCandidates(context, agencyId) {
    return new Promise((resolve, reject) => {
      axios.get(`/api/v1/agencies/${agencyId}/candidates`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getRequestedCandidates(context, data) {
    return new Promise((resolve, reject) => {
      axios.get('/api/v1/agencies/requested_candidates', data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
