<template>
  <div>
    <candidates-preview-list
      page="requested"
      :candidates="candidates"
      :searchId="searchId"
    />
  </div>
</template>

<script>
import CandidatesPreviewList from '@/components/candidate/CandidatesPreviewList.vue';

export default {
  name: 'SearchRequestedCandidates',
  components: {
    CandidatesPreviewList,
  },
  data() {
    return {
      candidates: [],
    };
  },
  computed: {
    searchId() {
      return parseInt(this.$route.params.id, 10);
    },
  },
  methods: {
    getRequestedCandidates() {
      this.$store.dispatch('search/getRequestedCandidates', parseInt(this.$route.params.id, 10))
        .then((response) => {
          this.candidates = response.data.candidates;
        })
        .catch((error) => {
          window.flash(error.response.data.message, 'error');
        });
    },
  },
  created() {
    this.getRequestedCandidates();
  },
};
</script>

<style lang="scss" scoped>
</style>
