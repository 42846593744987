<template>
  <!-- eslint-disable -->
  <svg :width="width" :height="height" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g class="fill" transform="translate(-160.000000, -4000.000000)" :fill="color">
        <g transform="translate(0.000000, 3920.000000)">
          <g transform="translate(80.000000, 80.000000)">
            <g transform="translate(80.000000, 0.000000)">
              <path d="M15.7071068,18.7071068 C16.0675907,18.3466228 16.0953203,17.7793918 15.7902954,17.3871006 L15.7071068,17.2928932 L10.415,12 L15.7071068,6.70710678 C16.0675907,6.34662282 16.0953203,5.77939176 15.7902954,5.38710056 L15.7071068,5.29289322 C15.3466228,4.93240926 14.7793918,4.90467972 14.3871006,5.20970461 L14.2928932,5.29289322 L8.29289322,11.2928932 C7.93240926,11.6533772 7.90467972,12.2206082 8.20970461,12.6128994 L8.29289322,12.7071068 L14.2928932,18.7071068 C14.6834175,19.0976311 15.3165825,19.0976311 15.7071068,18.7071068 Z"></path>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
  <!-- eslint-enable -->
</template>

<script>
export default {
  name: 'ChevronLeftSvg',
  props: {
    width: {
      type: String,
      default: '16px',
    },
    height: {
      type: String,
      default: '16px',
    },
    color: {
      type: String,
      default: '#000000',
    },
  },
};
</script>
