<template>
  <b-modal
    modal-class="landing-modal landing-modal--with-form"
    size="lg"
    ref="connect-now"
    title="Connect Now"
    :visible="modalStatus"
    no-close-on-backdrop
    centered
    no-stacking
    @change="modalStatusChange"
    @hidden="clearConnectForm"
  >
    <template slot="modal-header-close">
      <cross-svg />
    </template>

    <info-block type="primary">
      Please fill in all fields and upload your resume. Your information will ONLY
      be visible to the agencies and will NOT be imported to TheVault without
      your approval through email.
    </info-block>

    <b-form @submit.prevent="submitConnectForm">
      <div class="form-row">
        <b-form-group
          id="firstNameGroup"
          label-for="firstName"
          invalid-feedback="This is a required field and must be at least 2 characters."
        >
          <template slot="label">
            First Name <span class="text-danger">*</span>
          </template>
          <b-form-input
            id="firstName"
            type="text"
            v-model="$v.form.first_name.$model"
            :state="validateState('first_name')"
          ></b-form-input>
        </b-form-group>

        <b-form-group
          id="lastNameGroup"
          label-for="lastName"
          invalid-feedback="This is a required field and must be at least 2 characters."
        >
          <template slot="label">
            Last Name <span class="text-danger">*</span>
          </template>
          <b-form-input
            id="lastName"
            type="text"
            v-model="$v.form.last_name.$model"
            :state="validateState('last_name')"
          ></b-form-input>
        </b-form-group>
      </div>

      <div class="form-row">
        <b-form-group
          id="contactEmailGroup"
          label-for="contactEmail"
          invalid-feedback="This is a required field and must be email."
        >
          <template slot="label">
            Contact Email <span class="text-danger">*</span>
          </template>
          <b-form-input
            id="contactEmail"
            type="email"
            v-model="$v.form.contact_email.$model"
            :state="validateState('contact_email')"
          ></b-form-input>
        </b-form-group>

        <b-form-group
          id="contactPhoneGroup"
          label-for="contactPhone"
          invalid-feedback="This is a required field and must be valid phone number (XXX-XXX-XXXX)."
        >
          <template slot="label">
            Contact Phone <span class="text-danger">*</span>
          </template>
          <b-form-input
            id="contactPhone"
            type="tel"
            v-mask="'###-###-####'"
            placeholder="XXX-XXX-XXXX"
            v-model="$v.form.contact_phone.$model"
            :state="validateState('contact_phone')"
          ></b-form-input>
        </b-form-group>
      </div>

      <div class="form-row">
        <b-form-group
          id="HomeCityGroup"
          label-for="HomeCity"
          invalid-feedback="This is a required field and must be at least 2 characters."
        >
          <template slot="label">
            Home City <span class="text-danger">*</span>
          </template>
          <b-form-input
            id="HomeCity"
            type="email"
            v-model="$v.form.home_city.$model"
            :state="validateState('home_city')"
          ></b-form-input>
        </b-form-group>

        <b-form-group
          id="stateGroup"
          label-for="state"
          invalid-feedback="This is a required field and must be at least 2 characters."
        >
          <template slot="label">
            State <span class="text-danger">*</span>
          </template>
          <b-form-input
            id="state"
            type="tel"
            v-model="$v.form.state.$model"
            :state="validateState('state')"
          ></b-form-input>
        </b-form-group>
      </div>

      <b-form-group
        id="jobFunctionGroup"
        label-for="jobFunction"
        invalid-feedback="Please, select at least one."
        :state="validateState('job_function_id')"
      >
        <template slot="label">
          Job function <span class="text-danger">*</span>
        </template>

        <multiselect
          id="industry"
          class="custom-multiselect"
          placeholder="— Select —"
          v-model="form.job_function_id"
          :options="jobFunctionOptions.map(option => option.id)"
          :custom-label="opt => jobFunctionOptions.find(x => x.id === opt).name">
          @close="$v.form.job_function_id.$touch()"
          ></multiselect>
      </b-form-group>

      <b-form-group
        id="createResumeFileGroup"
        invalid-feedback="This is a required field and file type must be PDF, DOC, DOCX."
        :state="validateState('cv')"
      >
        <form-file
          title="Upload Resume"
          :required="true"
          description="Without contact information."
          :allowed-types="['pdf, doc, docx']"
          @validate="$v.form.cv.$touch()"
          @change="value => form.cv = value"
        ></form-file>
      </b-form-group>
    </b-form>

    <template slot="modal-footer">
      <button
        class="action-label action-label--dark"
        @click="modalStatusChange(false)"
      >
        Cancel
      </button>
      <button
        class="button"
        @click="submitConnectForm"
      >
        Submit
      </button>
    </template>
  </b-modal>
</template>

<script>
import {
  BModal, BForm, BFormGroup, BFormInput,
} from 'bootstrap-vue';
import { validationMixin } from 'vuelidate';
import { required, minLength, email } from 'vuelidate/lib/validators';
import Multiselect from 'vue-multiselect';
import { mask } from 'vue-the-mask';
import debounce from '@/mixins/debounce';
import FormFile from '@/components/utils/FormFile.vue';
import InfoBlock from '@/components/utils/InfoBlock.vue';
import CrossSvg from '@/components/svg/CrossSvg.vue';

export default {
  name: 'LandingConnectCandidateModal',
  props: {
    modalStatus: {
      type: Boolean,
      default: false,
    },
    jobFunctionOptions: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    BModal,
    BForm,
    BFormGroup,
    BFormInput,
    Multiselect,
    FormFile,
    InfoBlock,
    CrossSvg,
  },
  directives: { mask },
  mixins: [validationMixin, debounce],
  data() {
    return {
      form: {
        first_name: null,
        last_name: null,
        cv: null,
        contact_email: null,
        contact_phone: null,
        home_city: null,
        state: null,
        job_function_id: null,
      },
    };
  },
  validations: {
    form: {
      first_name: {
        required,
        minLength: minLength(2),
      },
      last_name: {
        required,
        minLength: minLength(2),
      },
      cv: {
        required,
        allowedType(value) {
          return value ? ['pdf', 'doc', 'docx'].includes(value.type) : false;
        },
      },
      contact_email: {
        required,
        email,
      },
      contact_phone: {
        required,
        minLength: minLength(12),
      },
      home_city: {
        required,
        minLength: minLength(2),
      },
      state: {
        required,
        minLength: minLength(2),
      },
      job_function_id: {
        required,
      },
    },
  },
  methods: {
    modalStatusChange(status) {
      this.$emit('onChangeModalStatus', status);
    },
    submitConnectForm() {
      this.$v.form.$reset();
      this.$v.form.$touch();

      if (!this.$v.form.$anyError) {
        this.$emit('onConnectCandidate', this.form);
      }
    },
    clearConnectForm() {
      const setProps = (obj) => Object.entries(obj).map((item) => {
        const [key, value] = item;
        let newValue = null;

        if (value === Object(value)) {
          if (!(value instanceof File)) {
            newValue = Object.fromEntries(setProps(value));
          }
        }

        return [key, newValue];
      });

      this.form = Object.fromEntries(setProps(this.form));
      this.$v.form.$reset();
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
  },
};
</script>
