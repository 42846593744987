<template>
  <div class="submit-candidate">
    <div class="submit-sidebar">
      <div class="submit-sidebar-header">
        <div class="submit-sidebar__title">Enter your Candidate into TheVault now!</div>
        <div class="submit-sidebar__text">
          The more complete your Candidate Profile is, the better your chances are of
          getting your top talent requested and hired.
        </div>
      </div>
      <div class="submit-sidebar-body">
        <a href="#section-1" v-smooth-scroll class="submit-anchor">
          <div class="submit-anchor__title">
            Personal Information
            <span class="text-danger">*</span>
          </div>
          <div class="submit-anchor__status">
            <template v-if="personalInformationStatus">
              <check-svg width="20px" height="20px" color="#5AC058" />
            </template>
          </div>
        </a>

        <a href="#section-2" v-smooth-scroll class="submit-anchor">
          <div class="submit-anchor__title">
            Title Sought <span class="text-danger">*</span>
          </div>
          <div class="submit-anchor__status">
            <template v-if="form.job_title_ids && form.job_title_ids.length">
              <div class="custom-badge">{{ form.job_title_ids.length }}</div>
            </template>
          </div>
        </a>

        <a href="#section-3" v-smooth-scroll class="submit-anchor">
          <div class="submit-anchor__title">
            Industry Experience <span class="text-danger">*</span>
          </div>
          <div class="submit-anchor__status">
            <template v-if="form.industry_ids && form.industry_ids.length">
              <div class="custom-badge">{{ form.industry_ids.length }}</div>
            </template>
          </div>
        </a>

        <a href="#section-4" v-smooth-scroll class="submit-anchor">
          <div class="submit-anchor__title">
            Years of experience <span class="text-danger">*</span>
          </div>
          <div class="submit-anchor__status">
            <template v-if="form.years_of_experience_id">
              <check-svg width="20px" height="20px" color="#5AC058" />
            </template>
          </div>
        </a>

        <a href="#section-5" v-smooth-scroll class="submit-anchor">
          <div class="submit-anchor__title">
            Undergraduate Degree <span class="text-danger">*</span>
          </div>
          <div class="submit-anchor__status">
            <template
              v-if="form.undergraduate_degree_ids
                && form.undergraduate_degree_ids.length"
            >
              <div class="custom-badge">{{ form.undergraduate_degree_ids.length }}</div>
            </template>
          </div>
        </a>

        <a href="#section-6" v-smooth-scroll class="submit-anchor">
          <div class="submit-anchor__title">
            Target Salary <span class="text-danger">*</span>
          </div>
          <div class="submit-anchor__status">
            <template v-if="form.target_salary_ids">
              <check-svg width="20px" height="20px" color="#5AC058" />
            </template>
          </div>
        </a>

        <a href="#section-7" v-smooth-scroll class="submit-anchor">
          <div class="submit-anchor__title">
            Software <span class="text-danger">*</span>
          </div>
          <div class="submit-anchor__status">
            <template v-if="form.software_ids && form.software_ids.length">
              <div class="custom-badge">{{ form.software_ids.length }}</div>
            </template>
          </div>
        </a>

        <a href="#section-8" v-smooth-scroll class="submit-anchor">
          <div class="submit-anchor__title">
            Additional experience
          </div>
          <div class="submit-anchor__status">
            <template
              v-if="form.additional_experience_ids
                && form.additional_experience_ids.length"
            >
              <div class="custom-badge">{{ form.additional_experience_ids.length }}</div>
            </template>
          </div>
        </a>

        <a href="#section-9" v-smooth-scroll class="submit-anchor">
          <div class="submit-anchor__title">
            Additional Information <span class="text-danger">*</span>
          </div>
          <div class="submit-anchor__status">
            <template v-if="additionalInformationStatus">
              <check-svg width="20px" height="20px" color="#5AC058" />
            </template>
          </div>
        </a>

      </div>
      <div class="submit-sidebar-footer">
        <div class="submit-progress">
          <div class="submit-progress__title">Profile completion level</div>
          <div class="submit-progress__number">{{ progressValue }}%</div>
          <div class="submit-progress__line">
            <span :style="'width:' + progressValue + '%;'"></span>
          </div>
        </div>
      </div>
    </div>

    <div class="submit-content">
      <b-form class="submit-form" @submit.prevent="submitForm">
        <div id="section-1" class="submit-section">
          <div class="submit-section__title">Candidate's Personal Information</div>

          <div class="submit-section-row">
            <div class="submit-section-form">
              <div class="form-row">
                <b-form-group
                  id="firstNameGroup"
                  label-for="firstName"
                  :state="validateState('first_name')"
                  invalid-feedback="This is a required field and must be at least 2 characters."
                >
                  <template slot="label">
                    First Name <span class="text-danger">*</span>
                  </template>
                  <b-form-input
                    id="firstName"
                    type="text"
                    v-model="$v.form.first_name.$model"
                    :state="validateState('first_name')"
                  ></b-form-input>
                </b-form-group>

                <b-form-group
                  id="lastNameGroup"
                  label-for="lastName"
                  :state="validateState('last_name')"
                  invalid-feedback="This is a required field and must be at least 2 characters."
                >
                  <template slot="label">
                    Last Name <span class="text-danger">*</span>
                  </template>
                  <b-form-input
                    id="lastName"
                    type="text"
                    v-model="$v.form.last_name.$model"
                    :state="validateState('last_name')"
                  ></b-form-input>
                </b-form-group>
              </div>

              <div class="form-row">
                <b-form-group
                  id="emailGroup"
                  label-for="email"
                  :state="validateState('email')"
                  invalid-feedback="This is a required field and must be email."
                >
                  <template slot="label">
                    Email <span class="text-danger">*</span>
                  </template>
                  <b-form-input
                    id="email"
                    type="text"
                    v-model="$v.form.email.$model"
                    :state="validateState('email')"
                  ></b-form-input>
                </b-form-group>

                <b-form-group
                  id="phoneGroup"
                  label-for="phone"
                  :state="validateState('phone')"
                  invalid-feedback="This is a required field and must be
                  valid phone number (XXX-XXX-XXXX)."
                >
                  <template slot="label">
                    Phone <span class="text-danger">*</span>
                  </template>
                  <b-form-input
                    id="phone"
                    type="tel"
                    v-mask="'###-###-####'"
                    placeholder="XXX-XXX-XXXX"
                    v-model="$v.form.phone.$model"
                    :state="validateState('phone')"
                  ></b-form-input>
                </b-form-group>
              </div>
            </div>

            <div class="submit-section-info">
              <info-block type="primary">
                <p>
                  City, State, "Sell your candidate" and Summary, are the only
                  "Personal Information" that will be Visible to Employers.
                </p>
                <p>
                  Once the Agency accepts the Employer's request, all other
                  information will be made public to the Employer
                  (except email and phone number).
                </p>
              </info-block>
            </div>
          </div>

          <div class="submit-section-row">
            <div class="submit-section-form">
              <template v-if="formType === 'update' && cv_url">
                <div class="submit-resume">
                  <div class="submit-resume__title">Resume file: </div>
                  <a
                    class="submit-resume__link link-regular"
                    :href="cv_url"
                    target="_blank"
                  >
                    {{ cv_file_name }}
                  </a>
                </div>

                <b-form-group
                  id="updateResumeFileGroup"
                  invalid-feedback="File type must be PDF, DOC, DOCX."
                  :state="validateState('cv')"
                >
                  <form-file
                    title="Change Resume"
                    description="Without contact information."
                    :allowed-types="['pdf, doc, docx']"
                    @validate="$v.form.cv.$touch()"
                    @change="value => form.cv = value"
                  ></form-file>
                </b-form-group>
              </template>

              <template v-else>
                <b-form-group
                  id="createResumeFileGroup"
                  invalid-feedback="This is a required field and file type must be PDF, DOC, DOCX."
                  :state="validateState('cv')"
                >
                  <form-file
                    title="Upload Resume"
                    :required="true"
                    description="Without contact information."
                    :allowed-types="['pdf, doc, docx']"
                    @validate="$v.form.cv.$touch()"
                    @change="value => form.cv = value"
                  ></form-file>
                </b-form-group>
              </template>
            </div>
          </div>

          <div class="submit-section-row">
            <div class="submit-section-form">
              <div class="form-row submit-address-row">
                <div class="submit-address-wrapper">
                  <div class="form-row">
                    <b-form-group
                      id="zipGroup"
                      label-for="zip"
                      :class="{
                        'is-invalid' : $v.form.zip_code.$error,
                        'is-valid' : $v.form.zip_code.$dirty && !$v.form.zip_code.$error
                      }"
                    >
                      <template slot="label">
                        ZIP <span class="text-danger">*</span>
                      </template>
                      <b-form-input
                        id="zip"
                        type="text"
                        :class="{
                          'is-invalid' : $v.form.zip_code.$error,
                          'is-valid' : $v.form.zip_code.$dirty && !$v.form.zip_code.$error
                        }"
                        v-model.lazy="form.zip_code"
                        @focus="$v.form.zip_code.$reset()"
                        @blur="$v.form.zip_code.$touch()"
                      ></b-form-input>
                    </b-form-group>

                    <b-form-group
                      id="stateGroup"
                      label-for="state"
                      :state="validateState('state')"
                    >
                      <template slot="label">
                        State <span class="text-danger">*</span>
                      </template>
                      <b-form-input
                        id="state"
                        type="text"
                        v-model="$v.form.state.$model"
                        :state="validateState('state')"
                      ></b-form-input>
                    </b-form-group>
                  </div>
                </div>

                <b-form-group
                  id="cityGroup"
                  label-for="city"
                  :state="validateState('city')"
                >
                  <template slot="label">
                    City <span class="text-danger">*</span>
                  </template>
                  <b-form-input
                    id="city"
                    type="text"
                    v-model="$v.form.city.$model"
                    :state="validateState('city')"
                  ></b-form-input>
                </b-form-group>

                <b-form-invalid-feedback
                  :state="validateAddress()"
                >
                  <div v-if="$v.form.zip_code.$error">
                    Zip code is required and should be valid.
                  </div>

                  <div v-if="$v.form.state.$error">
                    State is required and must be at least 2 characters.
                  </div>

                  <div v-if="$v.form.city.$error">
                    City is required and must be at least 2 characters.
                  </div>
                </b-form-invalid-feedback>
              </div>
            </div>
          </div>

          <div class="submit-section-row">
            <div class="submit-section-form">
              <b-form-group
                id="sellTextGroup"
                label-for="sellText"
                :state="validateState('sell_text')"
                invalid-feedback="This is a required field and must be at least 2 characters."
              >
                <template slot="label">
                  Sell your candidate to the employer in 1-3 sentences
                  <span class="text-danger">*</span>
                </template>
                <b-form-textarea
                  id="sellText"
                  rows="5"
                  max-rows="10"
                  :placeholder="inputPlaceholder"
                  v-model="$v.form.sell_text.$model"
                  :state="validateState('sell_text')"
                ></b-form-textarea>
              </b-form-group>
            </div>

            <div class="submit-section-info">
              <div class="submit-section-example">
                <div>
                  <b>As an Exmaple:</b>
                  <br>
                  “This Big 4 CPA is degreed in Accounting from USC and has two years
                  in audit at PWC and three years of industry accounting experience where
                  he is currently an accounting manager at a publicly traded
                  entertainment company.”
                </div>
              </div>
            </div>
          </div>

          <div class="submit-section-row">
            <div class="submit-section-form">
              <b-form-group
                id="summaryTextGroup"
                label-for="summaryText"
                :state="validateState('summary')"
                invalid-feedback="This is a required field and must be at least 2 characters."
              >
                <template slot="label">
                  Summary of what your Candidate is looking for
                  <span class="text-danger">*</span>
                </template>
                <b-form-textarea
                  id="summaryText"
                  rows="7"
                  max-rows="14"
                  :placeholder="inputPlaceholder"
                  v-model="$v.form.summary.$model"
                  :state="validateState('summary')"
                ></b-form-textarea>
              </b-form-group>
            </div>

            <div class="submit-section-info">
              <div class="submit-section-example">
                <div>
                  <b>As an Exmaple:</b>
                  <br>
                  “This candidate is looking to continue his accounting career in the Real Estate
                  industry where he can use his accounting degree and experience, MBA and strong
                  analytical skills to do more financial analysis. He is ideally looking for
                  work for a large private or publicly traded company."
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id="section-2" class="submit-section">
          <div class="submit-section__title">
            Title Sought
            <span class="text-danger">*</span>
            <br>
            <span class="submit-section__subtitle">Please select any that apply</span>
          </div>

          <div class="submit-section-row">
            <div class="submit-section-form">
              <b-form-group
                id="titleSoughtGroup"
                label-for="titleSought"
                invalid-feedback="Please, select at least one."
                :state="validateState('job_title_ids')"
              >
                <multiselect
                  id="titleSought"
                  class="custom-multiselect"
                  placeholder="— Select —"
                  v-model="form.job_title_ids"
                  :options="jobTitleOptions.map(option => option.id)"
                  :custom-label="opt => jobTitleOptions.find(x => x.id === opt).name"
                  :multiple="true"
                  :closeOnSelect="false"
                  @close="$v.form.job_title_ids.$touch()"
                >
                  <template slot="tag" slot-scope="props">
                    <div class="multiselect__tag">
                        <span>
                          {{ jobTitleOptions.find(x => x.id === props.option).name }}
                        </span>
                      <div
                        class="multiselect__tag-remove"
                        @mousedown.prevent="props.remove(props.option)"
                      >
                        <cross-svg />
                      </div>
                    </div>
                  </template>
                </multiselect>
              </b-form-group>
            </div>
          </div>
        </div>

        <div id="section-3" class="submit-section">
          <div class="submit-section__title">
            Industry Experience
            <span class="text-danger">*</span>
            <br>
            <span class="submit-section__subtitle">Please select any that apply</span>
          </div>

          <div class="submit-section-row">
            <div class="submit-section-form">
              <b-form-group
                id="industryGroup"
                label-for="industry"
                invalid-feedback="Please, select at least one."
                :state="validateState('industry_ids')"
              >
                <multiselect
                  id="industry"
                  class="custom-multiselect"
                  placeholder="— Select —"
                  v-model="form.industry_ids"
                  :options="industryOptions.map(option => option.id)"
                  :custom-label="opt => industryOptions.find(x => x.id === opt).name"
                  :multiple="true"
                  :closeOnSelect="false"
                  @close="$v.form.industry_ids.$touch()"
                >
                  <template slot="tag" slot-scope="props">
                    <div class="multiselect__tag">
                        <span>
                          {{ industryOptions.find(x => x.id === props.option).name }}
                        </span>
                      <div
                        class="multiselect__tag-remove"
                        @mousedown.prevent="props.remove(props.option)"
                      >
                        <cross-svg />
                      </div>
                    </div>
                  </template>
                </multiselect>
              </b-form-group>
            </div>
          </div>
        </div>

        <div id="section-4" class="submit-section">
          <div class="submit-section__title">
            Years of experience
            <span class="text-danger">*</span>
          </div>

          <div class="submit-section-row">
            <div class="submit-section-form">
              <b-form-group
                id="experienceGroup"
                :state="validateState('years_of_experience_id')"
                label-for="experience"
                invalid-feedback="This is a required field"
              >
                <b-form-radio-group
                  id="experience"
                  name="experience"
                  v-model="$v.form.years_of_experience_id.$model"
                  :state="validateState('years_of_experience_id')"
                  stacked
                >
                  <b-form-radio
                    v-for="(option, index) in experienceOptions"
                    :key="index"
                    :value="option.id"
                  >
                    {{ option.interval }}
                  </b-form-radio>
                </b-form-radio-group>
              </b-form-group>
            </div>
          </div>
        </div>

        <div id="section-5" class="submit-section">
          <div class="submit-section__title">
            Undergraduate Degree
            <span class="text-danger">*</span>
          </div>

          <div class="submit-section-row">
            <div class="submit-section-form">
              <b-form-group
                id="undergraduateDegreeGroup"
                label-for="undergraduateDegree"
                invalid-feedback="Please, select at least one."
                :state="validateState('undergraduate_degree_ids')"
              >
                <multiselect
                  id="undergraduateDegree"
                  class="custom-multiselect"
                  placeholder="— Select —"
                  v-model="form.undergraduate_degree_ids"
                  :options="undergraduateDegreeOptions.map(option => option.id)"
                  :custom-label="opt => undergraduateDegreeOptions.find(x => x.id === opt).name"
                  :multiple="true"
                  :closeOnSelect="false"
                  @close="$v.form.undergraduate_degree_ids.$touch()"
                >
                  <template slot="tag" slot-scope="props">
                    <div class="multiselect__tag">
                        <span>
                          {{ undergraduateDegreeOptions.find(x => x.id === props.option).name }}
                        </span>
                      <div
                        class="multiselect__tag-remove"
                        @mousedown.prevent="props.remove(props.option)"
                      >
                        <cross-svg />
                      </div>
                    </div>
                  </template>
                </multiselect>
              </b-form-group>
            </div>
          </div>
        </div>

        <div id="section-6" class="submit-section">
          <div class="submit-section__title">
            Target Salary
            <span class="text-danger">*</span>
          </div>

          <div class="submit-section-row">
            <div class="submit-section-form">
              <b-form-group
                id="targetSalaryGroup"
                :state="validateState('target_salary_ids')"
                label-for="targetSalary"
                invalid-feedback="This is a required field"
              >
                <multiselect
                  id="targetSalary"
                  class="custom-multiselect"
                  placeholder="— Select —"
                  v-model="form.target_salary_ids"
                  :options="targetSalaryOptions.map(option => option.id)"
                  :custom-label="opt => targetSalaryOptions.find(x => x.id === opt).interval"
                  :multiple="true"
                  :closeOnSelect="false"
                  @close="$v.form.target_salary_ids.$touch()"
                >
                  <template slot="tag" slot-scope="props">
                    <div class="multiselect__tag">
                        <span>
                          {{ targetSalaryOptions.find(x => x.id === props.option).interval }}
                        </span>
                      <div
                        class="multiselect__tag-remove"
                        @mousedown.prevent="props.remove(props.option)"
                      >
                        <cross-svg />
                      </div>
                    </div>
                  </template>
                </multiselect>
              </b-form-group>
            </div>
          </div>
        </div>

        <div id="section-7" class="submit-section">
          <div class="submit-section__title">
            Software (must be intermediate or advanced)
            <span class="text-danger">*</span>
            <br>
            <span class="submit-section__subtitle">Please select any that apply</span>
          </div>

          <div class="submit-section-row">
            <div class="submit-section-form">
              <b-form-group
                id="softwareGroup"
                label-for="software"
                invalid-feedback="Please, select at least one."
                :state="validateState('software_ids')"
              >
                <multiselect
                  id="software"
                  class="custom-multiselect"
                  placeholder="— Select —"
                  v-model="form.software_ids"
                  :options="softwareOptions.map(option => option.id)"
                  :custom-label="opt => softwareOptions.find(x => x.id === opt).name"
                  :multiple="true"
                  :closeOnSelect="false"
                  @close="$v.form.software_ids.$touch()"
                >
                  <template slot="tag" slot-scope="props">
                    <div class="multiselect__tag">
                        <span>
                          {{ softwareOptions.find(x => x.id === props.option).name }}
                        </span>
                      <div
                        class="multiselect__tag-remove"
                        @mousedown.prevent="props.remove(props.option)"
                      >
                        <cross-svg />
                      </div>
                    </div>
                  </template>
                </multiselect>
              </b-form-group>
            </div>
          </div>
        </div>

        <div id="section-8" class="submit-section">
          <div class="submit-section__title">
            Additional experience
            <br>
            <span class="submit-section__subtitle">Please select any that apply</span>
          </div>

          <div class="submit-section-row">
            <div class="submit-section-form">
              <b-form-group
                id="additionalExperienceGroup"
                label-for="additionalExperience"
              >
                <multiselect
                  id="additionalExperience"
                  class="custom-multiselect"
                  placeholder="— Select —"
                  v-model="form.additional_experience_ids"
                  :options="additionalExperienceOptions.map(option => option.id)"
                  :custom-label="opt => additionalExperienceOptions.find(x => x.id === opt).name"
                  :multiple="true"
                  :closeOnSelect="false"
                >
                  <template slot="tag" slot-scope="props">
                    <div class="multiselect__tag">
                        <span>
                          {{ additionalExperienceOptions.find(x => x.id === props.option).name }}
                        </span>
                      <div
                        class="multiselect__tag-remove"
                        @mousedown.prevent="props.remove(props.option)"
                      >
                        <cross-svg />
                      </div>
                    </div>
                  </template>
                </multiselect>
              </b-form-group>
            </div>
          </div>
        </div>

        <div id="section-9" class="submit-section">
          <div class="submit-section__title">
            Additional Information
            <span class="text-danger">*</span>
          </div>

          <div class="submit-section-row">
            <div class="submit-section-form">
              <template v-if="formType === 'update' && photo_url">
                <div class="submit-photo">
                  <div class="submit-photo__title">Photo file: </div>
                  <a
                    id="submit-photo-tooltip"
                    class="submit-resume__link link-regular"
                    :href="photo_url"
                    target="_blank"
                  >
                    {{ photo_file_name }}
                  </a>

                  <b-tooltip
                    custom-class="submit-photo__tooltip"
                    target="submit-photo-tooltip"
                    triggers="hover"
                    placement="top"
                  >
                    <img class="fit-image" :src="photo_url" alt="">
                  </b-tooltip>
                </div>

                <b-form-group
                  id="updatePhotoGroup"
                  invalid-feedback="File type must be jpg, jpeg or png."
                  :state="validateState('photo')"
                >
                  <form-file
                    title="Change Photo"
                    description="Portrait photo of the candidate."
                    :allowed-types="['jpg', 'jpeg', 'png']"
                    :cropper="true"
                    @validate="$v.form.photo.$touch()"
                    @change="value => form.photo = value"
                  ></form-file>
                </b-form-group>
              </template>

              <template v-else>
                <b-form-group
                  id="createPhotoGroup"
                  invalid-feedback="
                  This is a required field and file type must be jpg, jpeg or png."
                  :state="validateState('photo')"
                >
                  <form-file
                    title="Upload Photo"
                    :required="true"
                    description="Portrait photo of the candidate."
                    :allowed-types="['jpg', 'jpeg', 'png']"
                    :cropper="true"
                    @validate="$v.form.photo.$touch()"
                    @change="value => form.photo = value"
                  ></form-file>
                </b-form-group>
              </template>
            </div>
          </div>

          <div class="submit-section-row">
            <div class="submit-section-form">
              <b-form-group
                id="linkedinLinkGroup"
                label-for="linkedinLink"
                invalid-feedback="
                Should be valid link to Linkedin profile."
                :state="validateState('linkedin_link')"
              >
                <template slot="label">
                  Link to LinkedIn profile <i>(highly recommended)</i>
                </template>
                <b-form-input
                  id="linkedinLink"
                  type="text"
                  v-model="$v.form.linkedin_link.$model"
                  :state="validateState('linkedin_link')"
                ></b-form-input>
              </b-form-group>
            </div>
          </div>

          <div class="submit-section-row">
            <div class="submit-section-form">
              <b-form-checkbox switch v-model="videoInterview">
                Video Interview<br />
                <span>
                    Do you have a video interview to share with the client?
                  </span>
              </b-form-checkbox>

              <div class="form-switcher-content">
                <b-form-group
                  v-if="videoInterview"
                  id="videoInterviewLinkGroup"
                  label-for="videoInterviewLink"
                  :state="validateState('video_interview_link')"
                  invalid-feedback="
                  This is a required field and should be valid link to Youtube/Vimeo."
                >
                  <template slot="label">
                    Link to YouTube/Vimeo <span class="text-danger">*</span>
                  </template>
                  <b-form-input
                    id="videoInterviewLink"
                    type="text"
                    v-model="$v.form.video_interview_link.$model"
                    :state="validateState('video_interview_link')"
                  ></b-form-input>
                </b-form-group>

                <div
                  class="video-wrapper"
                  v-if="videoInterview && videoInterviewFrame"
                  v-html="videoInterviewFrame"
                ></div>
              </div>
            </div>
          </div>

          <div class="submit-section-row">
            <div class="submit-section-form">
              <b-form-group
                id="militaryGroup"
                label-for="military"
              >
                <b-form-checkbox
                  id="military"
                  v-model="form.military"
                >
                  Military
                </b-form-checkbox>
              </b-form-group>
            </div>
          </div>

          <div class="submit-section-row">
            <div class="submit-section-form">
              <b-form-group
                id="languagesGroup"
                label-for="languages"
              >
                <template slot="label">
                  Languages
                </template>

                <multiselect
                  id="languages"
                  class="custom-multiselect"
                  placeholder="— Select —"
                  v-model="form.language_ids"
                  :options="languageOptions.map(option => option.id)"
                  :custom-label="opt => languageOptions.find(x => x.id === opt).name"
                  :multiple="true"
                  :closeOnSelect="false"
                >
                  <template slot="tag" slot-scope="props">
                    <div class="multiselect__tag">
                        <span>
                          {{ languageOptions.find(x => x.id === props.option).name }}
                        </span>
                      <div
                        class="multiselect__tag-remove"
                        @mousedown.prevent="props.remove(props.option)"
                      >
                        <cross-svg />
                      </div>
                    </div>
                  </template>
                </multiselect>
              </b-form-group>
            </div>
          </div>

          <div class="submit-section-row">
            <div class="submit-section-form">
              <b-form-group
                id="diversityGroup"
                label-for="diversity"
              >
                <b-form-checkbox
                  id="diversity"
                  v-model="form.diversity"
                  switch
                >
                  Diversity<br />
                  <span>
                      My candidate is considered to be a minority and meets
                      requirements for diversity in the work place.
                    </span>
                </b-form-checkbox>
              </b-form-group>
            </div>
          </div>

          <div class="submit-section-row">
            <div class="submit-section-form">
              <b-form-group
                id="referencesGroup"
                label-for="references"
              >
                <b-form-checkbox
                  id="references"
                  v-model="form.references"
                  switch
                >
                  References<br />
                  <span>
                      Have you done at least two references on this candidate?
                    </span>
                </b-form-checkbox>
              </b-form-group>
            </div>
          </div>
        </div>

        <div class="submit-buttons">
          <template v-if="formType === 'create'">
            <button type="submit" class="button">Create Candidate Profile</button>
          </template>

          <template v-else-if="formType === 'update'">
            <button type="submit" class="button">Update Profile</button>

            <button class="icon-label" @click="deleteCandidate">
              <trash-svg />
              <span>Delete Profile</span>
            </button>
          </template>

          <!--<div v-if="responseErrorMessage" class="submit-error">
            {{ responseErrorMessage }}
          </div>-->
        </div>
      </b-form>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import vueSmoothScroll from 'vue2-smooth-scroll';
import {
  BForm, BFormGroup, BFormInput, BFormInvalidFeedback,
  BFormTextarea, BFormRadioGroup, BFormRadio, BFormCheckbox, BTooltip,
} from 'bootstrap-vue';
import { validationMixin } from 'vuelidate';
import {
  minLength, required, requiredIf, email,
} from 'vuelidate/lib/validators';
import Multiselect from 'vue-multiselect';
import { mask } from 'vue-the-mask';
import InfoBlock from '@/components/utils/InfoBlock.vue';
import FormFile from '@/components/utils/FormFile.vue';
import CheckSvg from '@/components/svg/CheckSvg.vue';
import CrossSvg from '@/components/svg/CrossSvg.vue';
import TrashSvg from '@/components/svg/TrashSvg.vue';

import 'vue-advanced-cropper/dist/style.css';

Vue.use(vueSmoothScroll);

export default {
  name: 'CandidateForm',
  components: {
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BFormTextarea,
    BFormRadioGroup,
    BFormRadio,
    BFormCheckbox,
    BTooltip,
    Multiselect,
    InfoBlock,
    FormFile,
    CheckSvg,
    CrossSvg,
    TrashSvg,
  },
  directives: { mask },
  mixins: [validationMixin],
  props: {
    formType: {
      type: String,
      required: true,
    },
    candidateId: {
      type: Number,
    },
  },
  data() {
    return {
      form: {
        first_name: null,
        last_name: null,
        email: null,
        phone: null,
        cv: null,
        zip_code: null,
        state: null,
        city: null,
        sell_text: null,
        summary: null,
        job_title_ids: [],
        industry_ids: [],
        years_of_experience_id: null,
        undergraduate_degree_ids: [],
        target_salary_ids: null,
        software_ids: [],
        additional_experience_ids: [],
        photo: null,
        linkedin_link: null,
        video_interview_link: null,
        military: false,
        language_ids: [],
        diversity: false,
        references: false,
      },
      videoInterview: false,
      videoInterviewFrame: null,
      cv_url: null,
      cv_file_name: null,
      photo_url: null,
      photo_file_name: null,
      confirmSubmitModal: false,
      responseErrorMessage: null,
      jobTitleOptions: [],
      industryOptions: [],
      experienceOptions: [],
      undergraduateDegreeOptions: [],
      targetSalaryOptions: [],
      softwareOptions: [],
      additionalExperienceOptions: [],
      languageOptions: [],
      categoryOptions: [],
      inputPlaceholder: 'Do not put name of candidate or any identifiable information in this section',
    };
  },
  validations: {
    form: {
      first_name: {
        required,
        minLength: minLength(2),
      },
      last_name: {
        required,
        minLength: minLength(2),
      },
      email: {
        required,
        email,
      },
      phone: {
        required,
        minLength: minLength(12),
      },
      cv: {
        // eslint-disable-next-line
        required: requiredIf(function () {
          return !this.cv_url;
        }),
        allowedType(value) {
          if (this.cv_url && !value) {
            return true;
          }

          return value ? ['pdf', 'doc', 'docx'].includes(value.type) : false;
        },
      },
      zip_code: {
        required,
        isExist() {
          if (!this.form.zip_code || !this.form.zip_code.length) {
            return true;
          }

          return new Promise((resolve) => {
            this.$store.dispatch('zipCode/getZipCodeInfo', this.form.zip_code)
              .then((response) => {
                const { results } = response.data;
                let status = false;

                if (results) {
                  const zipCodeData = results[this.form.zip_code];

                  if (zipCodeData && zipCodeData.length) {
                    this.form.state = zipCodeData[0].state;
                    this.form.city = zipCodeData[0].city;

                    this.$v.form.state.$touch();
                    this.$v.form.city.$touch();

                    status = true;
                  }
                }

                resolve(status);
              })
              .catch((error) => {
                window.flash(error.response.data.message, 'error');
              });
          });
        },
      },
      state: {
        required,
        minLength: minLength(2),
      },
      city: {
        required,
        minLength: minLength(2),
      },
      sell_text: {
        required,
        minLength: minLength(2),
      },
      summary: {
        required,
        minLength: minLength(2),
      },
      job_title_ids: {
        required,
      },
      industry_ids: {
        required,
      },
      years_of_experience_id: {
        required,
      },
      undergraduate_degree_ids: {
        required,
      },
      target_salary_ids: {
        required,
      },
      software_ids: {
        required,
      },
      photo: {
        // eslint-disable-next-line
        required: requiredIf(function () {
          return !this.photo_url;
        }),
        // eslint-disable-next-line
        allowedType(value) {
          if (this.photo_url && !value) {
            return true;
          }

          return value ? ['png', 'jpg', 'jpeg'].includes(value.type) : false;
        },
      },
      linkedin_link: {
        isValid(value) {
          if (value) {
            if (value.match(/linkedin\.com/ig)) {
              return true;
            }
            return false;
          }

          return true;
        },
      },
      video_interview_link: {
        // eslint-disable-next-line
        required: requiredIf(function () {
          return this.videoInterview;
        }),
        isValid(value) {
          if (this.videoInterview && value) {
            if (value.match(/vimeo\.com/ig)) {
              return new Promise((resolve, reject) => {
                this.$store.dispatch('video/getVimeoVideoInfo', this.form.video_interview_link)
                  .then((response) => {
                    this.videoInterviewFrame = response.data.html;
                    resolve(response.data.video_id);
                  })
                  .catch(() => {
                    this.videoInterviewFrame = null;
                    reject();
                  });
              });
            }

            if (value.match(/youtu\.be|www\.youtube\.com/ig)) {
              return new Promise((resolve, reject) => {
                this.$store.dispatch('video/getYoutubeVideoInfo', this.form.video_interview_link)
                  .then((response) => {
                    this.videoInterviewFrame = response.data.html;
                    resolve(response.data.html);
                  })
                  .catch(() => {
                    this.videoInterviewFrame = null;
                    reject();
                  });
              });
            }

            return false;
          }

          return true;
        },
      },
    },
  },
  computed: {
    personalInformationStatus() {
      const fields = [
        'first_name',
        'last_name',
        'email',
        'phone',
        'cv',
        'zip_code',
        'state',
        'city',
        'sell_text',
        'summary',
      ];

      let status = true;

      fields.forEach((field) => {
        const { $dirty, $error } = this.$v.form[field];

        if (!$dirty || $error) {
          status = false;
        }
      });

      return status;
    },
    additionalInformationStatus() {
      const fields = [
        'photo',
      ];

      if (this.videoInterview) {
        fields.push('video_interview_link');
      }

      let status = true;

      fields.forEach((field) => {
        const { $dirty, $error } = this.$v.form[field];

        if (!$dirty || $error) {
          status = false;
        }
      });

      return status;
    },
    progressValue() {
      let totalCount = 0;
      let filledCount = 0;

      const params = Object.entries(this.form);

      params.forEach((param) => {
        const field = param[0];
        const value = param[1];

        if (typeof value !== 'boolean') {
          totalCount += 1;
        }

        if (field === 'cv' && this.cv_url) {
          filledCount += 1;
        } else if (field === 'photo' && this.photo_url) {
          filledCount += 1;
        } else if (value && typeof value !== 'boolean') {
          if (Array.isArray(value)) {
            if (value.length) {
              filledCount += 1;
            }
          } else {
            filledCount += 1;
          }
        }
      });

      return Math.round((filledCount / totalCount) * 100);
    },
  },
  methods: {
    setCandidateOptions(data) {
      this.jobTitleOptions = data.job_titles;
      this.industryOptions = data.industries;
      this.experienceOptions = data.years_of_experience;
      this.undergraduateDegreeOptions = data.undergraduate_degrees;
      this.targetSalaryOptions = data.target_salaries;
      this.softwareOptions = data.software;
      this.additionalExperienceOptions = data.additional_experience;
      this.languageOptions = data.languages;
    },
    setCandidateProfile(data) {
      this.form.first_name = data.first_name;
      this.form.last_name = data.last_name;
      this.form.email = data.email;
      this.form.phone = data.phone;
      this.form.zip_code = data.zip_code;
      this.form.state = data.state;
      this.form.city = data.city;
      this.form.sell_text = data.sell_text;
      this.form.summary = data.summary;
      this.form.job_title_ids = data.job_titles.map((item) => item.id);
      this.form.industry_ids = data.industries.map((item) => item.id);
      this.form.years_of_experience_id = data.years_of_experience.id;
      this.form.undergraduate_degree_ids = data.undergraduate_degrees.map((item) => item.id);
      this.form.target_salary_ids = data.target_salaries.map((item) => item.id);
      this.form.software_ids = data.software.map((item) => item.id);
      this.form.additional_experience_ids = data.additional_experience.map((item) => item.id);
      this.form.linkedin_link = data.linkedin_link;
      this.form.video_interview_link = data.video_interview_link;
      this.form.military = data.military;
      this.form.language_ids = data.languages.map((item) => item.id);
      this.form.diversity = data.diversity;
      this.form.references = data.references;

      this.cv_url = data.cv_url;
      this.cv_file_name = `${data.first_name} ${data.last_name}`;

      this.photo_url = data.photo_url;
      this.photo_file_name = `${data.first_name} ${data.last_name}`;

      this.videoInterview = !!data.video_interview_link;

      this.$v.form.$touch();
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    validateAddress() {
      const fields = ['zip_code', 'state', 'city'];

      let state = null;

      fields.forEach((field) => {
        const fieldState = this.validateState(field);

        if (fieldState === false) {
          state = fieldState;
        }
      });

      return state;
    },
    submitForm() {
      this.responseErrorMessage = null;
      this.$v.form.$reset();
      this.$v.form.$touch();

      this.$nextTick(() => {
        if (!this.$v.form.$anyError) {
          if (this.formType === 'create') {
            this.$emit('onCreate', this.form);
          } else if (this.formType === 'update') {
            this.$emit('onUpdate', this.form);
          }
        } else {
          this.responseErrorMessage = 'Please, fill all required fields!';
          const firstErrorElement = document.querySelector('.form-group.is-invalid');

          this.$smoothScroll({
            scrollTo: firstErrorElement,
            offset: -8,
          });
        }
      });
    },
    deleteCandidate(e) {
      e.preventDefault();
      this.$emit('onDelete');
    },
  },
  created() {
    if (this.formType === 'update') {
      Promise.all([
        this.$store.dispatch('candidate/getCandidateOptions'),
        this.$store.dispatch('candidate/getCandidate', {
          candidateId: this.candidateId,
          data: {
            params: {
              level: 3,
            },
          },
        }),
      ])
        .then((response) => {
          this.setCandidateOptions(response[0].data);
          this.setCandidateProfile(response[1].data);
        })
        .catch((error) => {
          window.flash(error.response.data.message, 'error');
        });
    } else if (this.formType === 'create') {
      this.$store.dispatch('candidate/getCandidateOptions')
        .then((response) => {
          this.setCandidateOptions(response.data);
        })
        .catch((error) => {
          window.flash(error.response.data.message, 'error');
        });
    }
  },
};
</script>

<style lang="scss" scoped>
@import "src/assets/sass/views/candidate/candidate-form.scss";
</style>

<style lang="scss">
.submit-photo__tooltip.b-tooltip {
  opacity: 1;
  .tooltip-inner {
    padding: 0;
    position: relative;
    width: 196px;
    height: 196px;
  }
  .arrow {
    display: none !important;
  }
}
.video-wrapper {
  margin-top: 1rem;
}
</style>
