<template>
  <b-form class="profile-form" @submit.prevent="submitForm">
    <div class="form-row">
      <b-form-group
        id="companyNameGroup"
        label-for="companyName"
        :state="validateState('company_name')"
        invalid-feedback="This is a required field and must be at least 2 characters."
      >
        <template slot="label">
          Company Name <span class="text-danger">*</span>
        </template>
        <b-form-input
          id="companyName"
          type="text"
          v-model="$v.form.company_name.$model"
          :state="validateState('company_name')"
        ></b-form-input>
      </b-form-group>

      <b-form-group
        id="companyWebsiteGroup"
        label-for="companyWebsite"
        :state="validateState('company_website')"
        invalid-feedback="This is a required field and must be at least 2 characters."
      >
        <template slot="label">
          Company Website <span class="text-danger">*</span>
        </template>
        <b-form-input
          id="companyWebsite"
          type="text"
          v-model="$v.form.company_website.$model"
          :state="validateState('company_website')"
        ></b-form-input>
      </b-form-group>
    </div>

    <div class="form-row">
      <b-form-group
        id="employeesCountGroup"
        label-for="employeesCount"
        :state="validateState('number_of_company_employees')"
        invalid-feedback="This is a required field"
      >
        <template slot="label">
          # of employees in the company <span class="text-danger">*</span>
        </template>
        <b-form-input
          id="employeesCount"
          type="number"
          v-model="$v.form.number_of_company_employees.$model"
          :state="validateState('number_of_company_employees')"
        ></b-form-input>
      </b-form-group>

      <b-form-group
        :state="validateState('company_type')"
        label-for="companyType"
        invalid-feedback="This is a required field"
      >
        <template slot="label">
          Company type <span class="text-danger">*</span>
        </template>

        <b-form-radio-group
          id="companyType"
          v-model="$v.form.company_type.$model"
          :state="validateState('company_type')"
        >
          <b-form-radio
            name="company-type"
            value="Public"
          >
            Public
          </b-form-radio>
          <b-form-radio
            name="company-type"
            value="Private"
          >
            Private
          </b-form-radio>
        </b-form-radio-group>
      </b-form-group>
    </div>

    <div class="form-row">
      <b-form-group
        id="contactNameGroup"
        label-for="contactName"
        :state="validateState('contact_name')"
        invalid-feedback="This is a required field and must be at least 2 characters."
      >
        <template slot="label">
          Contact Name <span class="text-danger">*</span>
        </template>
        <b-form-input
          id="contactName"
          type="text"
          v-model="$v.form.contact_name.$model"
          :state="validateState('contact_name')"
        ></b-form-input>
      </b-form-group>

      <b-form-group
        id="contactTitleGroup"
        label-for="contactTitle"
        :state="validateState('contact_title')"
        invalid-feedback="This is a required field and must be at least 2 characters."
      >
        <template slot="label">
          Contact Title <span class="text-danger">*</span>
        </template>
        <b-form-input
          id="contactTitle"
          type="text"
          v-model="$v.form.contact_title.$model"
          :state="validateState('contact_title')"
        ></b-form-input>
      </b-form-group>
    </div>

    <b-form-group
      id="briefGroup"
      label-for="brief"
      invalid-feedback="This is a required field and must be at least 2 characters."
    >
      <template slot="label">
        Brief description of the company and why someone would want to work there...
      </template>
      <b-form-textarea
        id="brief"
        rows="5"
        v-model="$v.form.brief.$model"
        :state="validateState('brief')"
      ></b-form-textarea>
    </b-form-group>

    <b-form-group
      id="emailGroup"
      label-for="email"
      invalid-feedback="This is a required field and must be email."
    >
      <template slot="label">
        Contact Email <span class="text-danger">*</span>
      </template>
      <b-form-input
        id="email"
        type="email"
        v-model="$v.form.email.$model"
        :state="validateState('email')"
      ></b-form-input>
    </b-form-group>

    <div class="form-buttons profile-form-buttons">
      <button
        class="button"
        type="submit"
      >
        Update Profile
      </button>
    </div>
  </b-form>
</template>

<script>
import {
  BForm, BFormGroup, BFormInput, BFormTextarea, BFormRadioGroup, BFormRadio,
} from 'bootstrap-vue';
import { validationMixin } from 'vuelidate';
import {
  email, minLength, required,
} from 'vuelidate/lib/validators';

import { mapGetters } from 'vuex';

export default {
  name: 'EmployerProfile',
  components: {
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BFormRadioGroup,
    BFormRadio,
  },
  mixins: [validationMixin],
  data() {
    return {
      form: {
        company_name: null,
        company_website: null,
        number_of_company_employees: null,
        company_type: null,
        contact_name: null,
        contact_title: null,
        brief: null,
        email: null,
      },
    };
  },
  validations: {
    form: {
      company_name: {
        required,
        minLength: minLength(2),
      },
      company_website: {
        required,
        minLength: minLength(2),
      },
      number_of_company_employees: {
        required,
      },
      company_type: {
        required,
      },
      contact_name: {
        required,
        minLength: minLength(2),
      },
      contact_title: {
        required,
        minLength: minLength(2),
      },
      brief: {
        required,
        minLength: minLength(2),
      },
      email: {
        required,
        email,
      },
    },
  },
  computed: {
    ...mapGetters({
      authUserId: 'auth/getAuthUserId',
    }),
  },
  methods: {
    getProfileData() {
      this.$store.dispatch('employer/getEmployerProfile', this.authUserId)
        .then((response) => {
          this.form = response.data;
        })
        .catch((error) => {
          window.flash(error.response.data.message, 'error');
        });
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    submitForm() {
      this.$v.form.$reset();
      this.$v.form.$touch();

      if (!this.$v.form.$anyError) {
        this.$store.dispatch('employer/updateEmployerProfile', {
          employerId: this.authUserId,
          employerData: this.form,
        })
          .then(() => {
            window.flash('Employer updated successfully', 'success');
          })
          .catch((error) => {
            window.flash(error.response.data.message, 'error');
          });
      }
    },
  },
  created() {
    if (this.authUserId) {
      this.getProfileData();
    }
    this.authUserIdWatcher = this.$store.watch(
      (state, getters) => getters['auth/getAuthUserId'],
      (newValue) => {
        if (newValue) {
          this.getProfileData();
        }
      },
    );
  },
  beforeDestroy() {
    this.authUserIdWatcher();
  },
};
</script>
