import axios from 'axios';

const actions = {
  getVimeoVideoInfo(context, videoUrl) {
    return new Promise((resolve, reject) => {
      axios.get(`https://vimeo.com/api/oembed.json?url=${videoUrl}`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getYoutubeVideoInfo(context, videoUrl) {
    return new Promise((resolve, reject) => {
      axios.get(`https://www.youtube.com/oembed?format=json&url=${videoUrl}`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

export default {
  namespaced: true,
  actions,
};
